import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, defineMessages} from 'react-intl';
import {deleteClient} from '../../redux/modules/clients';
import {
  COUNTRY_BRAND_WEB_CONSOLE,
  SUB_BRAND_WEB_CONSOLE,
} from '../../redux/modules/brand';
import terms from '../../helpers/terms';

import FixedDataTable from '../../components/FixedDataTable/FixedDataTable';
import TextCell from '../../components/FixedDataTable/TextCell';
import {Column} from 'fixed-data-table-2';
import {loadAllSegments} from '../../redux/modules/segments';
import {asyncConnect} from 'redux-connect';
import {clientsWithExtendedFollowedBrandsSelector} from '../../redux/modules/clients';

class ClientsOverview extends Component {
  static propTypes = {
    clients: PropTypes.array,
    dispatch: PropTypes.func,
    brandId: PropTypes.string,
    brandName: PropTypes.string,
    segments: PropTypes.array,
    subBrands: PropTypes.object,
    webConsoleType: PropTypes.string,
  };

  _editClient = (client) => () => {
    this.props.history.push({
      pathname: `/clients/edit/${client.id}`,
      search: '',
    });
  };

  _onCreateClient = () => {
    this.props.history.push('/clients/create');
  };

  _filter = (value) => (client) => {
    return (
      (client.name &&
        client.name.toString().toLowerCase().indexOf(value) !== -1) ||
      (client.id && client.id.toString().toLowerCase().indexOf(value) !== -1)
    );
  };

  render() {
    const {dispatch, clients, webConsoleType, segments} = this.props;

    const messages = defineMessages({
      confirmLabel: {
        id: 'ClientsOverview.confirmLabel',
        defaultMessage:
          'Are you sure you want to permanently delete this client?',
        description:
          'The confirmation message that is shown when the user is about to delete a client',
      },
    });

    return (
      <FixedDataTable
        inputData={clients}
        dispatch={dispatch}
        filter={this._filter}
        createItem={
          webConsoleType === SUB_BRAND_WEB_CONSOLE
            ? undefined
            : this._onCreateClient
        }
        deleteItem={
          webConsoleType === SUB_BRAND_WEB_CONSOLE ? undefined : deleteClient
        }
        editItem={this._editClient}
        confirmLabel={this.props.intl.formatMessage(messages.confirmLabel)}
      >
        <Column
          headerTitle={this.props.intl.formatMessage(terms.clientId)}
          displayCell={(client, props) => (
            <TextCell {...props}>{client.id}</TextCell>
          )}
          width={100}
          flexGrow={1}
          sortingField={(client) => client.id}
          columnKey={'type'}
        />
        <Column
          headerTitle={this.props.intl.formatMessage(terms.clientName)}
          flexGrow={1}
          displayCell={(client, props) => (
            <TextCell {...props}>{client.name}</TextCell>
          )}
          width={180}
          sortingField={(client) => client.name}
          columnKey={'defaultLabel'}
        />
        {webConsoleType === COUNTRY_BRAND_WEB_CONSOLE && (
          <Column
            headerTitle={this.props.intl.formatMessage(terms.BrandName)}
            flexGrow={1}
            displayCell={(client, props) => (
              <TextCell {...props}>
                {client.followedBrands.map((item, i) => {
                  const delimiter =
                    client.followedBrands.length - 1 > i ? ', ' : '';

                  return (
                    <span
                      style={{
                        color: item.linkedToSalesSegment
                          ? '#4E4E4E'
                          : '#afafaf',
                      }}
                      key={i}
                    >
                      {item.shortName}
                      {delimiter}
                    </span>
                  );
                })}
              </TextCell>
            )}
            width={100}
            sortingField={(client) => {
              const shortName = client.followedBrands.map((item) => {
                return item.shortName;
              });
              return shortName;
            }}
            columnKey={'subBrand'}
          />
        )}
        {webConsoleType === SUB_BRAND_WEB_CONSOLE && (
          <Column
            headerTitle={this.props.intl.formatMessage(terms.segment)}
            flexGrow={1}
            displayCell={(client, props) => {
              const segmentOfClient = segments.find((segment) =>
                client.segments.includes(segment.id)
              );
              return (
                <TextCell {...props}>
                  {segmentOfClient && segmentOfClient.name}
                </TextCell>
              );
            }}
            width={160}
            sortingField={(client) => {
              const segmentOfClient = segments.find((segment) =>
                client.segments.includes(segment.id)
              );
              return segmentOfClient && segmentOfClient.name;
            }}
            columnKey={'segment'}
          />
        )}
      </FixedDataTable>
    );
  }
}

export default asyncConnect([
  {
    key: 'load',
    promise: (helpers) => helpers.store.dispatch(loadAllSegments()),
  },
])(
  connect((state) => {
    return {
      brandId: state.brand.id,
      brandName: state.brand.shortName,
      segments: state.segments.segments,
      subBrands: state.brand.subBrands,
      webConsoleType: state.brand.webConsoleType,
      clients: clientsWithExtendedFollowedBrandsSelector(state),
    };
  })(injectIntl(ClientsOverview))
);
