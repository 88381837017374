import terms from '../../helpers/terms';

export default (values) => {
  const errors = {};
  if (!values.segmentGroupId) {
    errors.segmentGroupId = terms.required;
  }
  if (!values.label) {
    errors.label = terms.required;
  }
  if (!values.value) {
    errors.value = terms.required;
  }
  return errors;
};
