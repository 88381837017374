import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {StyleSheet, css} from 'aphrodite';

export default class ToggleImage extends Component {
  static propTypes = {
    image: PropTypes.string.isRequired,
  };

  render() {
    const {styles} = this;
    return (
      <div className={css(styles.wrapper)}>
        <img
          className={css(styles.image)}
          src={this.props.image}
          alt="toggle"
        />
      </div>
    );
  }

  styles = StyleSheet.create({
    wrapper: {
      ':hover img:first-child': {
        display: 'none',
      },
      ':hover img:last-child': {
        display: 'block',
      },
    },
    image: {
      height: '50px',
      width: '50px',
    },
  });
}
