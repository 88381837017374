import React from 'react';
import {Redirect} from 'react-router';
import {defineMessages} from 'react-intl';

import App from '../../containers/App/App';
import Messages from '../../containers/Messages/Messages';
import Chat from '../../containers/Chat/Chat';
import MessageOverview from '../../containers/Messages/MessageOverview';
import MessageDetail from '../../containers/Messages/MessageDetail';
import Segments from '../../containers/Segments/Segments';
import SegmentOverview from '../../containers/Segments/SegmentOverview';
import SegmentDetails from '../../containers/Segments/SegmentDetail';
import Clients from '../../containers/Clients/Clients';
import CTAS from '../../containers/CTAS/CTAS';
import Categories from '../../containers/Categories/Categories';
import CTASOverview from '../../containers/CTAS/CTASOverview';
import CTASDetail from '../../containers/CTAS/CTASDetail';
import ClientsOverview from '../../containers/Clients/ClientsOverview';
import ClientDetails from '../../containers/Clients/ClientDetails';
import CategoryOverview from '../../containers/Categories/CategoryOverview';
import CategoryDetails from '../../containers/Categories/CategoryDetails';
import Users from '../../containers/User/Users';
import UsersOverview from '../../containers/User/UserOverview';
import UserDetails from '../../containers/User/UserDetails';
import LoginError from '../../components/LoginError/LoginError';
import terms from '../../helpers/terms';
import Insights from '../../containers/Insights/Insights';

export default (intl) => {
  const messages = defineMessages({
    createYourMessage: {
      id: 'Routes.createYourMessage',
      description:
        'The page title of the page where the user creates a message',
      defaultMessage: 'Create Your Message',
    },
    editYourMessage: {
      id: 'Routes.editYourMessage',
      description: 'The page title of the page where the user edits a message',
      defaultMessage: 'Edit Your Message',
    },
    viewTargetGroup: {
      id: 'Routes.viewTargetGroup',
      description:
        'The page title of the page where the user view a target group',
      defaultMessage: 'View Target Group',
    },
    editTargetGroup: {
      id: 'Routes.editTargetGroup',
      description:
        'The page title of the page where the user edits a target group',
      defaultMessage: 'Edit Target Group',
    },
    createATargetGroup: {
      id: 'Routes.createATargetGroup',
      description:
        'The page title of the page where the user creates a target group',
      defaultMessage: 'Create a Target Group',
    },
    editClient: {
      id: 'Routes.editClient',
      description: 'The page title of the page where the user edits a client',
      defaultMessage: 'Edit Client',
    },
    createANewClient: {
      id: 'Routes.createANewClient',
      description:
        'The page title of the page where the user creates a new client',
      defaultMessage: 'Create a new Client',
    },
    editCallToAction: {
      id: 'Routes.editCallToAction',
      description:
        'The page title of the page where the user edits a call to action',
      defaultMessage: 'Edit Call To Action',
    },
    createYourCallToAction: {
      id: 'Routes.createYourCallToAction',
      description:
        'The page title of the page where the user creates a new call to action',
      defaultMessage: 'Create your Call To Action',
    },
    editCategory: {
      id: 'Routes.editCategory',
      description: 'The page title of the page where the user edits a category',
      defaultMessage: 'Edit Category',
    },
    addANewCategory: {
      id: 'Routes.addANewCategory',
      description:
        'The page title of the page where the user adds a new category',
      defaultMessage: 'Add a new Category',
    },
    editUser: {
      id: 'Routes.editUser',
      description:
        'The page title of the page where the details of a user / account managers can be managed',
      defaultMessage: 'Edit user',
    },
    addANewUser: {
      id: 'Routes.addANewUser',
      description:
        'The page title of the page a new user / account managers can be added',
      defaultMessage: 'Add a new user',
    },
  });

  const TITLE_MAPPING = [
    {
      path: '/messages',
      title: intl.formatMessage(terms.messages),
    },
    {
      path: '/messages/create/',
      title: intl.formatMessage(messages.createYourMessage),
    },
    {
      path: '/messages/',
      title: intl.formatMessage(messages.editYourMessage),
    },
    {
      path: '/segments',
      title: intl.formatMessage(terms.segments),
    },
    {
      path: '/segments/edit/',
      title: intl.formatMessage(messages.editTargetGroup),
    },
    {
      path: '/segments/view/',
      title: intl.formatMessage(messages.viewTargetGroup),
    },
    {
      path: '/segments/create',
      title: intl.formatMessage(messages.createATargetGroup),
    },
    {
      path: '/clients',
      title: intl.formatMessage(terms.clients),
    },
    {
      path: '/clients/edit/',
      title: intl.formatMessage(messages.editClient),
    },
    {
      path: '/clients/create',
      title: intl.formatMessage(messages.createANewClient),
    },
    {
      path: '/ctas',
      title: intl.formatMessage(terms.callToActions),
    },
    {
      path: '/ctas/edit/',
      title: intl.formatMessage(messages.editCallToAction),
    },
    {
      path: '/ctas/create',
      title: intl.formatMessage(messages.createYourCallToAction),
    },
    {
      path: '/categories',
      title: intl.formatMessage(terms.categories),
    },
    {
      path: '/categories/edit',
      title: intl.formatMessage(messages.editCategory),
    },
    {
      path: '/categories/create',
      title: intl.formatMessage(messages.addANewCategory),
    },
    {
      path: '/users',
      title: intl.formatMessage(terms.users),
    },
    {
      path: '/users/edit',
      title: intl.formatMessage(messages.editUser),
    },
    {
      path: '/users/create',
      title: intl.formatMessage(messages.addANewUser),
    },
  ];

  return [
    {
      path: '/',
      component: (props) => {
        const {
          location: {pathname},
        } = props;

        const titleObj = TITLE_MAPPING.find(
          (obj) => pathname.indexOf(obj.path) > -1
        );
        return <App {...props} title={titleObj ? titleObj.title : ''} />;
      },
      routes: [
        {
          path: '/',
          exact: true,
          component: () => {
            return <Redirect to="/messages" exact />;
          },
        },
        {
          path: '/login-error',
          component: LoginError,
        },
        {
          path: '/chat',
          component: Chat,
        },
        {
          path: '/insights',
          component: Insights,
        },
        {
          path: '/messages',
          component: Messages,
          routes: [
            {
              path: '/messages',
              exact: true,
              component: MessageOverview,
            },
            {
              path: '/messages/create/:id',
              component: MessageDetail,
            },
            {
              path: '/messages/:id',
              component: MessageDetail,
            },
          ],
        },
        {
          path: '/segments',
          component: Segments,
          routes: [
            {
              path: '/segments',
              exact: true,
              component: SegmentOverview,
            },
            {
              path: '/segments/edit/:id',
              component: SegmentDetails,
            },
            {
              path: '/segments/view/:id',
              component: SegmentDetails,
            },
            {
              path: '/segments/create',
              component: SegmentDetails,
            },
          ],
        },
        {
          path: '/clients',
          component: Clients,
          routes: [
            {
              path: '/clients',
              exact: true,
              component: ClientsOverview,
            },
            {
              path: '/clients/edit/:id',
              component: ClientDetails,
            },
            {
              path: '/clients/create',
              component: ClientDetails,
            },
          ],
        },
        {
          path: '/ctas',
          component: CTAS,
          routes: [
            {
              path: '/ctas',
              exact: true,
              component: CTASOverview,
            },
            {
              path: '/ctas/edit/:id',
              component: CTASDetail,
            },
            {
              path: '/ctas/create',
              component: CTASDetail,
            },
          ],
        },
        {
          path: '/categories',
          component: Categories,
          routes: [
            {
              path: '/categories',
              exact: true,
              component: CategoryOverview,
            },
            {
              path: '/categories/edit/:id',
              component: CategoryDetails,
            },
            {
              path: '/categories/create',
              component: CategoryDetails,
            },
          ],
        },
        {
          path: '/users',
          component: Users,
          routes: [
            {
              path: '/users',
              exact: true,
              component: UsersOverview,
            },
            {
              path: '/users/edit/:id',
              component: UserDetails,
            },
            {
              path: '/users/create',
              component: UserDetails,
            },
          ],
        },
        {
          path: '*',
          component: (props) => {
            return <div></div>;
          },
        },
      ],
    },
  ];
};
