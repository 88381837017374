import {defineMessages} from 'react-intl';

export default defineMessages({
  clientId: {
    id: 'Term.clientId',
    description:
      'The general term for the client id with which a client logs in',
    defaultMessage: 'Client ID',
  },
  clientName: {
    id: 'Term.clientName',
    description:
      'The general term for client name which refers to the full name of a client',
    defaultMessage: 'Client Name',
  },
  clientEmail: {
    id: 'Term.clientEmail',
    description:
      'The general term for client email which refers to the admin email of a client',
    defaultMessage: 'Client Email',
  },
  BrandName: {
    id: 'Term.BrandName',
    description: 'The general term for brand name',
    defaultMessage: 'Brand(s)',
  },
  callToAction: {
    id: 'Term.callToActions.one',
    description: 'The general, singular term for a Call to Action (CTA)',
    defaultMessage: 'Call to Action',
  },
  callToActions: {
    id: 'Term.callToActions.other',
    description: 'The general, plural term for a Call to Action (CTA)',
    defaultMessage: 'Call to Actions',
  },
  category: {
    id: 'Term.category.one',
    description: 'The general, singular term for a category',
    defaultMessage: 'Category',
  },
  categories: {
    id: 'Term.category.other',
    description: 'The general, plural term for a category',
    defaultMessage: 'Categories',
  },
  insights: {
    id: 'Term.insight.other',
    description: 'The general, plural term for a insight',
    defaultMessage: 'insights',
  },
  message: {
    id: 'Term.message.one',
    description: 'The general, singular term for a message',
    defaultMessage: 'Message',
  },
  messages: {
    id: 'Term.message.other',
    description: 'The general, plural term for a message',
    defaultMessage: 'Messages',
  },
  segment: {
    id: 'Term.segment.one',
    description: 'The singular general term for a target group / segment',
    defaultMessage: 'Target Group',
  },
  segments: {
    id: 'Term.segments.other',
    description: 'The singular general term for a target group / segment',
    defaultMessage: 'Target Groups',
  },
  client: {
    id: 'Term.client.one',
    description: 'The singular general term for a client',
    defaultMessage: 'Client',
  },
  clients: {
    id: 'Term.client.other',
    description: 'The singular general term for a clients',
    defaultMessage: 'Clients',
  },
  user: {
    id: 'Term.user.one',
    description: 'The singular general term for a user / account manager',
    defaultMessage: 'User',
  },
  users: {
    id: 'Term.user.other',
    description: 'The singular general term for a users / account manager',
    defaultMessage: 'Users',
  },
  ok: {
    id: 'Term.ok',
    description:
      "The general term for 'OK, I understand' for the use on button labels",
    defaultMessage: 'OK',
  },
  next: {
    id: 'Term.next',
    description:
      "The general term for 'Confirm input and move on to the next step' for the use on button labels",
    defaultMessage: 'Next',
  },
  cancel: {
    id: 'Term.cancel',
    description:
      'The general term for cancelling the current action (like publishing or updating a message) for the use on button labels',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'Term.confirm',
    description:
      'The general term for confirming the current action (like publishing or updating a message) for the use on button labels',
    defaultMessage: 'Confirm',
  },
  update: {
    id: 'Term.update',
    description:
      'The general term for updating a cta, message, client, etc.., for the use on button labels',
    defaultMessage: 'Update',
  },
  add: {
    id: 'Term.add',
    description:
      'The general term adding a new cta, message, client, etc.., for the use on button labels',
    defaultMessage: 'Add',
  },
  delete: {
    id: 'Term.delete',
    description:
      'The general term deleting a cta, message, client, etc.., for the use on button labels',
    defaultMessage: 'Delete',
  },
  name: {
    id: 'Term.name',
    description: "The general term for a person' or object's name",
    defaultMessage: 'Name',
  },
  phonenumber: {
    id: 'Term.phonenumber',
    description: "The general term for a person' or object's phone number",
    defaultMessage: 'Phone number',
  },
  email: {
    id: 'Term.email',
    description: 'The general term for a email address',
    defaultMessage: 'Email',
  },
  picture: {
    id: 'Term.picture',
    description: 'The general term for a picture address',
    defaultMessage: 'Picture',
  },
  role: {
    id: 'Term.role',
    description:
      "The general term for an user's role on the platform, such as sales or marketing",
    defaultMessage: 'Role',
  },
  noImage: {
    id: 'Term.noImage',
    description:
      'The term that is displayed when there is no image uploaded yet.',
    defaultMessage: 'No image',
  },
  administrator: {
    id: 'Term.administrator',
    description: 'The term for administrator.',
    defaultMessage: 'Administrator',
  },
  notConnected: {
    id: 'Term.notConnected',
    description:
      'The select item on user settings to disconnect the accountmanager from a brand .',
    defaultMessage: 'Remove target group',
  },
  tags: {
    id: 'Term.tags',
    description: 'The term for Tags',
    defaultMessage: 'Tags',
  },
  // Validation error messages
  required: {
    id: 'Error.required',
    description:
      'The error message that pops up when the user leaves a required field empty',
    defaultMessage: 'Required',
  },
  expirationDatePrecedesPublicationDate: {
    id: 'Error.expirationDatePrecedesPublicationDate',
    description:
      'This error message will pop up if the user sets the expiration date before the publication date when publishing a message',
    defaultMessage: 'The expiration date should be after the publication date',
  },
  // userSettings
  emailPlaceholder: {
    id: 'UserDetails.emailPlaceholder',
    description: 'The placeholder for the email input field',
    defaultMessage: 'Set the user email',
  },
  namePlaceholder: {
    id: 'UserDetails.namePlaceholder',
    description: 'The placeholder for the name input field',
    defaultMessage: 'Set the user name',
  },
  phonenumberPlaceholder: {
    id: 'UserDetails.phonePlaceholder',
    description: 'The placeholder for the phone number input field',
    defaultMessage: 'Set the phone number',
  },
  rolePlaceholder: {
    id: 'UserDetails.rolePlaceholder',
    description: 'The placeholder for the role input field',
    defaultMessage: 'Select user role',
  },
  segmentPlaceholder: {
    id: 'UserDetails.segmentPlaceholder',
    description: 'The placeholder for the user input field',
    defaultMessage: 'Select target group for user',
  },
  uploadProfilePictureButtonLabel: {
    id: 'UserDetails.uploadProfilePictureButtonLabel',
    description: 'The label on the button which uploads a new profile picture',
    defaultMessage: 'Upload profile picture',
  },
  changeProfilePictureButtonLabel: {
    id: 'UserDetails.changeProfilePictureButtonLabel',
    description: 'The label on the button which changes a profile picture',
    defaultMessage: 'Change profile picture',
  },
  uploadBackgroundImageButtonLabel: {
    id: 'UserDetails.uploadBackgroundImageButtonLabel',
    description: 'The label on the button which uploads a new background image',
    defaultMessage: 'Upload background image',
  },
  changeBackgroundImageButtonLabel: {
    id: 'UserDetails.changeBackgroundImageButtonLabel',
    description: 'The label on the button which changes a background image',
    defaultMessage: 'Change background image',
  },
});
