import React, {Component} from 'react';
import {StyleSheet, css} from 'aphrodite';

export default class Header extends Component {
  render() {
    const {back, logo, menu} = this.props;
    const {styles} = Header;
    return (
      <div className={css(styles.header)}>
        <img className={css(styles.back)} src={back} alt="go back" />
        <img className={css(styles.logo)} src={logo} alt="app logo" />
        <img className={css(styles.menu)} src={menu} alt="menu logo" />
      </div>
    );
  }

  static horizontalMargin = 8;

  static styles = StyleSheet.create({
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 34,
      width: 231,
    },
    back: {
      width: 9,
      height: 11,
      marginLeft: Header.horizontalMargin,
    },
    logo: {
      height: 15,
    },
    menu: {
      width: 12,
      height: 11,
      marginRight: Header.horizontalMargin,
    },
  });
}
