/**
 * WARNING used by both front-end and back-end; DO NOT store secrets here
 */
export const sharedAuth0Config = {
  payload: ({protocol, hostname, port}, env) => {
    const resolvedPort = makePort({protocol, port});
    return {
      client_id:
        env === 'production'
          ? 'pycjV8JL4RicN5efCbU4RqFe4N05PKwp'
          : '7PrgMWDLnQIYWGcd0qFyuH1EjyG2B90f',
      connection: 'Username-Password-Authentication',
      redirect_uri: `${protocol}//${hostname}:${resolvedPort}/#/callback`,
    };
  },
  auth0BaseUrl: 'https://immidi-com.eu.auth0.com/',
};

export function makePort({protocol, port}) {
  return port || (protocol === 'http:' ? 80 : 443);
}
