import terms from '../../helpers/terms';

export default (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = terms.required;
  }
  if (!values.type) {
    errors.type = terms.required;
  }
  return errors;
};
