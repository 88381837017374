import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {StyleSheet, css} from 'aphrodite';
import {injectIntl} from 'react-intl';

import SideMenuItem from '../../components/SideMenuItem/SideMenuItem';
import chatIcon from '../../assets/images/chat.png';
import messageIcon from '../../assets/images/ic_messages.svg';
import messageActiveIcon from '../../assets/images/ic_messages_active.svg';
import segmentIcon from '../../assets/images/ic_target_groups.svg';
import segmentActiveIcon from '../../assets/images/ic_target_groups_active.svg';
import clientsIcon from '../../assets/images/ic_clients.svg';
import clientsActiveIcon from '../../assets/images/ic_clients_active.svg';
import ctaIcon from '../../assets/images/ic_cta.svg';
import ctaActiveIcon from '../../assets/images/ic_cta_active.svg';
import categoriesIcon from '../../assets/images/ic_categories.svg';
import categoriesActiveIcon from '../../assets/images/ic_categories_active.svg';
import insightsIcon from '../../assets/images/ic_insights.jpg';
import insightsActiveIcon from '../../assets/images/ic_insights_active.jpg';
import terms from '../../helpers/terms';

export let SIDE_MENU_WIDTH = '50px';

const HOME_SIGN = 'i';

export default injectIntl(
  class SideMenu extends Component {
    static propTypes = {
      features: PropTypes.object,
    };

    _homeClicked = () => {
      this.props.history.push({
        pathname: '/',
      });
    };

    render() {
      const {
        features: {modules},
        history,
      } = this.props;
      const {styles} = SideMenu;

      if (this.props.hideItems) {
        return (
          <div className={css(styles.wrapper)}>
            <div className={css(styles.brand)} onClick={this._homeClicked}>
              {HOME_SIGN}
            </div>
          </div>
        );
      }

      return (
        <div className={css(styles.wrapper)}>
          <div className={css(styles.brand)} onClick={this._homeClicked}>
            {HOME_SIGN}
          </div>
          {modules.chat && (
            <SideMenuItem
              to={() => history.push('/chat')}
              label="Chat"
              image={chatIcon}
              activeImage={chatIcon}
            />
          )}
          {modules.includes('messages') && (
            <SideMenuItem
              to={() => history.push('/messages')}
              label={this.props.intl.formatMessage(terms.messages)}
              image={messageIcon}
              activeImage={messageActiveIcon}
            />
          )}
          {modules.includes('segments') && (
            <SideMenuItem
              to={() => history.push('/segments')}
              label={this.props.intl.formatMessage(terms.segments)}
              image={segmentIcon}
              activeImage={segmentActiveIcon}
            />
          )}
          {modules.includes('clients') && (
            <SideMenuItem
              to={() => history.push('/clients')}
              label={this.props.intl.formatMessage(terms.clients)}
              image={clientsIcon}
              activeImage={clientsActiveIcon}
            />
          )}
          {modules.includes('ctas') && (
            <SideMenuItem
              to={() => history.push('/ctas')}
              label={this.props.intl.formatMessage(terms.callToActions)}
              image={ctaIcon}
              activeImage={ctaActiveIcon}
            />
          )}
          {modules.includes('categories') && (
            <SideMenuItem
              to={() => history.push('/categories')}
              label={this.props.intl.formatMessage(terms.categories)}
              image={categoriesIcon}
              activeImage={categoriesActiveIcon}
            />
          )}
          {modules.includes('insights') && (
            <SideMenuItem
              to={() => history.push('/insights')}
              label={this.props.intl.formatMessage(terms.insights)}
              image={insightsIcon}
              activeImage={insightsActiveIcon}
            />
          )}
        </div>
      );
    }

    static styles = StyleSheet.create({
      wrapper: {
        height: 'calc(100% - 22px)',
        width: SIDE_MENU_WIDTH,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        zIndex: 51,
        top: 0,
        background: '#F9F8F8',
        bottom: 0,
        paddingTop: '22px',
      },
      brand: {
        fontFamily: 'Raleway',
        fontSize: 36,
        cursor: 'pointer',
        textAlign: 'center',
        fontWeight: 500,
        letterSpacing: '0.8px',
        color: '#C6C6C6',
        marginBottom: 12,
      },
    });
  }
);
