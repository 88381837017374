import PropTypes from 'prop-types';
import React, {Component} from 'react';
import moment from 'moment';
import {StyleSheet, css} from 'aphrodite';

import logo from './../../assets/lp/images/lPLogo.png';
import ctaOpenIcon from './../../assets/lp/images/fABCTA.png';
import ctaCloseIcon from './../../assets/lp/images/cTAClose.png';
import emailIcon from './../../assets/lp/images/emailCTA.png';
import phoneIcon from './../../assets/lp/images/phoneCTA.png';
import whatsappIcon from './../../assets/lp/images/phoneCTA.png';
import menu from '../../assets/lp/images/burgerMenu.png';
import back from '../../assets/lp/images/backArrow.png';
import Header from './Header';

export default class MessagePreviewLP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ctaHeight: 20,
      ctaVisible: false,
    };
  }

  static propTypes = {
    lookup: PropTypes.object,
    message: PropTypes.object,
  };

  _renderCta = (cta) => {
    const {styles} = MessagePreviewLP;
    switch (cta.type) {
      case 'web':
        return (
          <div className={css(styles.ReadMorelink)} key={cta.id}>
            <div className={css(styles.ctaLink)}>{cta.defaultLabel}</div>
          </div>
        );
      case 'email':
        return (
          <div className={css(styles.secondPageCta)} key={cta.id}>
            <div className={css(styles.secondPageCtaLabel)}>
              {cta.defaultLabel}
            </div>
            <img
              className={css(styles.secondPageCtaIcon)}
              src={emailIcon}
              alt="email cta"
            />
          </div>
        );
      case 'phone':
        return (
          <div className={css(styles.secondPageCta)} key={cta.id}>
            <div className={css(styles.secondPageCtaLabel)}>
              {cta.defaultLabel}
            </div>
            <img
              className={css(styles.secondPageCtaIcon)}
              src={phoneIcon}
              alt="phone cta"
            />
          </div>
        );

      case 'whatsapp':
        return (
          <div className={css(styles.secondPageCta)} key={cta.id}>
            <div className={css(styles.secondPageCtaLabel)}>
              {cta.defaultLabel}
            </div>
            <img
              className={css(styles.secondPageCtaIcon)}
              src={whatsappIcon}
              alt="whatsapp cta"
            />
          </div>
        );
      default:
    }
  };

  _getCtas = () => {
    return (this.props.message.ctas || [])
      .filter((id) => this.props.lookup.ctas[id])
      .map((id) => this.props.lookup.ctas[id]);
  };

  _onCtaButtonClick = () => {
    if (this.state.ctaVisible) {
      this.setState({ctaVisible: false, ctaHeight: 20});
    } else {
      this.setState({ctaVisible: true, ctaHeight: 350});
    }
  };

  render() {
    const {
      message: {description, title, ctas, categories, publicationDate, image},
    } = this.props;
    const {styles} = MessagePreviewLP;
    const formatedPublicationDate =
      moment(publicationDate).format("DD MMMM'YY");
    const category = {};
    if (categories && categories.length > 0) {
      try {
        category.categoryName =
          this.props.lookup.categories[categories[0]].name;
        category.categoryIcon =
          this.props.lookup.categories[categories[0]].icon;
      } catch (e) {
        console.log('error ', e);
      }
    }
    return (
      <div>
        <div className={css(styles.preview)}>
          <div className={css(styles.container)}>
            <Header logo={logo} menu={menu} back={back} />
            <div className={css(styles.imageDiv)}>
              {!this.state.ctaVisible && image && (
                <img className={css(styles.image)} src={image} alt="message" />
              )}
              {!this.state.ctaVisible && category.categoryName && (
                <div className={css(styles.categoryName)}>
                  {category.categoryName}
                </div>
              )}
            </div>

            {!this.state.ctaVisible && (
              <div className={css(styles.title)}>{title}</div>
            )}
            {!this.state.ctaVisible && (
              <div className={css(styles.publicationDate)}>
                {formatedPublicationDate}
              </div>
            )}

            <div style={{marginBottom: 50}}>
              {!this.state.ctaVisible && (
                <div className={css(styles.description)}>{description}</div>
              )}
              {!this.state.ctaVisible && ctas && (
                <div>
                  {this._getCtas()
                    .filter((cta) => cta.type === 'web')
                    .map(this._renderCta)}
                </div>
              )}
            </div>
            {!this.state.ctaVisible && (
              <img
                className={css(styles.ctaOpen)}
                onClick={this._onCtaButtonClick}
                alt="open cta"
                src={ctaOpenIcon}
              />
            )}

            <div
              className={css(styles.secondPage)}
              style={{height: this.state.ctaHeight}}
            >
              {this._getCtas() && (
                <div>
                  {this._getCtas()
                    .filter(
                      (cta) => cta.type === 'phone' || cta.type === 'email'
                    )
                    .map(this._renderCta)}
                </div>
              )}
              <img
                className={css(styles.ctaClose)}
                onClick={this._onCtaButtonClick}
                alt="close cta"
                src={ctaCloseIcon}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  static styles = StyleSheet.create({
    preview: {
      width: 231,
      height: 430,
      overflow: 'hidden',
      margin: '53px',
      marginRight: '190px',
      marginTop: '70px',
    },
    container: {
      height: '100%',
      width: '100%',
      overflow: 'auto',
      paddingRight: '20px',
    },
    secondPage: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      overflow: 'hidden',
    },
    ctaOpen: {
      position: 'absolute',
      width: 40,
      height: 40,
      left: 225,
      top: 427,
    },
    ctaClose: {
      width: 47,
      height: 47,
      marginBottom: 28,
      marginRight: 15,
    },
    imageDiv: {
      position: 'relative',
    },
    image: {
      width: 164,
      marginLeft: 32,
      marginTop: 6,
    },
    categoryName: {
      bottom: 12,
      right: 0,
      position: 'absolute',
      backgroundColor: '#E05A8F',
      color: 'white',
      fontSize: 10,
      fontWeight: '800',
      paddingLeft: 3,
      paddingRight: 3,
      paddingBottom: 3,
    },
    title: {
      marginTop: 11,
      marginLeft: 30,
      marginRight: 30,
      textAlign: 'center',
      display: 'flex',
      fontSize: 23,
      fontFamily: 'DroidSerif',
      wordBreak: 'break-word',
      justifyContent: 'center',
    },
    description: {
      fontSize: 13,
      fontFamily: 'Raleway',
      color: '#000000',
      lineHeight: '18px',
      marginTop: 15,
      marginBottom: 16,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      display: 'flex',
      paddingLeft: 15,
      paddingRight: 15,
    },
    ReadMorelink: {
      order: 2,
      display: 'flex',
      flexDirection: 'row',
      fontSize: 10,
      marginLeft: 15,
      textDecoration: 'line',
    },
    publicationDate: {
      fontSize: 10,
      marginTop: 5,
      color: '#898989',
      letterSpacing: '0.2px',
      textAlign: 'center',
      fontFamily: 'Raleway',
      fontStyle: 'italic',
    },
    secondPageCta: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginBottom: 16,
      marginRight: 14,
    },
    secondPageCtaLabel: {
      lineHeight: '18px',
      fontSize: 13,
      fontFamily: 'Raleway',
      color: '#000000',
      marginRight: 10,
    },
    secondPageCtaIcon: {
      width: 32,
      height: 32,
      marginRight: 9,
    },
    ctaLink: {
      lineHeight: '18px',
      fontSize: 13,
      fontFamily: 'Raleway',
      color: '#000000',
      textDecoration: 'underline',
    },
  });
}
