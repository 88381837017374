import PropTypes from 'prop-types';
import React, {Component} from 'react';
import moment from 'moment';
import DateTimePicker from 'react-widgets/DatePicker';
import {StyleSheet, css} from 'aphrodite';
import {injectIntl} from 'react-intl';

import MomentLocalizer from 'react-widgets-moment';
import InputWrapper from '../InputWrapper/InputWrapper';
import mainStyles from '../../helpers/styles';

new MomentLocalizer(moment);
// momentLocalizer(moment);

export default injectIntl(
  class DatePicker extends Component {
    static propTypes = {
      date: PropTypes.string,
      label: PropTypes.string,
      error: PropTypes.object,
      touched: PropTypes.bool,
      onBlur: PropTypes.func,
      initialValue: PropTypes.string,
    };

    render() {
      const {
        date,
        label,
        onBlur,
        onChange,
        includeTime,
        // intl
      } = this.props;
      const {styles} = DatePicker;
      const dateValue = date ? new Date(date) : new Date();

      return (
        <InputWrapper>
          <div className={css(styles.inputWrapper)}>
            <label className={css(styles.label)}>{label}</label>
            <DateTimePicker
              value={dateValue}
              onBlur={(e) => onBlur(dateValue)}
              onChange={onChange}
              includeTime={includeTime}
            />
          </div>
        </InputWrapper>
      );
    }

    static styles = StyleSheet.create({
      ...mainStyles,
    });
  }
);
