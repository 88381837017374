import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {getUsers} from '../../redux/modules/users';
import {loadAllSegments} from '../../redux/modules/segments';
import {asyncConnect} from 'redux-connect';
import {renderRoutes} from 'react-router-config';

class Users extends Component {
  static propTypes = {
    children: PropTypes.any,
  };

  render() {
    return <div>{renderRoutes(this.props.route.routes)}</div>;
  }
}

export default asyncConnect([
  {
    key: 'load2',
    promise: (helpers) => helpers.store.dispatch(loadAllSegments()),
  },
  {
    key: 'load',
    promise: (helpers) => helpers.store.dispatch(getUsers()),
  },
])(Users);
