import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import {
  deleteClientFromSegment,
  exportSegment,
} from '../../redux/modules/segments';
import terms from '../../helpers/terms';

import FixedDataTable from '../FixedDataTable/FixedDataTable';
import TextCell from '../FixedDataTable/TextCell';
import {Column} from 'fixed-data-table-2';

export default injectIntl(
  class ClientsInSegment extends Component {
    static propTypes = {
      viewMode: PropTypes.bool.isRequired,
      dispatch: PropTypes.func,
      segment: PropTypes.object,
    };

    _deleteClient = (client) => {
      return new Promise((resolve) => {
        this.props
          .dispatch(deleteClientFromSegment(this.props.segment, client))
          .then(() => {
            resolve('ok');
          });
      });
    };

    _filter = (value) => (client) => {
      return (
        (client.id && client.id.toLowerCase().indexOf(value) !== -1) ||
        (client.name && client.name.toLowerCase().indexOf(value) !== -1)
      );
    };

    render() {
      const {dispatch, segment, viewMode} = this.props;

      const messages = defineMessages({
        clientGroupDeleteConfirmLabel: {
          id: 'ClientsInSegment.confirmLabel',
          defaultMessage:
            'Are you sure you want to permanently delete this client from the target group?',
          description:
            'The text which is displayed when the user is about to remove a client from a target group',
        },
        activeDevicesColumnTitle: {
          id: 'ClientsInSegment.activeDevicesColumnTitle',
          description:
            'The column title of the active devices column in the table on the segment page that shows the clients in a particular segments',
          defaultMessage: 'Active devices',
        },
        messagesOpenedPushedColumnTitle: {
          id: 'ClientsInSegment.messagesOpenedPushedColumnTitle',
          description:
            'The column title of the message opened/pushed ratio column in the table on the segment page that shows the clients in a particular segments',
          defaultMessage: 'Messages Opened / Pushed',
        },
      });

      return (
        <div>
          <FixedDataTable
            inputData={segment.clients || []}
            dispatch={dispatch}
            filter={this._filter}
            deleteItem={!viewMode && this._deleteClient}
            confirmLabel={this.props.intl.formatMessage(
              messages.clientGroupDeleteConfirmLabel
            )}
            isModules={false}
            export={() => this.props.dispatch(exportSegment(segment.id))}
          >
            <Column
              headerTitle={this.props.intl.formatMessage(terms.clientId)}
              flexGrow={1}
              displayCell={(client, props) => (
                <TextCell {...props}>{client.id}</TextCell>
              )}
              width={180}
              sortingField={(client) => client.id}
              columnKey={'id'}
            />
            <Column
              headerTitle={this.props.intl.formatMessage(terms.clientName)}
              flexGrow={1}
              displayCell={(client, props) => (
                <TextCell {...props}>{client.name}</TextCell>
              )}
              width={180}
              sortingField={(client) => client.name}
              columnKey={'name'}
            />
            {/* <Column
              headerTitle={this.props.intl.formatMessage(
                messages.activeDevicesColumnTitle
              )}
              displayCell={(client, props) => (
                <TextCell {...props}>{client.analytics.activeDevices}</TextCell>
              )}
              width={180}
              sortingField={(client) => client.analytics.activeDevices}
              columnKey={'activeDevices'}
            /> */}
            {/* <Column
              headerTitle={this.props.intl.formatMessage(
                messages.messagesOpenedPushedColumnTitle
              )}
              displayCell={(client, props) => (
                <TextCell>
                  {(client.analytics || {}).uniqueMessagesOpened || 0}
                  {'/'}
                  {(client.analytics || {}).uniqueMessagePushesReceived || 0}
                </TextCell>
              )}
              width={230}
              // sortingField={(client) => (client.analytics.uniqueMessagesOpened / client.analytics.uniqueMessagePushesReceived)}
              // columnKey={'messagesOpened'} TODO: (howto) sort?
            /> */}
          </FixedDataTable>
        </div>
      );
    }
  }
);
