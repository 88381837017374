import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import {reducer as reduxAsyncConnect} from 'redux-connect';

import {reducer as auth} from './auth';
import {reducer as brand} from './brand';
import {reducer as segments} from './segments';
import {reducer as messages} from './message';
import {reducer as categories} from './categories';
import {reducer as clients} from './clients';
import {reducer as lookup} from './lookups';
import {reducer as cta} from './ctas';
import {reducer as chat} from './chat';
import {reducer as users} from './users';
import i18n from './i18n';

export default combineReducers({
  reduxAsyncConnect,
  segments,
  messages,
  categories,
  clients,
  lookup,
  auth,
  brand,
  cta,
  chat,
  users,
  i18n,
  routing: routerReducer,
});
