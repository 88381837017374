import moment from 'moment';
import Cookies from 'universal-cookie';
import routes from '../../helpers/routes';
// import {addLocaleData} from 'react-intl';
import _ from 'lodash';
import LOCAL_TRANSLATIONS from '../../i18n/default.translations.json';
const ACTION_SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
const ACTION_FETCH_I18N_DATA_SUCCESS = 'FETCH_I18N_DATA_SUCCESS';
const FALLBACK_LANGUAGE_KEY = 'en';
const cookie = new Cookies();

// TODO [I18N] dynamic solution for this?
// https://github.com/yahoo/react-intl/issues/777
// import nl from 'react-intl/locale-data/nl';
// import en from 'react-intl/locale-data/en';

import 'moment/locale/nl';
import 'moment/locale/pl';

// addLocaleData([...en, ...nl]);

const initialState = {
  language: getLocalLanguagePreference(),
  version: 0,
  translations: LOCAL_TRANSLATIONS,
  supportedLanguages: [FALLBACK_LANGUAGE_KEY],
  messages: LOCAL_TRANSLATIONS[getLocalLanguagePreference()],
  initialized: false,
};

function getLocalLanguagePreference() {
  return (
    cookie.get('language') ||
    getBrowserLanguagePreference() ||
    FALLBACK_LANGUAGE_KEY
  );
}

function getBrowserLanguagePreference() {
  // `browserLanguage` is used in IE, `language` in all other vendors
  return navigator.browserLanguage || navigator.language;
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_FETCH_I18N_DATA_SUCCESS:
      return {
        ...state,
        initialized: true,
        language: action.data.language,
        translations: action.data.translations,
        messages: action.data.translations[action.data.language],
        supportedLanguages: action.data.supportedLanguages || [
          FALLBACK_LANGUAGE_KEY,
        ],
        version: state.version + 1,
      };
    case ACTION_SWITCH_LANGUAGE:
      return {
        ...state,
        messages: state.translations[action.data.language],
        language: action.data.language,
        version: state.version + 1,
      };
    default:
      return state;
  }
}

/*
 * Pick the language that is supported and that is closest to the given language
 */
function getSupportedLanguage(language, supportedLanguages) {
  if (supportedLanguages.includes(language)) {
    return language;
  } else if (supportedLanguages.includes(splitLanguageAndRegion(language)[0])) {
    return splitLanguageAndRegion(language)[0];
  } else {
    return FALLBACK_LANGUAGE_KEY;
  }
}

/*
 *  Update moment locale globally. Not an action creator. Make sure that all possible supported languages have been loaded in.
 */
export function updateMomentLocale(language, supportedLanguages) {
  return moment.locale(getSupportedLanguage(language, supportedLanguages));
}

/*
 * Takes an iso 639-* language classification code (like 'nl_be' or 'nl-be') and splits country and region into an array [country, region] (or [country] if no region is set in the code)
 */
function splitLanguageAndRegion(code) {
  return code.split(/_|-/);
}

export function fetchI18nData(brand) {
  return async (dispatch, getState) => {
    try {
      const {i18n} = brand;
      const supportedLanguages = i18n.supported.webConsole;
      const language = getSupportedLanguage(
        getState().auth.language || getLocalLanguagePreference(),
        supportedLanguages
      );
      const response = await fetch(i18n.translations.webConsole);
      const allTranslations = await response.json();
      // filter out unsupported translations
      const translations = _.pick(allTranslations, supportedLanguages);

      moment.locale(language);
      return dispatch({
        type: ACTION_FETCH_I18N_DATA_SUCCESS,
        data: {translations, language, supportedLanguages},
      });
    } catch (e) {
      console.error('error in fetchI18nData', e);
    }
  };
}

export function switchLanguage(inputLanguage) {
  return async (dispatch, getState) => {
    const supportedLanguages = getState().i18n.supportedLanguages;
    const language = getSupportedLanguage(inputLanguage, supportedLanguages);
    updateMomentLocale(language, getState().i18n.supportedLanguages);
    dispatch({type: ACTION_SWITCH_LANGUAGE, data: {language}});
    //  save language setting locally and remotely
    cookie.set('language', language);
    dispatch({
      promise: (client) =>
        client.post(`${routes.profile}`, {profile: {language}}).catch((e) => {
          console.log('error in saving language with /profile call', e);
        }),
    });
  };
}

// for debugging purposes
// export function removeStoredTranslations() {
//   return async dispatch => {
//     try {
//       console.log('remove');
//       await removeItem(STORAGE_TRANSLATIONS_KEY);
//       dispatch({type: 'remove translations'});
//     } catch (e) {
//       console.log('error removing stored translations', e);
//     }
//   };
// }
