import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {asyncConnect} from 'redux-connect';
import Page from '../Page/Page';
import {colorDefinitions} from '../../helpers/colors';
import zopim from '../../helpers/zopim';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fonts.css';
import {getBrand} from '../../redux/modules/brand';

export default asyncConnect([
  {
    key: 'load',
    promise: (helpers) => {
      return helpers.store.dispatch(getBrand());
    },
  },
])(
  class App extends Component {
    static childContextTypes = {
      rebass: PropTypes.object,
      location: PropTypes.object,
    };

    static propTypes = {
      children: PropTypes.any,
    };

    getChildContext() {
      return {
        rebass: {
          colors: colorDefinitions,
          fontSizes: [64, 48, 24, 18, 16, 14, 12],
          Button: {
            backgroundColor: 'secondary',
          },
        },
        location: this.props.location,
      };
    }

    componentDidMount = () => {
      zopim();
    };

    render() {
      return (
        <div>
          <Page {...this.props} />
        </div>
      );
    }
  }
);
