import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {StyleSheet, css} from 'aphrodite';
import {FormattedMessage, injectIntl, defineMessages} from 'react-intl';
import {Modal} from 'antd';
import {
  UploadButton,
  Button,
  FloatingLabel,
} from '../../helpers/formComponents';
import {
  downloadNewClients,
  uploadClientsFromExcel,
} from '../../redux/modules/segments';
import Spinner from '../../components/Spinner/Spinner';
import Form from '../../components/Form/Form';
import terms from '../../helpers/terms';
import AddClientValidation from './AddClientValidation';
import {getBrandId} from '../../redux/modules/brand';
import _ from 'lodash';
import {showErrors} from '../../helpers/errors';

class AddClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconNameAdd: null,
      iconNameReplace: null,
      loading: false,
      clientId: '',
      name: '',
      emailId: '',
    };
  }

  static propTypes = {
    shouldRender: PropTypes.bool.isRequired,
    submit: PropTypes.func.isRequired,
    segment: PropTypes.object.isRequired,
    hasPassword: PropTypes.bool.isRequired,
    dispatch: PropTypes.func,
    addingClient: PropTypes.bool,
    isV2: PropTypes.bool,
  };

  _submit = () => {
    const data = {
      clientId: this.state.clientId,
      name: this.state.name,
      emailId: this.state.emailId,
    };

    const brandId = this.props.dispatch(getBrandId());
    const errors = AddClientValidation(data, {brandId});
    if (_.isEmpty(errors)) {
      this.props.submit(data);
    } else {
      showErrors(errors);
    }
  };

  _onChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  _onOK = () => {
    if (this.props.hasPassword && !this.props.isV2) {
      this.props.dispatch(downloadNewClients(this.props.segment.name));
    }
  };

  _onDropAddClients = (files) => {
    this.setState(
      {
        loading: true,
        iconNameAdd: null,
      },
      () => {
        this.props
          .dispatch(uploadClientsFromExcel(this.props.segment, files[0], false))
          .then(() => {
            this._uploadFeedback(files[0].name);
          });
      }
    );
  };

  _onDropReplaceClients = (files) => {
    this.setState(
      {
        loading: true,
        iconNameReplace: null,
      },
      () => {
        this.props
          .dispatch(uploadClientsFromExcel(this.props.segment, files[0], true))
          .then(() => {
            this._uploadFeedback(files[0].name);
          });
      }
    );
  };

  _uploadFeedback = (fileName) => {
    const {newClients, deletedClients, totalClients} = this.props.segment;
    let msg = '';

    const messages = defineMessages({
      clientsAddedFeedback: {
        id: 'AddClient.clientsAddedFeedback',
        description:
          'A feedback message which is displayed after uploading a client excel sheet. It tells the user how many new clients have been uploaded.',
        defaultMessage: 'new client(s) added',
      },
      clientsDeletedFeedback: {
        id: 'AddClient.clientsDeletedFeedback',
        description:
          'A feedback message which is displayed after uploading a client excel sheet. It tells the user how many new clients have been deleted.',
        defaultMessage: 'client(s) deleted',
      },
      clientsTotalFeedback: {
        id: 'AddClient.clientsTotalFeedback',
        description:
          'A feedback message which is displayed after uploading a client excel sheet. It tells the user how what the total number of clients is after the upload.',
        defaultMessage: 'Total client(s)',
      },
      clientsUploadConfirmation: {
        id: 'AddClient.clientsUploadConfirmation',
        description:
          'A feedback message which is displayed after uploading a client excel sheet. It confirms for the user that the upload has been successful.',
        defaultMessage:
          'The client list has been updated for this target group',
      },
    });

    if (newClients > 0) {
      msg += `${newClients} ${this.props.intl.formatMessage(
        messages.clientsAddedFeedback
      )}, `;
    }
    if (deletedClients > 0) {
      msg += `${deletedClients} ${this.props.intl.formatMessage(
        messages.clientsDeletedFeedback
      )}, `;
    }

    msg += `${this.props.intl.formatMessage(
      messages.clientsTotalFeedback
    )}: ${totalClients}`;

    this.setState({
      iconNameReplace: fileName,
      loading: false,
    });

    Modal.info({
      title: this.props.intl.formatMessage(messages.clientsUploadConfirmation),
      content: msg,
      onOk: this._onOK,
      okText: this.props.intl.formatMessage(terms.ok),
    });
  };

  _defineMessages = () => {
    return defineMessages({
      enterClientIdPlaceholder: {
        id: 'AddClient.enterClientIdPlaceholder',
        description: 'The placeholder on the client ID input field',
        defaultMessage: 'Enter Client ID',
      },
      enterClientNamePlaceholder: {
        id: 'AddClient.enterClientNamePlaceholder',
        description: 'The placeholder on the client name input field',
        defaultMessage: 'Enter Client Name',
      },
      enterClientEmailPlaceholder: {
        id: 'AddClient.enterClientEmailPlaceholder',
        defaultMessage: 'Enter Client Email',
        description: 'The placeholder text of the client email input field',
      },
      addFromExcelButtonLabel: {
        id: 'AddClient.addFromExcelButtonLabel',
        description:
          'The label on the button that uploads new clients and adds them to the current target group',
        defaultMessage: 'Add from excel',
      },
      replaceFromExcelButtonLabel: {
        id: 'AddClient.replaceFromExcelButtonLabel',
        description:
          'The label on the button that uploads client excel sheet and replaces them the current clients (in the current target group)',
        defaultMessage: 'Replace from excel',
      },
    });
  };

  render() {
    const {addingClient, shouldRender, replaceDisabled, isV2} = this.props;
    const {iconNameAdd, iconNameReplace, loading, clientId, name, emailId} =
      this.state;
    const {styles} = AddClient;

    const messages = this._defineMessages();

    if (!shouldRender) return null;

    return (
      <div className={css(styles.wrapper)}>
        <div>
          <h5>
            <FormattedMessage
              id="AddClient.addClientsToTargetGroupHeader"
              description="The header above the interface to add clients to your target group (on the segments page)"
              defaultMessage="Manually add clients to your target group"
            />
          </h5>
          <Form style={styles.form}>
            <FloatingLabel
              label={this.props.intl.formatMessage(terms.clientId)}
              placeholder={this.props.intl.formatMessage(
                messages.enterClientIdPlaceholder
              )}
              type="text"
              name="clientId"
              value={clientId}
              onChange={this._onChange.bind(this, 'clientId')}
            />
            <FloatingLabel
              label={this.props.intl.formatMessage(terms.clientName)}
              placeholder={this.props.intl.formatMessage(
                messages.enterClientNamePlaceholder
              )}
              type="text"
              name="name"
              value={name}
              onChange={this._onChange.bind(this, 'name')}
            />
            {/* Hiding the email field */}
            {isV2 && false && (
              <FloatingLabel
                label={this.props.intl.formatMessage(terms.clientEmail)}
                placeholder={this.props.intl.formatMessage(
                  messages.enterClientEmailPlaceholder
                )}
                type="text"
                name="emailId"
                value={emailId}
                onChange={this._onChange.bind(this, 'emailId')}
              />
            )}
            <Button
              wrapperStyle={styles.button}
              onClick={this._submit}
              loading={addingClient}
            >
              {this.props.intl.formatMessage(terms.add)}
            </Button>
          </Form>
        </div>
        <div>
          <h5>
            <FormattedMessage
              id="AddClient.uploadClientsHeader"
              description="The header above the buttons which upload an excel sheet with clients and adds them to the current target group"
              defaultMessage="Upload Clients from Excel file"
            />
          </h5>
          <div className={css(styles.uploadAndSpinner)}>
            <div>
              <UploadButton
                onDrop={this._onDropAddClients}
                multiple={false}
                label={this.props.intl.formatMessage(
                  messages.addFromExcelButtonLabel
                )}
                assetsName={iconNameAdd}
              />
            </div>
            {!replaceDisabled && (
              <div>
                <UploadButton
                  onDrop={this._onDropReplaceClients}
                  multiple={false}
                  label={this.props.intl.formatMessage(
                    messages.replaceFromExcelButtonLabel
                  )}
                  assetsName={iconNameReplace}
                />
              </div>
            )}
            <div className={css(styles.spinner)}>
              {loading ? <Spinner /> : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  static styles = StyleSheet.create({
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '80%',
    },
    form: {
      marginTop: 18,
      width: 380,
    },
    button: {
      width: '100%',
    },
    spinner: {
      position: 'relative',
    },
    uploadAndSpinner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
    },
  });
}

export default injectIntl(AddClient);
