import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {StyleSheet} from 'aphrodite';
import {updateCtaLocally, uploadCtaIcsFile} from '../../redux/modules/ctas';
import validate from './CTASValidation';
import Form from '../../components/Form/Form';
import {
  UpsertButton,
  Select,
  FloatingLabel,
  UploadButton,
} from '../../helpers/formComponents';
import {injectIntl, defineMessages} from 'react-intl';
import {showErrors} from '../../helpers/errors';
import _ from 'lodash';

class CTAForm extends Component {
  static propTypes = {
    brand: PropTypes.object,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func,
    cta: PropTypes.object,
    submit: PropTypes.func,
  };

  state = {icsFileName: null};

  _getCtaType = () => {
    const allowed = this.props.brand.features.cta.allowed;
    const {intl} = this.props;

    const messages = defineMessages({
      email: {
        id: 'CTAForm.emailOptionLabel',
        defaultMessage: 'Email',
        description: 'The email cta option in the cta type selector',
      },
      phone: {
        id: 'CTAForm.phoneOptionLabel',
        defaultMessage: 'Phone',
        description: 'The phone cta option in the cta type selector',
      },
      web: {
        id: 'CTAForm.webOptionLabel',
        defaultMessage: 'Web',
        description: 'The web cta option in the cta type selector',
      },
      chat: {
        id: 'CTAForm.chatOptionLabel',
        defaultMessage: 'Chat',
        description: 'The chat cta option in the cta type selector',
      },
      prize: {
        id: 'CTAForm.prizeOptionLabel',
        defaultMessage: 'Prize',
        description: 'The chat cta option in the cta type selector',
      },
      whatsapp: {
        id: 'CTAForm.whatsappOptionLabel',
        defaultMessage: 'Whatsapp',
        description: 'The whatsapp cta option in the cta type selector',
      },
      calendar: {
        id: 'CTAForm.calendarOptionLabel',
        defaultMessage: 'Calendar Invite',
        description: 'The Calendar Invite cta option in the cta type selector',
      },
    });
    return [
      {label: intl.formatMessage(messages.email), id: 'email'},
      {label: intl.formatMessage(messages.phone), id: 'phone'},
      {label: intl.formatMessage(messages.web), id: 'web'},
      {label: intl.formatMessage(messages.chat), id: 'chat'},
      {label: intl.formatMessage(messages.prize), id: 'prize'},
      {label: intl.formatMessage(messages.whatsapp), id: 'whatsapp'},
      {label: intl.formatMessage(messages.calendar), id: 'calendar'},
      // Filter on allowed CTA types for the brand
    ].filter((cta) => allowed.indexOf(cta.id) !== -1);
  };

  _getCtaSegmentType = () => {
    const messages = defineMessages({
      all: {
        id: 'CTAForm.allOptionLabel',
        defaultMessage: 'All',
        description: 'The all option in the cta target group type selector',
      },
      group: {
        id: 'CTAForm.groupOptionLabel',
        defaultMessage: 'Group',
        description: 'The group option in the cta target group type selector',
      },
    });

    return [
      {label: this.props.intl.formatMessage(messages.all), id: 'all'},
      {label: this.props.intl.formatMessage(messages.group), id: 'group'},
    ];
  };

  _valueChange = (key, value) => {
    let cta = {...this.props.cta};
    cta[key] = value;
    this.props.dispatch(updateCtaLocally(cta));
  };

  _onChange = (field) => (value) => {
    this._valueChange(field, value);
  };

  _submit = () => {
    const errors = validate(this.props.cta);
    if (_.isEmpty(errors)) {
      this.props.submit(this.props.cta);
    } else {
      showErrors(errors);
    }
  };

  _onDrop = async (files) => {
    try {
      const data = await this.props.dispatch(uploadCtaIcsFile(files[0]));
      this._onChange('defaultValue')(data.url);
      this.setState({icsFileName: files[0].name});
    } catch (e) {
      alert('Error uploading file');
    }
  };

  render() {
    const {
      cta: {
        defaultLabel = '',
        defaultValue = '',
        name = '',
        type = '',
        segmentType = '',
        defaultMessage,
      },
      submitting,
      isEdit,
    } = this.props;
    const {styles} = CTAForm;

    const messages = defineMessages({
      ctaNameLabel: {
        id: 'CTAForm.ctaNameLabel',
        defaultMessage: 'CTA Name',
        description:
          'The label above the cta name field when editing an existing cta',
      },
      ctaTypeLabel: {
        id: 'CTAForm.ctaTypeLabel',
        defaultMessage: 'Type of CTA',
        description:
          'The label above the cta type selector when editing an existing cta',
      },
      targetGroupLabel: {
        id: 'CTAForm.targetGroupLabel',
        defaultMessage: 'Target Group Type',
        description:
          'The label above the cta target group field when editing an existing cta',
      },
      defaultLabelLabel: {
        id: 'CTAForm.defaultLabelLabel',
        defaultMessage: 'Default Label',
        description:
          'The label above the cta default label field when editing an existing cta',
      },
      defaultValueLabel: {
        id: 'CTAForm.defaultValueLabel',
        defaultMessage: 'Default Value',
        description:
          'The label above the cta default value field when editing an existing cta',
      },
      defaultMessageLabel: {
        id: 'CTAForm.defaultMessageLabel',
        defaultMessage: 'Default Message',
        description:
          'The label above the cta default message field when editing an existing cta',
      },
      ctaNamePlaceholder: {
        id: 'CTAForm.ctaNamePlaceholder',
        defaultMessage: 'Name your CTA for your reference',
        description:
          'The placeholder in the cta name input field when adding a new cta',
      },
      ctaTypePlaceholder: {
        id: 'CTAForm.ctaTypePlaceholder',
        defaultMessage: 'Enter Type Of CTA',
        description:
          'The placeholder in the cta type input field when adding a new cta',
      },
      targetGroupPlaceholder: {
        id: 'CTAForm.targetGroupPlaceholder',
        defaultMessage: 'Enter Target Group Type',
        description:
          'The placeholder in the cta target Group input field when adding a new cta',
      },
      defaultLabelPlaceholder: {
        id: 'CTAForm.defaultLabelPlaceholder',
        defaultMessage: 'Enter Default Label',
        description:
          'The placeholder in the cta default label input field when adding a new cta',
      },
      defaultValuePlaceholder: {
        id: 'CTAForm.defaultValuePlaceholder',
        defaultMessage: 'Enter Default Value',
        description:
          'The placeholder in the cta default value input field when adding a new cta',
      },
      defaultMessagePlaceholder: {
        id: 'CTAForm.defaultMessageLabel',
        defaultMessage: 'Enter Default message',
        description:
          'The placeholder in the cta default message input field when adding a new cta',
      },
      uploadIcsFileButtonLabel: {
        id: 'CTAForm.uploadIcsFileButtonLabel',
        defaultMessage: 'Upload .ics File',
        description:
          'The label on the button which uploads an .ics file for a new cta',
      },
    });

    return (
      <Form style={styles.form}>
        <FloatingLabel
          label={this.props.intl.formatMessage(messages.ctaNameLabel)}
          type="text"
          placeholder={this.props.intl.formatMessage(
            messages.ctaNamePlaceholder
          )}
          autoFocus={!isEdit}
          value={name}
          name="name"
          onValueChange={this._valueChange}
        />
        <Select
          local={true}
          options={this._getCtaType()}
          placeholder={this.props.intl.formatMessage(
            messages.ctaTypePlaceholder
          )}
          label={this.props.intl.formatMessage(messages.ctaTypeLabel)}
          multiple={false}
          onChange={this._onChange('type')}
          required={true}
          name="type"
          value={type}
        />
        <Select
          local={true}
          options={this._getCtaSegmentType()}
          placeholder={this.props.intl.formatMessage(
            messages.targetGroupPlaceholder
          )}
          label={this.props.intl.formatMessage(messages.targetGroupLabel)}
          multiple={false}
          onChange={this._onChange('segmentType')}
          required={true}
          name="segmentType"
          value={segmentType}
        />
        <FloatingLabel
          label={this.props.intl.formatMessage(messages.defaultLabelLabel)}
          type="text"
          placeholder={this.props.intl.formatMessage(
            messages.defaultLabelPlaceholder
          )}
          value={defaultLabel}
          name="defaultLabel"
          onValueChange={this._valueChange}
        />
        <FloatingLabel
          label={this.props.intl.formatMessage(messages.defaultValueLabel)}
          type="text"
          placeholder={this.props.intl.formatMessage(
            messages.defaultValuePlaceholder
          )}
          disabled={type === 'calendar'}
          value={defaultValue}
          name="defaultValue"
          onValueChange={this._valueChange}
        />
        {type === 'calendar' && (
          <UploadButton
            onDrop={this._onDrop}
            multiple={false}
            label={this.props.intl.formatMessage(
              messages.uploadIcsFileButtonLabel
            )}
            assetsName={this.state.icsFileName}
          />
        )}
        {type === 'whatsapp' && (
          <FloatingLabel
            label={this.props.intl.formatMessage(messages.defaultMessageLabel)}
            type="text"
            placeholder={this.props.intl.formatMessage(
              messages.defaultMessagePlaceholder
            )}
            value={defaultMessage}
            name="defaultMessage"
            onValueChange={this._valueChange}
          />
        )}
        <UpsertButton
          onClick={this._submit}
          wrapperStyle={styles.button}
          loading={submitting}
          value={isEdit}
        />
      </Form>
    );
  }

  static styles = StyleSheet.create({
    form: {
      marginTop: 20,
      width: 380,
    },
    button: {
      width: '100%',
    },
  });
}

export default injectIntl(CTAForm);
