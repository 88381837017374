import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {css, StyleSheet} from 'aphrodite';
/**
 * Created by deepaksisodiya on 29/03/16.
 */

export default class Checkbox extends Component {
  static propTypes = {
    isChecked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string,
  };

  render() {
    const {isChecked, onChange, label} = this.props;
    const {styles} = Checkbox;
    return (
      <div className={css(styles.wrapper)}>
        <label className={css(styles.label)}>{label}</label>
        <input
          type="checkbox"
          value={isChecked}
          checked={isChecked}
          onChange={(e) => onChange(e.target.checked)}
        />
      </div>
    );
  }

  static styles = StyleSheet.create({
    label: {
      marginRight: '20px',
      position: 'relative',
      fontSize: '14px',
      fontWeight: 600,
      color: '#666666',
    },
  });
}
