import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {logout} from '../../redux/modules/auth';
import {css, StyleSheet} from 'aphrodite';
import {colors} from '../../helpers/colors';
import {SIDE_MENU_WIDTH} from '../SideMenu/SideMenu';
import {makeLogoutUrl} from '../../redux/modules/auth';
import INSTAGRAM_ICON from '../../assets/images/instagram.png';
import {getInstagramAuthUrl} from '../../redux/modules/brand';
import {Dropdown} from 'react-bootstrap';

export let MARGIN_HORIZONTAL = 50;
export let HEADER_PADDING = {top: 25, bottom: 25};

export const headerHeight = '6em';

class Header extends Component {
  static propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    ideas: PropTypes.object,
    dispatch: PropTypes.func,
  };

  _onLogout = () => {
    this.props.dispatch(logout());
    window.location.assign(makeLogoutUrl());
  };

  _onUserSettings = () => {
    window.location = '#users';
  };

  _handleInstagramAuthorization = async () => {
    const {authorizationUrl} = await this.props.dispatch(getInstagramAuthUrl());
    window.location = authorizationUrl;
  };

  _onIdeas = () => {
    window.location.assign('');
  };

  render() {
    const {styles} = Header;
    const {
      title,
      links: {ideas},
      isAdmin,
      modules,
    } = this.props;

    // TODO make font-weight lighter of the menu nav items

    return (
      <header className={css(styles.headerWrapper)}>
        <h1 className={css(styles.titleText)}>{title}</h1>
        <div className={css(styles.menu)}>
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              variant="sm"
              style={{
                color: colors.copyBlack,
                textDecoration: 'none',
              }}
            >
              {this.props.name}
            </Dropdown.Toggle>

            <Dropdown.Menu align="end">
              {ideas && (
                <Dropdown.Item href={ideas}>
                  <FormattedMessage
                    id="Header.ideaPortalLink"
                    description="The link to the Idea Portal in the dropdown menu in the top-right of the app"
                    defaultMessage="Idea Portal"
                  />
                </Dropdown.Item>
              )}

              {isAdmin &&
                modules.includes('userSettings') &&
                this.props.loggedInAs !==
                  'authorization.instagram@ppdconnect.com' && (
                  <Dropdown.Item href="#" onClick={this._onUserSettings}>
                    <FormattedMessage
                      id="Header.userSettingsLink"
                      description="The link to the admin user settings in the dropdown menu in the top-right of the app"
                      defaultMessage="User Settings"
                    />
                  </Dropdown.Item>
                )}

              {isAdmin &&
                (modules.includes('instagram') ||
                  this.props.loggedInAs ===
                    'authorization.instagram@ppdconnect.com') && (
                  <Dropdown.Item
                    href="#"
                    onClick={this._handleInstagramAuthorization}
                  >
                    <FormattedMessage
                      id="Header.instagramAuthorizeLink"
                      description="The link to the authorization of instagram in the dropdown menu in the top-right of the app"
                      defaultMessage="Connect with"
                    />
                    <img
                      src={INSTAGRAM_ICON}
                      alt={'Instagram authorization'}
                      className={css(styles.instagramIcon)}
                    />
                  </Dropdown.Item>
                )}

              {/* {supportedLanguages.length > 1 && <Dropdown.Item href="#">
                <FormattedMessage
                  id="Header.languageSettingsLink"
                  description="The link to the language switching menu in the dropdown menu in the top-right of the app"
                  defaultMessage="Change Language"
                />

              </Dropdown.Item>}

              {supportedLanguages.length > 1 && supportedLanguages
                  .sort((a, b) =>
                    languages[a].nativeName.localeCompare(
                      languages[b].nativeName,
                    ),
                  )
                  .map(language => (
                    <Dropdown.Item key={language} href="#" onClick={() => {
                      this.props.dispatch(switchLanguage(language));
                    }}>
                      <span
                        className={css(
                          language === currentLanguage &&
                          styles.currentLanguageNavItem,
                        )}
                      >
                        {languages[language].flag}
                        &nbsp;
                        {languages[language].nativeName}
                      </span>
                    </Dropdown.Item>
                  ))} */}

              <Dropdown.Item href="#" onClick={this._onLogout}>
                <FormattedMessage
                  id="Header.logoutButton"
                  description="The logout button in the dropdown menu in the top-right of the addpp"
                  defaultMessage="Logout"
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* <div>
            <Button
              style={{
                margin: 0, padding: 0, backgroundColor: colors.white,
                color: colors.copyBlack
              }}
              onClick={() => this.setState({ menuOpen: !menuOpen })}
            >
              <span className={css(styles.countryCode)}>{this.props.name}</span>
              <FaAngleDown />
            </Button>
            
          </div> */}
        </div>
      </header>
    );
  }

  static styles = StyleSheet.create({
    headerWrapper: {
      position: 'absolute',
      width: `calc(100% - ${SIDE_MENU_WIDTH})`,
      backgroundColor: colors.white,
      display: 'flex',
      zIndex: 50,
      paddingTop: HEADER_PADDING.top,
      paddingBottom: HEADER_PADDING.bottom,
      marginLeft: SIDE_MENU_WIDTH,
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'space-between',
    },
    menu: {
      marginRight: MARGIN_HORIZONTAL,
      justifyContent: 'flex-end',
      textAlign: 'left',
    },
    countryCode: {
      fontFamily: 'Raleway',
      fontSize: 14,
      fontWeight: 400,
      color: '#1d1d1b',
    },
    titleText: {
      margin: 0,
      marginLeft: MARGIN_HORIZONTAL,
      fontFamily: 'Raleway',
      fontSize: 32,
      fontWeight: 500,
      color: '#1d1d1b',
    },
    currentLanguageNavItem: {
      textDecoration: 'underline',
    },
    instagramIcon: {height: 18, width: 18, marginLeft: 6},
    hideMenu: {
      display: 'none',
    },
  });
}

export default connect((state) => {
  return {
    loggedIn: state.auth.loggedIn,
    name: state.brand.name,
    links: state.brand.links || {},
    isAdmin: state.brand.isAdmin,
    supportedLanguages: state.i18n.supportedLanguages,
    currentLanguage: state.i18n.language,
    modules: state.brand.features.modules,
    loggedInAs: state.auth.email,
  };
})(Header);
