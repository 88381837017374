import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {css, StyleSheet} from 'aphrodite';
import {FormattedMessage} from 'react-intl';

// import OpenChart from '../../components/Charts/OpenChart';

export default class MessagesChart extends Component {
  static propTypes = {
    data: PropTypes.array,
    brandId: PropTypes.string,
    webConsoleType: PropTypes.string,
  };

  render() {
    const {styles} = MessagesChart;
    // const {messages, brandId, webConsoleType} = this.props;

    return (
      <div className={css(styles.chartsContainer)}>
        <div className={css(styles.graphHeader)}>
          <label>
            <span className={css(styles.chartLabelDark)}>
              <FormattedMessage
                id="MessageOverview.impressionsHighlighted"
                description="The first (highlighted) part in the *Impressions* per day text above the impressions graph"
                defaultMessage="Impressions"
              />
            </span>
            <span>&nbsp;</span>
            <span className={css(styles.chartLabelLight)}>
              <FormattedMessage
                id="MessageOverview.impressionsUnhighlighted"
                description="The second (unhighlighted) part in the *Impressions* per day text above the impressions graph"
                defaultMessage="per day"
              />
            </span>
          </label>
          <div className={css(styles.DateSelectorButtonContainer)}>
            <div className={css(styles.dateSelectorButtonActive)}>
              <span>{'Active'}</span>
            </div>
            <div className={css(styles.dateSelectorButtonDefault)}>
              <span>{'Default'}</span>
            </div>
            <div className={css(styles.dateSelectorButtonDisabled)}>
              <span>{'Disabled'}</span>
            </div>
          </div>
        </div>
        <div className={css(styles.graphDiv)}>
          {/* <OpenChart
            messages={messages}
            brandId={brandId}
            webConsoleType={webConsoleType}
          /> */}
        </div>
      </div>
    );
  }

  static chartLabel = {
    marginBottom: 22,
    fontFamily: 'Raleway',
    fontSize: 18,
    fontWeight: 400,
  };

  static dateSelectorButtonStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Raleway',
    fontSize: 12,
    weight: 400,
    color: '#878787',
    backgroundColor: '#ffffff',
    alignItems: 'center',
    height: 26,
    lineHeight: 15,
    paddingLeft: 6,
    paddingRight: 6,
    marginLeft: 12,
    borderRadius: 12.5,
    border: '1px solid #9AD4DF',
  };

  static styles = StyleSheet.create({
    emailImage: {
      paddingLeft: '20px',
    },
    chartsContainer: {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
    },
    graphHeader: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      alignSelf: 'center',
      textAlign: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: 5,
    },
    graphDiv: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
    },
    chartLabelDark: {
      color: '#1D1D1B',
      ...MessagesChart.chartLabel,
    },
    chartLabelLight: {
      color: '#878787',
      ...MessagesChart.chartLabel,
    },
    DateSelectorButtonContainer: {
      display: 'none', // TODO: Set 'flex' to enable date selector button
      flexDirection: 'row',
    },
    dateSelectorButtonDefault: {
      ...MessagesChart.dateSelectorButtonStyle,
      ':hover': {
        color: '#ffffff',
        backgroundColor: '#9AD4DF',
      },
    },
    dateSelectorButtonActive: {
      ...MessagesChart.dateSelectorButtonStyle,
      color: '#ffffff',
      backgroundColor: '#9AD4DF',
    },
    dateSelectorButtonDisabled: {
      ...MessagesChart.dateSelectorButtonStyle,
      backgroundColor: '#ffffff',
      color: '#DADADA',
      border: '1px solid #DADADA',
    },
  });
}
