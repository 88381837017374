import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from './Button';

export default class UpsertButton extends Component {
  static propTypes = {
    value: PropTypes.any,
    onClick: PropTypes.func,
  };

  _getButtonLabel(value) {
    if (value) {
      return (
        <FormattedMessage
          id="UpsertButton.updateButtonLabel"
          defaultMessage="Update"
          description="The label on the button that updates an existing cta, client, message, etc.."
        />
      );
    } else {
      return (
        <FormattedMessage
          id="UpsertButton.createButtonLabel"
          defaultMessage="Create"
          description="The label on the button that creates a new cta, client, message, etc.."
        />
      );
    }
  }

  render() {
    // TODO 'value' is a bad prop name, as it might interfere with other Button props that should be passed down
    const {value, onClick, ...rest} = this.props;

    return (
      <Button onClick={onClick} {...rest}>
        {this._getButtonLabel(value)}
      </Button>
    );
  }
}
