import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {StyleSheet, css} from 'aphrodite';
import ToggleImage from '../ToggleImage/ToggleImage';

export default class SideMenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovering: false,
      showLabel: false,
    };
  }

  _onMouseOver = () => {
    this.setState({showLabel: true});
  };

  _onMouseOut = () => {
    this.setState({showLabel: false});
  };

  static propTypes = {
    to: PropTypes.func.isRequired,
    image: PropTypes.string.isRequired,
    activeImage: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  };

  render() {
    const {styles} = SideMenuItem;
    return (
      <div className={css(styles.container)}>
        <div
          onClick={this.props.to}
          className={css(styles.item)}
          onMouseOver={this._onMouseOver}
          onMouseOut={this._onMouseOut}
        >
          <ToggleImage
            image={
              this.state.showLabel ? this.props.activeImage : this.props.image
            }
          >
            {' '}
          </ToggleImage>
          <div
            className={
              this.state.showLabel
                ? css(styles.labelContainerVisible)
                : css(styles.labelContainerHidden)
            }
          >
            <div className={css(styles.triangle)} />
            <span className={css(styles.label)}>{this.props.label}</span>
          </div>
        </div>
      </div>
    );
  }

  static labelStyle = {
    height: 52,
    padding: 10,
    position: 'absolute',
    left: 0,
    display: 'flex',
    pointerEvents: 'none',
  };

  static styles = StyleSheet.create({
    container: {
      height: 50,
    },
    item: {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    icon: {
      width: '50px',
      height: '50px',
    },
    labelContainerHidden: {
      ...SideMenuItem.labelStyle,
      opacity: '0',
    },
    labelContainerVisible: {
      ...SideMenuItem.labelStyle,
      opacity: '1',
    },

    label: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500,
      fontSize: 14,
      color: '#ffffff',
      fontFamily: 'Raleway',
      marginLeft: 50,
      paddingLeft: 6,
      paddingRight: 6,
      backgroundColor: '#9AD4DF',
      whiteSpace: 'nowrap',
      opacity: 1,
      borderTopRightRadius: 2,
      borderBottomRightRadius: 2,
    },

    triangle: {
      position: 'absolute',
      marginLeft: 18,
      marginTop: 0,
      width: 0,
      height: 0,
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderTopWidth: 16,
      borderRightWidth: 16,
      borderBottomWidth: 16,
      borderLeftWidth: 16,
      borderTopColor: 'transparent',
      borderRightColor: '#9AD4DF',
      borderBottomColor: 'transparent',
      borderLeftColor: 'transparent',
    },
  });
}
