import PropTypes from 'prop-types';
import React, {Component} from 'react';
import moment from 'moment';
import {StyleSheet, css} from 'aphrodite';

import Header from './Header';

// fonts
// const A_GARAMOND_PRO = 'AGaramondPro-Regular';
const A_GARAMOND_PRO_BOLD = 'AGaramondPro-Bold';
const HELVETICA_LTSD_LT = 'HelveticaNeueLTStd-Lt';
const HELVETICA_LTSD_MD = 'HelveticaNeueLTStd-Md';
// const DEBUG_FONT = 'monospace';

// colors
// const DEBUG_COLOR = 'red';

const WHITE = '#fff';
// const LIGHT_GREY = '#f2f2f2';
const GREY1 = '#898989';
// const GREY2 = '#646363';
// const GREY3 = '#9b9b9a';
const DARK1 = '#363636';
const DARK2 = '#252525';
const GOLD = '#b8a26c';

// assets
import LOGO_IMAGE from '../../assets/images/carecosmetics/careCosmeticsLogo.png';
import MENU_BUTTON from '../../assets/images/carecosmetics/burgerMenu.png';
import BACK_BUTTON from '../../assets/images/carecosmetics/backArrow.png';
import FAB from '../../assets/images/carecosmetics/fAB.png';
import CTA_BUTTON_PHONE from '../../assets/images/carecosmetics/cTACall.png';
import CTA_BUTTON_EMAIL from '../../assets/images/carecosmetics/cTAEmail.png';

const logo = LOGO_IMAGE;
const fabOpenIcon = FAB;
const fabCloseIcon = FAB;
const emailIcon = CTA_BUTTON_EMAIL;
const phoneIcon = CTA_BUTTON_PHONE;
const whatsappIcon = CTA_BUTTON_PHONE;
const menu = MENU_BUTTON;
const back = BACK_BUTTON;

const dateFormat = 'D/MM/YY';

export default class MessagePreviewCarecosmetics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ctaHeight: 20,
      ctaVisible: false,
    };
  }

  static propTypes = {
    lookup: PropTypes.object,
    message: PropTypes.object,
  };

  _renderCta = (cta) => {
    const {styles} = MessagePreviewCarecosmetics;
    switch (cta.type) {
      case 'web':
        return (
          <div className={css(styles.link)} key={cta.id}>
            {cta.defaultLabel}
          </div>
        );
      case 'chat':
        return (
          <div className={css(styles.chat)} key={cta.id}>
            <div className={css(styles.chatLabel)}>{cta.defaultLabel}</div>
          </div>
        );
      case 'email':
        return (
          <div className={css(styles.secondPageCta)} key={cta.id}>
            <div className={css(styles.secondPageCtaLabel)}>
              {cta.defaultLabel}
            </div>
            <img
              className={css(styles.secondPageCtaIcon)}
              src={emailIcon}
              alt="email cta"
            />
          </div>
        );
      case 'phone':
        return (
          <div className={css(styles.secondPageCta)} key={cta.id}>
            <div className={css(styles.secondPageCtaLabel)}>
              {cta.defaultLabel}
            </div>
            <img
              className={css(styles.secondPageCtaIcon)}
              src={phoneIcon}
              alt="phone cta"
            />
          </div>
        );
      case 'whatsapp':
        return (
          <div className={css(styles.secondPageCta)} key={cta.id}>
            <div className={css(styles.secondPageCtaLabel)}>
              {cta.defaultLabel}
            </div>
            <img
              className={css(styles.secondPageCtaIcon)}
              src={whatsappIcon}
              alt="whatsapp cta"
            />
          </div>
        );
      default:
        return null;
    }
  };

  _getCtas = () => {
    return (this.props.message.ctas || [])
      .filter((id) => this.props.lookup.ctas[id])
      .map((id) => this.props.lookup.ctas[id]);
  };

  _onCtaButtonClick = () => {
    this.setState({ctaVisible: !this.state.ctaVisible});
  };

  render() {
    const {
      message: {description, title, publicationDate, image},
      ctas,
      categories,
    } = this.props;

    const {ctaVisible} = this.state;
    const formatedPublicationDate = moment(publicationDate).format(dateFormat);
    const categoryName = categories.length > 0 ? categories[0].name : '';
    const {styles} = MessagePreviewCarecosmetics;

    const fabCtas = ctas.filter((cta) => ['email', 'phone'].includes(cta.type));
    const inlineCtas = ctas
      .filter((cta) => ['web', 'chat'].includes(cta.type))
      .sort((cta) => (cta.type === 'web' ? 1 : -1)); // display web ctas first

    return (
      <div className={css(styles.preview)}>
        <Header logo={logo} menu={menu} back={back} />

        {!ctaVisible && (
          <div className={css(styles.container)}>
            <div>
              {image && (
                <img className={css(styles.image)} src={image} alt="message" />
              )}
              <div className={css(styles.messageContent)}>
                <div className={css(styles.title)}>{title}</div>
                <div className={css(styles.dateAndCategoryContainer)}>
                  <div className={css(styles.publicationDate)}>
                    {formatedPublicationDate}
                  </div>
                  {categoryName && (
                    <div className={css(styles.categoryContainer)}>
                      <div className={css(styles.category)}>{categoryName}</div>
                    </div>
                  )}
                </div>
                <div className={css(styles.description)}>{description}</div>
                {inlineCtas.map(this._renderCta)}
              </div>
            </div>
          </div>
        )}
        <div className={css(styles.ctas)}>
          {ctaVisible && fabCtas.map((cta) => this._renderCta(cta))}

          {fabCtas.length > 0 && (
            <img
              className={css(styles.fab, !ctaVisible && styles.rotate)}
              alt="cta"
              onClick={this._onCtaButtonClick}
              src={ctaVisible ? fabCloseIcon : fabOpenIcon}
            />
          )}
        </div>
      </div>
    );
  }

  static previewWidth = 246;
  static previewHeight = 426;
  static headerHeight = 34;

  static styles = StyleSheet.create({
    preview: {
      width: MessagePreviewCarecosmetics.previewWidth,
      height: MessagePreviewCarecosmetics.previewHeight,
      marginTop: 70,
      marginLeft: 53,
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: MessagePreviewCarecosmetics.previewWidth,
      height:
        MessagePreviewCarecosmetics.previewHeight -
        MessagePreviewCarecosmetics.headerHeight,
      overflow: 'scroll',
    },
    ctas: {
      position: 'absolute',
      right: 16,
      bottom: 44,
      marginTop: 'auto',
      alignSelf: 'flex-end',

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    fab: {
      width: 36,
      height: 36,
      marginRight: 9,
      marginBottom: 12,
    },
    rotate: {
      transform: 'rotate(45deg)',
    },
    image: {
      width: MessagePreviewCarecosmetics.previewWidth,
    },
    messageContent: {
      marginRight: 12,
      marginLeft: 12,
      marginBottom: 48,
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontFamily: A_GARAMOND_PRO_BOLD,
      fontSize: 17,
      textAlign: 'center',
      color: DARK2,
      marginBottom: 6,
      marginTop: 24,
    },
    description: {
      fontFamily: HELVETICA_LTSD_LT,
      fontSize: 12,
      color: DARK1,
      marginRight: 6,
      marginBottom: 12,
      whiteSpace: 'pre-wrap',
    },
    dateAndCategoryContainer: {
      display: 'flex',
      alignSelf: 'center',
      marginBottom: 24,
    },
    publicationDate: {
      fontFamily: HELVETICA_LTSD_LT,
      fontSize: 10,
      color: GREY1,
      paddingTop: 1,
    },
    categoryContainer: {
      marginLeft: 6,
      backgroundColor: GOLD,
      borderRadius: 2,
    },
    category: {
      fontFamily: HELVETICA_LTSD_MD,
      fontSize: 10,
      color: WHITE,
      paddingLeft: 4,
      paddingRight: 4,
    },
    link: {
      fontFamily: HELVETICA_LTSD_LT,
      fontSize: 12,
      color: DARK1,
      marginBottom: 12,
      textDecoration: 'underline',
    },
    secondPageCta: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: 6,
      marginRight: 6,
    },
    secondPageCtaLabel: {
      color: DARK1,
      fontFamily: HELVETICA_LTSD_LT,
      fontSize: 16,
      marginRight: 12,
    },
    secondPageCtaIcon: {
      width: 24,
      height: 24,
      marginRight: 9,
    },
    chat: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: MessagePreviewCarecosmetics.previewWidth - 2 * 36,
      minHeight: 36,
      padding: 12,
      backgroundColor: GOLD,
      marginBottom: 12,
    },
    chatLabel: {
      color: WHITE,
      fontFamily: HELVETICA_LTSD_MD,
      size: 14,
    },
  });
}
