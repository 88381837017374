import PropTypes from 'prop-types';
import React, {Component} from 'react';

export default class InputWrapper extends Component {
  static propTypes = {
    children: PropTypes.any,
  };

  static styles = {
    inputWrapper: {
      fontSize: '14px',
    },
  };

  render() {
    return (
      <div style={InputWrapper.styles.inputWrapper}>{this.props.children}</div>
    );
  }
}
