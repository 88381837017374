import routes from '../../helpers/routes';
import {saveAs} from 'file-saver';
import converter from 'json-2-csv';
import {V2_BRANDS} from '../../config/brands';
const ACTION_SELECT_SEGMENT = 'SELECT_SEGMENT';
const ACTION_DESELECT_SEGMENT = 'DESELECT_SEGMENT';
const ACTION_LOAD_SEGMENTS = 'LOAD_SEGMENTS';
const ACTION_UPLOAD_CLIENTS_FROM_EXCEL = 'UPLOAD_CLIENTS_FROM_EXCEL';
const ACTION_DELETE_CLIENT_FROM_SEGMENT = 'DELETE_CLIENT_FROM_SEGMENT';
const ACTION_GET_CLIENTS_FOR_SEGMENT = 'GET_CLIENTS_FOR_SEGMENT';
const ACTION_GET_CLIENTS_FOR_SEGMENT_ON_UPLOAD =
  'GET_CLIENTS_FOR_SEGMENT_ON_UPLOAD';
const ACTION_UPLOAD_SEGMENTS_AND_CLIENTS_FROM_EXCEL =
  'ACTION_UPLOAD_SEGMENTS_AND_CLIENTS_FROM_EXCEL';

const ACTION_ADD_CLIENT_TO_SEGMENT = 'ADD_CLIENT_TO_SEGMENT';

export function reducer(
  state = {
    currentSegment: {},
    segments: [],
  },
  action,
) {
  switch (action.type) {
    case ACTION_SELECT_SEGMENT:
      return {
        ...state,
        currentSegment: action.segment,
      };
    case ACTION_DESELECT_SEGMENT:
      return {
        ...state,
        currentSegment: {},
      };
    case ACTION_GET_CLIENTS_FOR_SEGMENT:
      return {
        ...state,
        currentSegment: {
          ...state.currentSegment,
          clients: action.data,
        },
      };
    case ACTION_GET_CLIENTS_FOR_SEGMENT_ON_UPLOAD:
      return {
        ...state,
        currentSegment: {
          ...state.currentSegment,
          clients: action.data.result,
          deletedClients: action.data.deleted,
          totalClients: action.data.result.length,
          newClients: action.data.new,
          passwordDownload: action.data.newClients.map((client) => ({
            id: client.id,
            password: client.password,
          })),
        },
      };
    case ACTION_LOAD_SEGMENTS:
      return {
        ...state,
        segments: action.data,
      };
    case ACTION_DELETE_CLIENT_FROM_SEGMENT:
      return {
        ...state,
        currentSegment: {
          ...state.currentSegment,
          clients: (state.currentSegment.clients || []).filter(
            (client) => client.id !== action.data,
          ),
        },
      };
    case ACTION_ADD_CLIENT_TO_SEGMENT:
      return {
        ...state,
        currentSegment: {
          ...state.currentSegment,
          clients: [...state.currentSegment.clients, action.data],
          passwordDownload:
            action.data.id && action.data.password
              ? [
                  {
                    id: action.data.id,
                    password: action.data.password,
                  },
                ]
              : [],
          queryType: action.data.queryType,
        },
      };
    default:
      return state;
  }
}

export const downloadNewClients = (filename) => (dispatch, getState) => {
  const isV2 = V2_BRANDS.indexOf(getState().brand.id) > -1;
  let currentSegment = getState().segments.currentSegment;
  if (currentSegment && currentSegment.passwordDownload && !isV2) {
    const passwords = currentSegment.passwordDownload;
    if (passwords.length > 0) {
      converter.json2csv(passwords, (err, result) => {
        const blob = new Blob([result], {type: 'text/csv;charset=utf-8'});
        saveAs(blob, `${filename}_passwords.csv`);
      });
    }
  }
};

export const createSegment = (values) => {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .post(`${routes.segments}/create`, values)
          .then((segment) => segment),
    });
  };
};

export const loadSegment = (id) => {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .get(`${routes.segments}/${id}`)
          .then((segment) => {
            dispatch(selectSegment(segment));
            return segment;
          })
          .catch((e) => {
            console.error('Error in loadSegment', e);
          }),
    });
  };
};

export const loadAllSegments = () => {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client.get(`${routes.segments}`).then((segments) => {
          return dispatch({type: ACTION_LOAD_SEGMENTS, data: segments});
        }),
    });
  };
};

export function deleteSegment(segment) {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .delete(`${routes.segments}/${segment.id}`)
          .then(() => dispatch(loadAllSegments())),
    });
  };
}

export function saveSegment(segment, update) {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .put(`${routes.segments}/${segment.id}`, update)
          .then((segment) => selectSegment(segment)),
    });
  };
}

export function getClientsForSegment(segment) {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .get(`${routes.segments}/${segment.id}/clients`)
          .then((clients) =>
            dispatch({type: ACTION_GET_CLIENTS_FOR_SEGMENT, data: clients}),
          ),
    });
  };
}

export function addClientToSegment(segment, client) {
  return (dispatch) => {
    return dispatch({
      promise: (httpClient) =>
        httpClient
          .put(`${routes.segments}/${segment.id}/clients/${client.id}`, client)
          .then((client) => {
            dispatch({type: ACTION_ADD_CLIENT_TO_SEGMENT, data: client});
            dispatch(getClientsForSegment(segment));
          }),
    });
  };
}

export function deleteClientFromSegment(segment, client) {
  return (dispatch) => {
    dispatch({
      type: ACTION_DELETE_CLIENT_FROM_SEGMENT,
      data: client.id,
    });
    return dispatch({
      promise: (httpClient) =>
        httpClient
          .delete(`${routes.segments}/${segment.id}/clients/${client.id}`)
          .then(() => dispatch(getClientsForSegment(segment))),
    });
  };
}

export function uploadClientsFromExcel(segment, file, isReplace) {
  const data = new FormData();
  data.append('file', file, 'upload.xls');
  return (dispatch) => {
    return dispatch({
      type: ACTION_UPLOAD_CLIENTS_FROM_EXCEL,
      promise: (client) =>
        client
          .post(
            `${routes.segments}/${segment.id}/clients?replace=${isReplace}`,
            data,
          )
          .then((response) =>
            dispatch({
              type: ACTION_GET_CLIENTS_FOR_SEGMENT_ON_UPLOAD,
              data: response,
            }),
          ),
    });
  };
}

export function uploadSegmentsAndClientsFromExcel(file, isReplace) {
  const data = new FormData();
  data.append('file', file, 'upload.xls');
  return (dispatch) => {
    return dispatch({
      type: ACTION_UPLOAD_SEGMENTS_AND_CLIENTS_FROM_EXCEL,
      promise: (client) =>
        client
          .post(`${routes.segments}/clients?replace=${isReplace}`, data)
          .then((response) => {
            const newClients = (
              response && response.length
                ? response.map((res) => res.response.newClients)
                : [[]]
            ).flat(1);

            if (newClients && newClients.length) {
              converter.json2csv(
                newClients.map(({id, password}) => ({id, password})),
                (err, result) => {
                  const blob = new Blob([result], {
                    type: 'text/csv;charset=utf-8',
                  });
                  saveAs(blob, `${file.name}_passwords.csv`);
                },
              );
            }
            const totalClients =
              response && response.length
                ? response.reduce(
                    (total, res) =>
                      total + (res.response.total ? res.response.total : 0),
                    0,
                  )
                : 0;
            const deletedClients =
              response && response.length
                ? response.reduce(
                    (total, res) =>
                      total + (res.response.deleted ? res.response.deleted : 0),
                    0,
                  )
                : 0;

            dispatch(loadAllSegments());

            return {
              newClients: newClients.length,
              deletedClients,
              totalClients,
            };
          }),
    });
  };
}

export function selectSegment(segment) {
  return (dispatch) => {
    return dispatch({
      type: ACTION_SELECT_SEGMENT,
      segment,
    });
  };
}

export function deselectSegment() {
  return (dispatch) => {
    return dispatch({
      type: ACTION_DESELECT_SEGMENT,
    });
  };
}

export const exportSegment = (segmentId) => {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .get(`${routes.segments}/${segmentId}/clients?type=xlsx`, {
            responseType: 'arraybuffer',
          })
          .then((result) => {
            const blob = new Blob([result], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            saveAs(blob, `target_group_${segmentId}.xlsx`);
          }),
    });
  };
};
