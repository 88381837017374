import routes from '../../helpers/routes';

const ACTION_BRAND_RECEIVED = 'BRAND_RECEIVED';

export const DEFAULT_WEB_CONSOLE = 'DEFAULT_WEB_CONSOLE';
export const ROOT_BRAND_WEB_CONSOLE = 'ROOT_BRAND_WEB_CONSOLE';
export const COUNTRY_BRAND_WEB_CONSOLE = 'COUNTRY_BRAND_WEB_CONSOLE';
export const SUB_BRAND_WEB_CONSOLE = 'SUB_BRAND_WEB_CONSOLE';
export const FULL_ACCESS_WEB_CONSOLE = 'FULL_ACCESS_WEB_CONSOLE';

export function reducer(
  state = {
    initialized: false,
    features: {
      modules: [],
      hasTagOnMessages: false,
      hasCategoryIcons: false,
      cta: {allowed: []},
      hasPassword: false,
    },
  },
  action
) {
  switch (action.type) {
    case ACTION_BRAND_RECEIVED:
      return {
        ...state,
        ...action.data,
        initialized: true,
      };
    default:
      return state;
  }
}

export function getInstagramAuthUrl() {
  return (dispatch) =>
    dispatch({promise: (client) => client.get(routes.instagram)});
}

export function handleInstagramAuthorization(code) {
  return (dispatch) =>
    dispatch({
      promise: (client) =>
        client.get(`${routes.handleInstagramAuthorization}?code=${code}`),
    });
}

export function getBrand() {
  return (dispatch) => {
    return dispatch({
      promise: (client) => {
        return client
          .get(routes.brands)
          .then((brand) => {
            return dispatch({
              type: ACTION_BRAND_RECEIVED,
              data: {...brand},
            });
          })
          .catch((e) => {
            console.error('config ERROR', e);
          });
      },
    });
  };
}

export function getBrandId() {
  return (dispatch, getState) => {
    if (getState().brand) {
      return getState().brand.id;
    } else {
      return dispatch(getBrand()).then(() => dispatch(getBrandId()));
    }
  };
}
