import React, {Component} from 'react';
import {Modal} from 'antd';
import PropTypes from 'prop-types';
import {css, StyleSheet} from 'aphrodite';
import {defineMessages, injectIntl} from 'react-intl';
import {Cell, Column, Table} from 'fixed-data-table-2';
import _ from 'lodash';
import Toolbar from './Toolbar';
import SortHeaderCell from './SortHeaderCell';
import TextCell from './TextCell';
import terms from '../../helpers/terms';
import deleteIcon from '../../assets/images/ic_delete.svg';
import editIcon from '../../assets/images/ic_edit.svg';
import viewIcon from '../../assets/images/ic_view.png';
import excelExportIcon from '../../assets/images/TEMP_excel_export.png';
import 'fixed-data-table-2/dist/fixed-data-table-base.min.css';

import './style.css';

const {confirm} = Modal;

export const SORT_DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc',
};

export default injectIntl(
  class FixedDataTable extends Component {
    static propTypes = {
      inputData: PropTypes.array.isRequired,
      dispatch: PropTypes.func.isRequired,
      filter: PropTypes.func,
      createItem: PropTypes.func,
      deleteItem: PropTypes.func,
      editItem: PropTypes.func,
      viewItem: PropTypes.func,
      confirmLabel: PropTypes.string,
      noHeader: PropTypes.bool,
      isModules: PropTypes.bool,
      export: PropTypes.func,
    };

    static defaultProps = {
      inputData: [],
    };

    constructor(props) {
      super(props);
      this.state = {
        tableDimensions: {
          height: 0,
          width: 400,
        },
        outputData: [],
        filterString: '',
        sort: {
          direction: null,
          columnKey: null,
          iteratees: null,
        },
      };
    }

    UNSAFE_componentWillMount = () => {
      let outputData = this.props.inputData || [];
      this.setState({
        outputData,
        tableDimensions: this._getTableDimensions(outputData),
      });
    };

    componentDidMount = () => {
      window.addEventListener('resize', this._updateTableDimensions);
    };

    componentWillUnmount = () => {
      window.removeEventListener('resize', this._updateTableDimensions);
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
      if (
        nextProps.inputData &&
        nextProps.inputData.length !== this.props.inputData.length
      ) {
        const outputData = !!this.state.filterString.trim()
          ? this._filter(
              nextProps.inputData,
              this.state.filterString,
              this.props.filter
            )
          : nextProps.inputData;
        this.setState({
          outputData,
          tableDimensions: this._getTableDimensions(outputData),
        });
      }
    };

    _updateTableDimensions = () => {
      this.setState({
        tableDimensions: this._getTableDimensions(this.state.outputData),
      });
    };

    _getTableDimensions = (data) => {
      const screenHeight = window.innerHeight - 200;
      const calculatedHeight = 48 * data.length + 50;
      const height = Math.min(calculatedHeight, screenHeight);
      const width = window.innerWidth - 200;
      return {
        height: height,
        width: width,
      };
    };

    _showConfirm = (item) => {
      const messages = this._defineMessage();

      confirm({
        title: this.props.intl.formatMessage(messages.confirmDeleteModalHeader),
        content: this.props.confirmLabel,
        onOk: this._onYes.bind(this, item),
        okText: this.props.intl.formatMessage(terms.delete),
        cancelText: this.props.intl.formatMessage(terms.cancel),
      });
    };

    _onYes = (item) => {
      const {isModules, dispatch, deleteItem} = this.props;
      if (isModules === false) {
        deleteItem(item);
      } else {
        dispatch(deleteItem(item));
      }
    };

    _filter(data, string, filterFunction) {
      return data.filter(filterFunction(string.toLowerCase()));
    }

    _sort(data, iteratees, direction) {
      const sortedData = _.sortBy(data, iteratees);
      return direction === SORT_DIRECTIONS.ASC
        ? sortedData
        : sortedData.reverse();
    }

    _onFilterInputChange = (event) => {
      const {value} = event.target;

      // if the new filter is more specific than the old filter, use the already filtered data.
      // otherwise, use the full set of input data
      const data =
        value && value.length > this.state.filterString.length
          ? this.state.outputData
          : this.props.inputData;

      const filteredData = value
        ? this._filter(data, value, this.props.filter)
        : data;

      const {iteratees, direction} = this.state.sort;
      const outputData = direction
        ? this._sort(filteredData, iteratees, direction)
        : filteredData;

      this.setState({
        outputData,
        filterString: value,
      });
    };

    _defineMessage() {
      return defineMessages({
        confirmDeleteModalHeader: {
          id: 'ResourceOverview.confirmDeleteModalHeader',
          description:
            'The header on the modal that pops up if a user is about to delete some cta, message, etc..',
          defaultMessage: 'Confirm Delete',
        },
        editColumnHeader: {
          id: 'ResourceOverview.editColumnHeader',
          description:
            'The header on the table columns with the edit button for the a cta, message, etc..',
          defaultMessage: 'Edit',
        },
        viewColumnHeader: {
          id: 'ResourceOverview.viewColumnHeader',
          description:
            'The header on the table columns with the view button for the a cta, message, etc..',
          defaultMessage: 'View',
        },
        defaultSearchPlaceholder: {
          id: 'ResourceOverview.defaultSearchPlaceholder',
          description:
            'The default placeholder in the search bar in the top-right',
          defaultMessage: 'Search',
        },
      });
    }

    _onSortChange(sortingField, columnKey, initialSortDirection) {
      // process String iteratees by making them lowercase so sorting is done case insensitive
      const iteratees = function (item) {
        const iteratee = sortingField(item);
        if (typeof iteratee === 'string') {
          return iteratee.toLowerCase();
        } else {
          return iteratee;
        }
      };

      const {sort} = this.state;
      if (sort.direction && sort.columnKey === columnKey) {
        // if the table is already sorted by this column, simply reverse the sort direction of the current outputData
        const newSortDirection =
          sort.direction === SORT_DIRECTIONS.ASC
            ? SORT_DIRECTIONS.DESC
            : SORT_DIRECTIONS.ASC;

        const outputData = [...this.state.outputData].reverse();

        this.setState({
          sort: {
            direction: newSortDirection,
            columnKey,
            iteratees,
          },
          outputData,
        });
      } else {
        // if the table is not sorted or is sorted by another column, actually sort the data
        const outputData = this._sort(
          this.state.outputData,
          iteratees,
          initialSortDirection
        );

        this.setState({
          sort: {
            direction: initialSortDirection,
            columnKey,
            iteratees,
          },
          outputData,
        });
      }
    }

    _renderChildren = () => {
      return React.Children.map(this.props.children, (child) => {
        if (child) {
          const {
            columnKey,
            headerTitle,
            sortingField,
            displayCell,
            initialSortDirection = SORT_DIRECTIONS.ASC,
          } = child.props;

          const props = {
            cell: ({rowIndex, ...props}) =>
              displayCell(this.state.outputData[rowIndex], props),
          };

          if (sortingField) {
            props.header = (
              <SortHeaderCell
                onSortChange={() =>
                  this._onSortChange(
                    sortingField,
                    columnKey,
                    initialSortDirection
                  )
                }
                sort={this.state.sort}
                initialSortDirection={initialSortDirection}
              >
                {headerTitle}
              </SortHeaderCell>
            );
          } else {
            props.header = <TextCell>{headerTitle}</TextCell>;
          }
          return React.cloneElement(child, props);
        } else {
          return null;
        }
      });
    };

    _rowClassNameGetter = () => {
      return 'data-row';
    };

    render() {
      const {editItem, viewItem, deleteItem, createItem} = this.props;
      const messages = this._defineMessage();
      const {outputData} = this.state;
      const {styles} = FixedDataTable;

      return (
        <div className={css(styles.container)}>
          {!this.props.noHeader && (
            <Toolbar
              createItem={createItem}
              defaultSearchPlaceholder={this.props.intl.formatMessage(
                messages.defaultSearchPlaceholder
              )}
              onFilterInputChange={this._onFilterInputChange}
            />
          )}

          {this.props.export && (
            <div className={css(styles.gridActionsHeader)}>
              <img
                onClick={this.props.export}
                alt="export data"
                className={css(styles.exportIcon)}
                src={excelExportIcon}
              />
            </div>
          )}

          <Table
            rowClassNameGetter={this._rowClassNameGetter}
            rowHeight={48}
            rowsCount={outputData.length}
            width={this.state.tableDimensions.width}
            height={this.state.tableDimensions.height}
            headerHeight={48}
          >
            {this._renderChildren()}

            {!!editItem && (
              <Column
                align={'center'}
                width={80}
                header={
                  <TextCell>
                    {this.props.intl.formatMessage(messages.editColumnHeader)}
                  </TextCell>
                }
                cell={({rowIndex, ...props}) => {
                  if (
                    !outputData[rowIndex].hasOwnProperty('editable') ||
                    outputData[rowIndex].editable
                  ) {
                    return (
                      <Cell {...props}>
                        <img
                          className={css(styles.editIcon)}
                          alt="Edit item"
                          onClick={editItem(outputData[rowIndex])}
                          src={editIcon}
                        />
                      </Cell>
                    );
                  }
                }}
              />
            )}

            {!!viewItem && (
              <Column
                align={'center'}
                width={80}
                header={
                  <TextCell>
                    {this.props.intl.formatMessage(messages.viewColumnHeader)}
                  </TextCell>
                }
                cell={({rowIndex, ...props}) => {
                  if (
                    !outputData[rowIndex].hasOwnProperty('viewable') ||
                    outputData[rowIndex].viewable
                  ) {
                    return (
                      <Cell {...props}>
                        <img
                          className={css(styles.viewIcon)}
                          alt="View item"
                          onClick={viewItem(outputData[rowIndex])}
                          src={viewIcon}
                        />
                      </Cell>
                    );
                  }
                }}
              />
            )}

            {!!deleteItem && (
              <Column
                width={100}
                align={'center'}
                header={
                  <TextCell>
                    {this.props.intl.formatMessage(terms.delete)}
                  </TextCell>
                }
                cell={({rowIndex, ...props}) => {
                  if (
                    !outputData[rowIndex].hasOwnProperty('deletable') ||
                    outputData[rowIndex].deletable
                  ) {
                    return (
                      <Cell {...props}>
                        <img
                          className={css(styles.deleteIcon)}
                          alt="Delete item"
                          onClick={() =>
                            this._showConfirm(outputData[rowIndex])
                          }
                          src={deleteIcon}
                        />
                      </Cell>
                    );
                  }
                }}
              />
            )}
          </Table>
        </div>
      );
    }

    static styles = StyleSheet.create({
      container: {
        display: 'flex',
        flexDirection: 'column',
      },
      deleteIcon: {
        cursor: 'pointer',
        height: 26,
        width: 26,
      },
      editIcon: {
        cursor: 'pointer',
        height: 26,
        width: 26,
      },
      viewIcon: {
        cursor: 'pointer',
        height: 21,
        width: 21,
      },
      exportIcon: {
        height: 34,
        pointerEvents: 'All',
        cursor: 'pointer',
      },
      gridActionsHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    });
  }
);
