import PropTypes from 'prop-types';
import React, {Component} from 'react';
import moment from 'moment';
import {StyleSheet, css} from 'aphrodite';

import callImage from '../../assets/images/ic_cta_call.svg';
import emailImage from '../../assets/images/ic_cta_email.svg';
import arrowImage from '../../assets/images/ic_forward_arrow.png';
import logo from '../../assets/images/ic_matrix_logo.svg';
import dots from '../../assets/images/ic_menu.svg';

export default class MessagePreviewMatrix extends Component {
  static propTypes = {
    lookup: PropTypes.object,
    message: PropTypes.object,
  };

  _getCtaByType(ctaId) {
    const {styles} = MessagePreviewMatrix;
    let ctaObj;
    try {
      ctaObj = this.props.lookup.ctas[ctaId];
    } catch (e) {
      ctaObj = {};
    }
    switch (ctaObj.type) {
      case 'email':
        return (
          <div className={css(styles.emailLink)} key={ctaObj.id}>
            <img
              className={css(styles.actionImage)}
              src={emailImage}
              alt="email cta"
            />
            {ctaObj.defaultLabel}
          </div>
        );
      case 'phone':
        return (
          <div className={css(styles.callLink)} key={ctaObj.id}>
            <img
              className={css(styles.actionImage)}
              src={callImage}
              alt="phone cta"
            />
            {ctaObj.defaultLabel}
          </div>
        );
      case 'web':
        return (
          <div className={css(styles.ReadMorelink)} key={ctaObj.id}>
            {ctaObj.defaultLabel}
            <img
              className={css(styles.linkImage)}
              src={arrowImage}
              alt="arraw with cta"
            />
          </div>
        );
      default:
    }
  }

  render() {
    const {
      message: {description, title, ctas, categories, publicationDate, image},
    } = this.props;
    const {styles} = MessagePreviewMatrix;
    const category = {};
    if (categories && categories.length > 0) {
      try {
        category.categoryName =
          this.props.lookup.categories[categories[0]].name;
        category.categoryIcon =
          this.props.lookup.categories[categories[0]].icon;
      } catch (e) {
        console.log('error ', e);
      }
    }
    return (
      <div>
        <div className={css(styles.preview)}>
          <div className={css(styles.header)}>
            <img className={css(styles.logo)} src={logo} alt="header logo" />
            <img
              className={css(styles.dots)}
              src={dots}
              alt="dots after header logo"
            />
          </div>
          {image && (
            <img className={css(styles.image)} src={image} alt="message" />
          )}
          <div className={css(styles.messageContent)}>
            <div className={css(styles.categoryAndPublication)}>
              {category.categoryName && (
                <div className={css(styles.categoryName)}>
                  <img
                    className={css(styles.categoryIcon)}
                    src={category.categoryIcon}
                    alt="category icon"
                  />
                  <span>{category.categoryName}</span>
                </div>
              )}
              <span className={css(styles.publicationDate)}>
                {moment(publicationDate).format('DD.MM.YY')}
              </span>
            </div>
            <h2 className={css(styles.title)}>{title}</h2>
            <pre className={css(styles.description)}>{description}</pre>
            {ctas && (
              <div className={css(styles.ctasContainer)}>
                {ctas.map((ctaId) => {
                  return this._getCtaByType(ctaId);
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  static styles = StyleSheet.create({
    preview: {
      width: '231px',
      height: '430px',
      overflow: 'scroll',
      margin: '53px',
      marginRight: '190px',
      marginTop: '70px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-around',
      backgroundColor: '#ca131f',
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: '12px',
    },
    logo: {
      width: 155,
    },
    dots: {
      height: 4,
      alignSelf: 'center',
    },
    brandName: {
      letterSpacing: '23px',
    },
    image: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    categoryName: {
      color: '#CA131F',
      display: 'flex',
      fontFamily: 'HelveticaNeue',
      fontSize: '11px',
      lineHeight: '14px',
      letterSpacing: '0.2px',
      flexDirection: 'row',
      alignSelf: 'center',
    },
    title: {
      display: 'flex',
      fontSize: '21.5px',
      fontFamily: 'HelveticaNeue-Bold',
      color: '#333333',
      lineHeight: '30px',
      letterSpacing: '-0.5px',
      marginTop: '8px',
      marginBottom: '8px',
      wordBreak: 'break-word',
    },
    description: {
      fontSize: '12px',
      fontFamily: 'HelveticaNeue',
      color: '#333333',
      lineHeight: '17px',
      marginTop: '10px',
      marginBottom: '8px',
      width: 182,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
    watchlink: {
      order: 1,
      display: 'flex',
      flexDirection: 'row',
      color: '#CA131F',
      fontSize: '11.5px',
      letterSpacing: '0.25px',
      fontFamily: 'HelveticaNeue-Medium',
      lineHeight: '21px',
    },
    ReadMorelink: {
      order: 2,
      display: 'flex',
      flexDirection: 'row',
      color: '#CA131F',
      fontSize: '11.5px',
      letterSpacing: '0.25px',
      fontFamily: 'HelveticaNeue',
      lineHeight: '21px',
      alignItems: 'center',
    },
    emailLink: {
      backgroundColor: 'black',
      padding: '10px 10px',
      marginTop: '13px',
      order: 4,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '10px',
      letterSpacing: '1.4px',
      lineHeight: '16px',
      fontFamily: 'HelveticaNeue',
      color: 'white',
      textTransform: 'uppercase',
    },
    callLink: {
      backgroundColor: 'black',
      padding: '10px 10px',
      marginTop: '13px',
      order: 3,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '10px',
      letterSpacing: '1.4px',
      lineHeight: '16px',
      fontFamily: 'HelveticaNeue',
      color: 'white',
      textTransform: 'uppercase',
    },
    messageContent: {
      padding: '10px 14px 20px 20px',
    },
    categoryAndPublication: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    publicationDate: {
      fontSize: '10px',
      fontFamily: 'HelveticaNeue',
      color: '#A9A9A9',
      lineHeight: '13px',
      letterSpacing: '0.2px',
    },
    categoryIcon: {
      height: '18px',
      width: '18px',
      paddingRight: '6pt',
    },
    actionImage: {
      paddingRight: '13.6px',
      height: '16pt',
      width: '18pt',
    },
    linkImage: {
      paddingLeft: '9px',
      height: '15px',
      width: '10px',
    },
    ctasContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  });
}
