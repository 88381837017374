import React, {Component} from 'react';
import moment from 'moment';
import {StyleSheet, css} from 'aphrodite';
import {injectIntl, defineMessages} from 'react-intl';

// settings
const dateFormat = 'DD.MM.YY';
const previewScreenWidth = 231;
const previewScreenHeight = 412;

// colors
const white = '#fff';
const black = '#000';
const tomato = '#e35425';
const lightGrey = '#d9dbda';

// assets
import LOGO_IMAGE from '../../assets/images/nf/logoSmall.png';
import BACK_ARROW from '../../assets/images/nf/icBackArrowGrey.png';

class MessagePreviewNF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  _defineMessages = () => {
    return defineMessages({
      messageNoImageLabel: {
        id: 'MessagePreview.messageNoImageLabel',
        description:
          'The placeholder for when there is no image added to a message',
        defaultMessage: 'Please upload a picture',
      },
      moreLabel: {
        id: 'MessagePreviewNF.moreLabel',
        description:
          'The label of the more button on the primary Call To Action at the bottom of the screen.',
        defaultMessage: 'MORE',
      },
      closeLabel: {
        id: 'MessagePreviewNF.close',
        description: 'The label of the close button.',
        defaultMessage: 'CLOSE',
      },
    });
  };

  _openCtaMenu = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  };

  render() {
    const {
      message: {description, title, publicationDate, image},
      categories,
      ctas,
    } = this.props;
    const {menuOpen} = this.state;
    const {styles} = MessagePreviewNF;

    const messages = this._defineMessages();

    const categoryName = categories.length > 0 ? categories[0].name : '...';
    const formatedPublicationDate = publicationDate
      ? moment(publicationDate).format(dateFormat)
      : '...';

    const titleStyle = {
      fontFamily: 'DINNextLTPro-Medium',
      fontSize: 16,
      color: black,
      paddingLeft: 16,
      letterSpacing: 0.3,
      flex: 1,
      wordBreak: 'break-word',
      marginTop: 20,
    };

    const dateStyle = {
      fontFamily: 'DINNextLTPro-Regular',
      fontSize: 10,
    };

    return (
      <div className={css(styles.preview)}>
        <div className={css(styles.container)}>
          <div className={css(styles.header)}>
            <img className={css(styles.logo)} src={LOGO_IMAGE} alt="logo" />

            <div className={css(styles.backButton)}>
              <img
                src={BACK_ARROW}
                className={css(styles.backButtonAsset)}
                alt="backButton"
              />
            </div>
          </div>

          <div className={css(styles.imageDiv)}>
            {image ? (
              <img className={css(styles.image)} src={image} alt="message" />
            ) : (
              <div className={css(styles.noImage)}>
                <div className={css(styles.noImageLabel)}>
                  {this.props.intl.formatMessage(messages.messageNoImageLabel)}
                </div>
              </div>
            )}
          </div>
          {menuOpen ? (
            <div className={css(styles.ctaList)}>
              {ctas.map((item, key) => {
                return (
                  <div className={css(styles.ctaItem)} key={key}>
                    <div className={css(styles.ctaLabel)}>
                      {item.defaultLabel}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={css(styles.contentWrapper)}>
              <div style={titleStyle}>{title || '' || ''}</div>
              <div className={css(styles.categoryAndDate)}>
                <div className={css(styles.category)}>
                  {categoryName.toUpperCase()}
                </div>
                <div style={dateStyle}>{formatedPublicationDate}</div>
              </div>

              <div className={css(styles.description)}>{description}</div>
            </div>
          )}
        </div>

        <div className={styles.bottomContainer}>
          {ctas.length > 1 && !menuOpen && (
            <div className={css(styles.ctaBottom)}>
              <div className={css(styles.labelContainer)}>
                <div className={css(styles.label)}>{ctas[0].defaultLabel}</div>
              </div>
              <div
                className={css(styles.moreContainer)}
                onClick={this._openCtaMenu}
              >
                <div className={css(styles.moreText)}>
                  {this.props.intl.formatMessage(messages.moreLabel)}
                </div>
              </div>
            </div>
          )}

          {ctas.length > 1 && menuOpen && (
            <div
              onClick={this._openCtaMenu}
              className={css(styles.closeButton)}
            >
              <div className={css(styles.closeText)}>
                {this.props.intl.formatMessage(messages.closeLabel)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  static styles = StyleSheet.create({
    preview: {
      width: previewScreenWidth,
      height: previewScreenHeight,
      overflow: 'hidden',
      margin: 53,
      marginRight: 190,
      marginTop: 70,
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      overflowY: 'scroll',
      paddingRight: 20,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      position: 'absolute',
      height: 30,
      width: previewScreenWidth,
      backgroundColor: white,
      alignItems: 'center',
    },
    logo: {
      marginLeft: 12,
      height: 20,
    },
    backButton: {
      marginLeft: 16,
    },
    backButtonAsset: {
      height: 8,
    },
    imageDiv: {
      width: previewScreenWidth,
    },
    image: {
      width: previewScreenWidth,
    },
    noImage: {
      display: 'flex',
      width: previewScreenWidth,
      backgroundColor: lightGrey,
      height: 150,
      justifyContent: 'center',
      alignContent: 'center',
    },
    noImageLabel: {
      paddingTop: 70,
      color: '#000',
      fontSize: 11,
    },

    bottomWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 16,
    },
    categoryAndDate: {
      display: 'flex',
      marginTop: 5,
      marginBottom: 5,
      marginLeft: 16,
      flexDirection: 'row',
    },
    category: {
      marginRight: 14,
      fontFamily: 'DINNextLTPro-Medium',
      fontSize: 10,
      letterSpacing: 0.7,
      color: tomato,
    },
    description: {
      fontFamily: 'DINNextLTPro-Regular',
      paddingLeft: 16,
      fontSize: 11,
      lineHeight: 1.5,
      letterSpacing: 0.6,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      display: 'flex',
    },
    bottomContainer: {
      display: 'flex',
      flex: 1,
    },
    closeButton: {
      position: 'relative',
      top: 45,
      left: 75,
      backgroundColor: black,
      height: 90,
      width: 90,
      borderRadius: 45,
    },
    closeText: {
      color: white,
      textAlign: 'center',
      fontSize: 12,
      paddingTop: 10,
    },
    ctaList: {
      marginTop: 20,
    },
    ctaItem: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 25,
      marginRight: 25,
      marginTop: 8,
      borderBottom: '1px solid #e35425',
    },
    ctaLabel: {
      fontSize: 12,
      paddingTop: 4,
      paddingBottom: 4,
    },
    ////////////// CTA primary
    ctaBottom: {
      display: 'flex',
      backgroundColor: black,
      width: previewScreenWidth,
      height: 46,
    },
    labelContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },
    label: {
      fontFamily: 'DINNextLTPro-Bold',
      fontSize: 12,
      letterSpacing: 0.7,
      color: white,
      paddingLeft: 16,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    moreContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 46,
      width: 46,
      backgroundColor: lightGrey,
    },
    moreText: {
      fontFamily: 'DINNextLTPro-Medium',
      color: 'tomato',
      fontSize: 10,
      letterSpacing: 1.1,
    },
    rowWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
  });
}

export default injectIntl(MessagePreviewNF);
