import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {StyleSheet} from 'aphrodite';
import {injectIntl, defineMessages} from 'react-intl';

import {FloatingLabel, Button} from '../../helpers/formComponents';
import Form from '../../components/Form/Form';
import terms from '../../helpers/terms';
import {updateMessageLocally} from '../../redux/modules/message';

class MessageNotifyForm extends Component {
  static propTypes = {
    updateMessage: PropTypes.func,
  };

  _onBlur = (key, value) => {
    this.props.updateMessage(key, value);
  };

  _onValueChange = (key, value) => {
    let message = {...this.props.message};
    message[key] = value;

    this.props.dispatch(updateMessageLocally(message));
  };

  render() {
    const {message, onSubmit} = this.props;
    const {styles} = MessageNotifyForm;

    const messages = defineMessages({
      notificationMessageLabel: {
        id: 'MessageNotifyForm.notificationMessageLabel',
        defaultMessage: 'Notification Message',
        description: 'The label on the notification message input field',
      },
      notificationMessagePlaceholder: {
        id: 'MessageNotifyForm.notificationMessagePlaceholder',
        defaultMessage: 'Enter Notification Message',
        description: 'The placeholder on the notification message input field',
      },
    });

    return (
      <Form style={styles.form}>
        <FloatingLabel
          label={this.props.intl.formatMessage(
            messages.notificationMessageLabel
          )}
          placeholder={this.props.intl.formatMessage(
            messages.notificationMessagePlaceholder
          )}
          type="text"
          value={message.notificationHeadline}
          name="notificationHeadline"
          onValueChange={this._onValueChange}
          updateMessageOnBlur={this._onBlur}
        />
        <Button wrapperStyle={styles.nextButton} onClick={onSubmit}>
          {this.props.intl.formatMessage(terms.next)}
        </Button>
      </Form>
    );
  }

  static styles = StyleSheet.create({
    form: {
      width: '400px',
    },
    nextButton: {
      width: '100%',
    },
  });
}

export default injectIntl(MessageNotifyForm);
