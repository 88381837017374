import React, {Component} from 'react';
import moment from 'moment';
import {StyleSheet, css} from 'aphrodite';
import CTAViewUFS from './components/CTAViewUFS';
import CategoryIconUFS from './components/CategoryIconUFS';
import {injectIntl, defineMessages} from 'react-intl';

// colors
const dateFormat = 'D.MMM.YYYY';

// fonts
const DINPRO_BLACK = 'DINPro-Black';
const DINPRO_MEDIUM = 'DINPro-Medium';

// colors
const white = '#fff';
const grey1 = '#454545';
const grey2 = '#999';
const deepOrange = '#ea5a06';
const blackT30 = 'rgba(0,0,0,0.3)';

// assets
import LOGO_IMAGE from '../../assets/images/ufs/logo.png';
import MENU_BUTTON from '../../assets/images/ufs/icHamburger.png';

class MessagePreviewUFS extends Component {
  _defineMessages = () => {
    return defineMessages({
      messageNoImageLabel: {
        id: 'MessagePreview.messageNoImageLabel',
        description:
          'The placeholder for when there is no image added to a message',
        defaultMessage: 'Please upload a picture',
      },
      messageDateLabel: {
        id: 'MessagePreview.messageDateLabel',
        description:
          'The general term for the current day or year. Used as a label on messages before the publication date, as in: "Date: 21.May.2017"',
        defaultMessage: 'Date',
      },
    });
  };

  render() {
    const {
      message: {description, title, publicationDate, image},
      categories,
      ctas,
    } = this.props;
    const messages = this._defineMessages();

    const cta = ctas[0];
    const {styles} = MessagePreviewUFS;

    const categoryName = categories.length > 0 ? categories[0].name : '...';
    const capitalizedCategoryName = categoryName
      ? categoryName.charAt(0).toUpperCase() + categoryName.slice(1)
      : '';
    const formatedPublicationDate = publicationDate
      ? moment(publicationDate).format(dateFormat)
      : '...';

    return (
      <div className={css(styles.preview)}>
        <div className={css(styles.container)}>
          <div className={css(styles.header)}>
            <img className={css(styles.logo)} src={LOGO_IMAGE} alt="logo" />
            <div className={css(styles.menu)}>
              <img
                src={MENU_BUTTON}
                className={css(styles.menuBurger)}
                alt="menu"
              />
            </div>
          </div>

          <div className={css(styles.imageDiv)}>
            {image ? (
              <img className={css(styles.image)} src={image} alt="message" />
            ) : (
              <div className={css(styles.noImage)}>
                <div className={css(styles.noImageLabel)}>
                  {this.props.intl.formatMessage(messages.messageNoImageLabel)}
                </div>
              </div>
            )}
            <div className={css(styles.category)}>
              {capitalizedCategoryName}
            </div>

            {categories[0] && categories[0].icon && (
              <CategoryIconUFS categories={categories} />
            )}
          </div>

          <div className={css(styles.bottomWrapper)}>
            <div className={css(styles.title)}>{title || ''}</div>
            <div className={css(styles.date)}>
              <span className={css(styles.dateLabel)}>
                {this.props.intl.formatMessage(messages.messageDateLabel)}
                {':'}
              </span>{' '}
              {formatedPublicationDate}
            </div>

            <div className={css(styles.description)}>{description}</div>
          </div>

          {cta && <CTAViewUFS label={cta.defaultLabel} type={cta.type} />}
        </div>
      </div>
    );
  }

  static previewScreenWidth = 231;
  static previewScreenHeight = 426;

  static styles = StyleSheet.create({
    preview: {
      width: MessagePreviewUFS.previewScreenWidth,
      height: MessagePreviewUFS.previewScreenHeight,
      overflow: 'hidden',
      margin: '53px',
      marginRight: '190px',
      marginTop: '70px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: white,
      height: '100%',
      width: '100%',
      overflow: 'scroll',
      paddingRight: '20px',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      position: 'absolute',
      height: '30px',
      width: MessagePreviewUFS.previewScreenWidth,
      backgroundColor: deepOrange,
      alignItems: 'center',
    },
    logo: {
      marginLeft: '20px',
      height: '20px',
    },
    menu: {
      marginRight: '10px',
    },
    menuBurger: {
      height: '8px',
    },
    imageDiv: {
      width: MessagePreviewUFS.previewScreenWidth,
    },
    image: {
      width: MessagePreviewUFS.previewScreenWidth,
    },
    noImage: {
      display: 'flex',
      width: MessagePreviewUFS.previewScreenWidth,
      backgroundColor: '#ccc',
      height: 150,
      justifyContent: 'center',
      alignContent: 'center',
    },
    noImageLabel: {
      paddingTop: 70,
      color: '#000',
      fontSize: 11,
    },
    bottomWrapper: {
      marginLeft: 18,
      marginBottom: 48,
    },
    category: {
      position: 'relative',
      top: -36,
      left: 20,
      fontFamily: DINPRO_BLACK,
      fontSize: 15,
      letterSpacing: 0.6,
      color: white,
      textShadowColor: blackT30,
      textShadowRadius: 1,
      textShadowOffset: {
        width: 1,
        height: 1,
      },
    },
    date: {
      fontFamily: DINPRO_MEDIUM,
      fontSize: 10,
      color: grey1,
      marginBottom: 24,
    },
    dateLabel: {
      color: deepOrange,
    },
    title: {
      fontFamily: DINPRO_MEDIUM,
      fontSize: 22,
      color: grey1,
      letterSpacing: 0.3,
      marginBottom: 6,
      display: 'flex',
      wordBreak: 'break-word',
    },
    description: {
      fontFamily: DINPRO_MEDIUM,
      fontSize: 11,
      color: grey2,
      lineHeight: 1.5,
      letterSpacing: 0.6,
      marginBottom: 16,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      display: 'flex',
    },
  });
}

export default injectIntl(MessagePreviewUFS);
