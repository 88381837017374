import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {StyleSheet, css} from 'aphrodite';

import mainStyles from '../../helpers/styles';

const REQUIRED_SIGN = '*';

export default class TextInput extends Component {
  static propTypes = {
    touched: PropTypes.bool,
    onBlur: PropTypes.func,
    placeholer: PropTypes.string,
    error: PropTypes.object,
    required: PropTypes.bool,
    updateMessage: PropTypes.func,
    label: PropTypes.string,
    half: PropTypes.number,
    name: PropTypes.string,
  };

  onBlur = function (event) {
    if (this.props.updateMessage) {
      this.props.updateMessage(this.props.name, event.target.value);
    }
  };

  render() {
    const {label, error, touched, required, placeholer, ...rest} = this.props;
    const {styles} = TextInput;
    return (
      <div className={css(styles.inputWrapper)}>
        <div className={css(styles.labelAndError)}>
          <label className={css(styles.label)}>
            {label}
            {required && <font color="red">{REQUIRED_SIGN}</font>}
          </label>
          {touched && error && (
            <span className={css(styles.error)}>{error}</span>
          )}
        </div>
        <input
          autoComplete="off"
          style={{width: this.props.half ? 150 : 365}}
          className={css(styles.input)}
          placeholder={placeholer}
          onBlur={this.onBlur.bind(this)}
          {...rest}
        />
      </div>
    );
  }

  static styles = StyleSheet.create({
    inputWrapper: {
      width: '100%',
      boxSizing: 'border-box',
    },
    input: {
      width: '100%',
      height: 34,
      borderRadius: '4px',
      backgroundColor: '#fff',
      borderColor: '#d9d9d9 #ccc #b3b3b3',
      border: '1px solid #ccc',
      boxSizing: 'border-box',
    },
    labelAndError: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    ...mainStyles,
  });
}

/*
 { error ? <p className={css(styles.error)}>{error}</p> : null }
 { success ? <p className={css(styles.success)}>{success}</p> : null }
 */
