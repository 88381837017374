module.exports = {
  '.react-tabs [role=tablist]': {
    'border-bottom': '0px solid #aaa',
    margin: '0 0 30px',
    padding: '0',
  },

  '.react-tabs': {
    margin: '5',
    cursor: 'default',
    'user-select': 'none',
    '-webkit-user-select': 'none' /* Chrome/Safari/Opera */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* IE/Edge */,
  },

  '.react-tabs [role=tab]': {
    display: 'inline-block',
    border: '0px solid #aaa',
    'border-bottom': 'none',
    bottom: '-1px',
    position: 'relative',
    'list-style': 'none',
    padding: '5px 0px',
    margin: '10px',
    'font-size': '18px',
    'font-weight': 'bold',
    cursor: 'pointer',
  },

  '.react-tabs [role=tab][aria-selected=true]': {
    background: '#fff',
    'border-bottom': '2px solid #666666 ',
    color: 'black',
    'border-radius': '5px 5px 0 0',
    '-moz-border-radius': '5px 5px 0 0',
    '-webkit-border-radius': '5px 5px 0 0',
    opacity: '1',
  },

  '.react-tabs [role=tab][aria-disabled=true]': {
    color: 'GrayText',
    cursor: 'default',
    margin: '20',
    opacity: '0.8',
  },

  '.react-tabs [role=tab]:focus': {
    //'box-shadow': '0px 2px 1px 1px #666666',
    'border-bottom': '2px solid #666666 ',
    outline: 'none',
  },

  '.react-tabs [role=tab]:hover': {
    'border-bottom': '2px solid #0E8000 ',
  },

  '.react-tabs [role=tab]:focus:after': {
    //'content': '""',
    //'position': 'absolute',
    //'height': '5px',
    //'left': '-4px',
    //'right': '-4px',
    //'bottom': '-5px',
    //'background': '#fff'
  },
};
