import routes from '../../helpers/routes';

const ACTION_ITEMS_LOADED = 'ITEMS_LOADED';

export function reducer(state = {}, action) {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS_LOADED:
      newState = {
        ...state,
      };
      newState[action.resource] = newState[action.resource] || {};
      action.data.forEach((item) => {
        newState[action.resource][item.id] = item;
      });
      return newState;
    default:
      return state;
  }
}

export const load = (resource) => {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .get(`${routes[resource]}`)
          .then((items) => {
            return dispatch({
              type: ACTION_ITEMS_LOADED,
              resource,
              data: items,
            });
          })
          .catch((error) => console.log(error)),
    });
  };
};

export const getItemForId = (resource, id) => {
  return (dispatch, getState) => {
    var item = getState().lookup[resource][id];
    if (item) {
      return Promise.resolve(item);
    } else {
      dispatch(load(resource)).then(() => {
        return getState().lookup[resource[id]];
      });
    }
  };
};
