import converter from 'json-2-csv';
import {saveAs} from 'file-saver';

/*
 * For converting and downloading password files from an array of objects
 */
export default function convertAndDownload(content, filename) {
  return converter.json2csv(content, (err, result) => {
    if (err) {
      console.error('error in converter json2csv', err);
    }
    const blob = new Blob([result], {type: 'text/csv;charset=utf-8'});
    return saveAs(blob, filename);
  });
}
