import terms from '../../helpers/terms';
import {validateURL} from '../../helpers/validation';

export default (values, props) => {
  const errors = {};
  if (!values.title) {
    errors.title = terms.required;
  }
  if (!values.description) {
    errors.description = terms.required;
  }
  if (!values.categories || values.categories.length === 0) {
    errors.categories = terms.required;
  }
  if (!values.ctas || values.ctas.length === 0) {
    errors.ctas = terms.required;
  }
  if (!values.image && (!values.images || !values.images.length)) {
    errors.image = terms.required;
  }
  if (values.videoUrl && !validateURL(values.videoUrl)) {
    errors.videoUrl = 'Invalid URL';
  }
  if (props && props.hasTagOnMessages && !values.tag) {
    errors.tag = terms.required;
  }
  return errors;
};
