export default {
  conversations: '/api/management/conversations',
  brands: '/api/management/brands',
  segments: '/api/management/segments',
  clients: '/api/management/clients',
  categories: '/api/management/categories',
  messages: '/api/management/messages',
  ctas: '/api/management/ctas',
  users: '/api/management/users',
  profile: '/profile',
  openPerMessage: '/analytics/openPerMessage',
  instagram: '/api/management/integration/instagram',
  handleInstagramAuthorization:
    '/api/management/integration/handleInstagramAuthorization',
};
