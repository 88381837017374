import PropTypes from 'prop-types';
import React, {Component} from 'react';
// import {TouchableOpacity, Text, View, StyleSheet, Image} from 'react-native';
import {StyleSheet, css} from 'aphrodite';

import CTA_PHONE_ICON from '../../../assets/images/ufs/ctaPhoneIcon.png';
import CTA_EMAIL_ICON from '../../../assets/images/ufs/ctaEmailIcon.png';
import CTA_WEB_ICON from '../../../assets/images/ufs/ctaWebIcon.png';

const CTA_ICONS = {
  phone: CTA_PHONE_ICON,
  web: CTA_WEB_ICON,
  email: CTA_EMAIL_ICON,
  whatsapp: CTA_PHONE_ICON,
};

const HEIGHT = 46;
const WIDTH = 231;
const ICON_SIZE = 16;
const ICON_MARGIN = 16;
const FOOTER_CTA_PRIMARY_BACKGROUND = '#ea5a06'; // deepOrange
const FOOTER_CTA_SECONDARY_BACKGROUND = '#f2f2f2'; // greyishWhite

export default class CTAViewUFS extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  };

  render() {
    const {label, type} = this.props;
    const icon = CTA_ICONS[type];
    const {styles} = CTAViewUFS;

    return (
      <div className={css(styles.container)}>
        <div className={css(styles.square)}>
          <div className={css(styles.label)}>{label}</div>
        </div>
        {/* TODO Add triangle like on mobile/client: probably need asset for this as the same method does not work on web  */}
        <div className={css(styles.iconContainer)}>
          <img
            className={css(styles.icon)}
            src={icon}
            alt="Call to action icon"
          />
        </div>
      </div>
    );
  }

  static styles = StyleSheet.create({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 41,
      left: 52,
      width: WIDTH,
      height: HEIGHT,
      backgroundColor: FOOTER_CTA_SECONDARY_BACKGROUND,
      flexDirection: 'row',
    },
    square: {
      width: WIDTH - ICON_SIZE - ICON_MARGIN * 2,
      flexGrow: 1,
      height: HEIGHT,
      backgroundColor: FOOTER_CTA_PRIMARY_BACKGROUND,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingLeft: 18,
    },
    label: {
      fontFamily: 'DINPro-Black',
      fontSize: 14,
      letterSpacing: 0.7,
      color: 'white',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: WIDTH - ICON_SIZE - ICON_MARGIN * 4,
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: HEIGHT,
      width: ICON_SIZE + ICON_MARGIN * 2,
    },
    icon: {
      width: ICON_SIZE,
      height: ICON_SIZE,
      marginRight: ICON_MARGIN,
      marginLeft: ICON_MARGIN,
    },
  });
}
