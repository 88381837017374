import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import FixedDataTable from '../../components/FixedDataTable/FixedDataTable';
import TextCell from '../../components/FixedDataTable/TextCell';
import terms from '../../helpers/terms';
import {Column} from 'fixed-data-table-2';

export default injectIntl(
  class CTAGroups extends Component {
    static propTypes = {
      children: PropTypes.any,
      segments: PropTypes.array,
      dispatch: PropTypes.func,
      onDeleteCtaGroup: PropTypes.func,
      cta: PropTypes.object,
    };

    _filter = (value) => (segment) => {
      return (
        (segment.value && segment.value.toLowerCase().indexOf(value) !== -1) ||
        (segment.label && segment.label.toLowerCase().indexOf(value) !== -1)
      );
    };

    render() {
      const {
        dispatch,
        onDeleteCtaGroup,
        cta: {groups},
        segments,
      } = this.props;
      const showMessage =
        groups && groups.filter((group) => !!group.message).length > 0;

      const messages = defineMessages({
        confirmLabel: {
          id: 'CTAGroups.confirmLabel',
          defaultMessage:
            'Are you sure you want to permanently delete this target group from CTA?',
          description:
            'The text which is displayed if the user is about to remove a target group from a cta (of type group)',
        },
        labelColumnTitle: {
          id: 'CTAGroups.labelColumnTitle',
          defaultMessage: 'CTA Label',
          description:
            'The title of the label column in the target groups table of a cta of type group',
        },
        valueColumnTitle: {
          id: 'CTAGroups.valueColumnTitle',
          defaultMessage: 'CTA Value',
          description:
            'The title of the value column in the target groups table of a cta of type group',
        },
        messageColumnTitle: {
          id: 'CTAGroups.messageColumnTitle',
          defaultMessage: 'CTA Message',
          description:
            'The title of the message column in the target groups table of a cta of type group',
        },
      });

      return (
        <FixedDataTable
          // Filter out the segments that do not exist from the CTA
          inputData={
            groups &&
            groups.filter((group) =>
              segments.find((segment) => segment.id === group.segmentGroupId)
            )
          }
          dispatch={dispatch}
          filter={this._filter}
          deleteItem={onDeleteCtaGroup}
          confirmLabel={this.props.intl.formatMessage(messages.confirmLabel)}
          noHeader={true}
          isModules={false}
        >
          <Column
            headerTitle={this.props.intl.formatMessage(terms.segment)}
            flexGrow={1}
            displayCell={(group, props) => (
              <TextCell {...props}>
                {
                  segments.find(
                    (segment) => segment.id === group.segmentGroupId
                  ).name
                }
              </TextCell>
            )}
            width={180}
          />
          <Column
            headerTitle={this.props.intl.formatMessage(
              messages.labelColumnTitle
            )}
            flexGrow={1}
            displayCell={(group, props) => (
              <TextCell {...props}>{group.label}</TextCell>
            )}
            width={140}
          />
          <Column
            headerTitle={this.props.intl.formatMessage(
              messages.valueColumnTitle
            )}
            flexGrow={1}
            width={140}
            displayCell={(group, props) => (
              <TextCell {...props}>{group.value}</TextCell>
            )}
          />
          {showMessage && (
            <Column
              headerTitle={this.props.intl.formatMessage(
                messages.messageColumnTitle
              )}
              flexGrow={1}
              width={140}
              displayCell={(group, props) => (
                <TextCell {...props}>{group.message}</TextCell>
              )}
            />
          )}
        </FixedDataTable>
      );
    }
  }
);
