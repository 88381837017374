// validating clientId for length/email validation

// TODO get validation rules from brand tables (or some other future feature-switch tables)!!
import {
  LPPD_NL_REDKEN,
  CARECOSMETICS,
  UFS_BE,
  UFS_PL,
  RPB_NL,
  NF,
  LPPD_NL,
  LLUXE_BNL,
  BD,
} from '../config/brands';
export const validateClientId = (brandId, clientId) => {
  switch (brandId) {
    case RPB_NL:
      return {
        status: /^\d{7}$/.test(clientId),
        errorMessage: 'should be 7 characters',
      };
    case UFS_PL:
    case UFS_BE:
    case BD:
    case CARECOSMETICS:
      return {
        status: validateEmail(clientId),
        // TODO [I18N] TRANSLATIONS
        errorMessage: 'should be a valid email address',
      };
    case NF:
      return {
        status: /(^\d{5}$|^CP\d{6}$|^D\d{5}$)/i.test(clientId),
        errorMessage:
          'should be either 5 digits (12345), CP followed by 6 digits (CP123456) or D followed by 5 digits (D12345)',
      };
    case LLUXE_BNL:
      return {
        status: /^\d{2}|^\d{5}$|^\d{6}/.test(clientId),
        errorMessage: 'should be 2, 5 or 6 digits',
      };
    case LPPD_NL:
    case LPPD_NL_REDKEN:
    default:
      return {
        status: /^\d{6}$|^\d{10}/.test(clientId),
        errorMessage: 'should be 6 or 10 digits',
      };
  }
};

export function validateEmail(email) {
  // these escapes are not useless after all.....
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
}

export function validateURL(url) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(url);
}

export const isEmpty = (object) => {
  for (var key in object) {
    if (object.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};
