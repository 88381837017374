// SEGMENT Analytics
import loader from 'analytics.js-loader';
export const analytics = loader({
  writeKey: 'a7dk0FvWTidFgJmBQWr5KNCnIbV91zFE',
  // you can skip the first analytics.page() call if needed, #1
  skipPageCall: true,
});

export const tracker = () => {
  return (next) => (action) => {
    const {event} = action;
    if (event) {
      analytics.track(event.name, event.properties);
    }
    return next(action);
  };
};
