import {notification} from 'antd';

export const showErrors = (errors) => {
  const mappedErrors = Object.keys(errors).map((key) =>
    typeof errors[key] === 'string'
      ? {key, value: errors[key]}
      : {key, value: errors[key].defaultMessage || 'Something went wrong'}
  );
  mappedErrors.map((error) => {
    notification.error({
      message: 'Validation Error',
      description: `${error.key} - ${error.value}`,
    });
    return null;
  });
};
