import Cookies from 'universal-cookie';
import {sharedAuth0Config, makePort} from '../../config/auth0Shared';
import axios from 'axios';
import {analytics} from '../middleware/analyticsMiddleware';

const ACTION_LOGIN = 'LOGIN';
const ACTION_LOGOUT = 'LOGOUT';
const cookie = new Cookies();

/**
 * Not a proper action creator,
 * just a utility to simplify login check.
 */
export const isLoggedIn = () => {
  return !!cookie.get('token');
};

export const getUserEmail = () => {
  return cookie.get('email');
};

if (getUserEmail()) {
  analytics.identify(getUserEmail());
}

const initialState = {loggedIn: isLoggedIn(), email: getUserEmail()};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_LOGIN:
      analytics.identify(getUserEmail());
      return {...state, loggedIn: true};
    case ACTION_LOGOUT:
      return {...state, loggedIn: false};
    default:
      return state;
  }
}

export function makeLogoutUrl() {
  const location = window.location;
  const protocol = location.protocol;
  const port = makePort(location);
  return `${sharedAuth0Config.auth0BaseUrl}v2/logout?returnTo=${protocol}//${location.hostname}:${port}`;
}

export async function makeAuthUrl() {
  const location = window.location;
  const {
    data: {ENV},
  } = await axios.get('/config');
  return (
    `${sharedAuth0Config.auth0BaseUrl}authorize` +
    serialize(
      Object.assign(
        {
          response_type: 'code',
          scope: 'openid email app_metadata user_metadata picture',
        },
        sharedAuth0Config.payload(location, ENV)
      )
    )
  );
}

function serialize(obj) {
  return (
    '?' +
    Object.keys(obj)
      .reduce(function (a, k) {
        a.push(k + '=' + encodeURIComponent(obj[k]));
        return a;
      }, [])
      .join('&')
  );
}

export function exchangeCodeForToken() {
  return (dispatch) => {
    const code = window.location.search.slice(6);
    const port = window.location.port;
    const protocol = window.location.protocol;
    return axios
      .get(`/callback?code=${code}&protocol=${protocol}&port=${port}`)
      .then((res) => {
        return dispatch({type: ACTION_LOGIN});
      })
      .catch((e) => {
        console.error('error in exchangeCodeForToken', e);
        return dispatch({type: ACTION_LOGOUT});
      });
  };
}

export const logout = () => {
  cookie.remove('token', {path: '/'});
  cookie.remove('email', {path: '/'});
  cookie.remove('language', {path: '/'});
  return {
    type: ACTION_LOGOUT,
  };
};
