import moment from 'moment';
import terms from '../../helpers/terms';

export default (values) => {
  const errors = {};
  if (!values.publicationDate) {
    errors.publicationDate = terms.required;
  }
  if (!values.expirationDate) {
    errors.expirationDate = terms.required;
  }
  if (!values.segments || values.segments.length === 0) {
    errors.segments = terms.required;
  }
  if (
    values.publicationDate &&
    values.expirationDate &&
    !moment(values.expirationDate).isAfter(values.publicationDate, 'day')
  ) {
    errors.expirationDate = terms.expirationDatePrecedesPublicationDate;
  }

  return errors;
};
