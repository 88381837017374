import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {TransitionGroup} from 'react-transition-group';
import {StyleSheet, css} from 'aphrodite';
import Overlay from 'react-bootstrap/Overlay';

import Header, {headerHeight} from '../Header/Header';
import SideMenu, {SIDE_MENU_WIDTH} from '../SideMenu/SideMenu';
import './page.css';
import 'react-widgets/styles.css';
import 'antd/dist/antd.css';
import Spinner from '../../components/Spinner/Spinner';
import {renderRoutes} from 'react-router-config';

class Page extends Component {
  static propTypes = {
    location: PropTypes.object,
    children: PropTypes.any,
    loading: PropTypes.bool,
    routes: PropTypes.array,
    features: PropTypes.object,
    title: PropTypes.string,
  };

  render() {
    const {features, history} = this.props;
    const {styles} = Page;

    if (this.props.loggedInAs === 'authorization.instagram@ppdconnect.com') {
      return (
        <div>
          <Header history={history} title={'Immidi integrations'} />
          <SideMenu history={history} features={features} hideItems={true} />
          <div className={css(styles.content)}>
            <h2>{'Instructions for Instagram'}</h2>
            <div className={css(styles.tempWrapper)}>
              <p>
                {
                  "Before we're able to use the Instagram's API we have to take a few steps to be compliant with Instagram'spolicy. In order to show the Instagram posts from the official Instagram account above, we need to beauthorized by the account owners. I will explain briefly on what we have do to make this happen."
                }
              </p>
              <p>
                {
                  "Please click the 'authorize instagram' button (dropdown top right). This will authorize us read thepublic information of that account. Meaning that L'Oréal PPD Connect app can see the posts on thataccount."
                }
              </p>

              <h2>{'Demo in the mobile app'}</h2>
              <p>
                {
                  'The following video will give you an impression of what the instagram integration looks like.'
                }
              </p>
              <video width="600" height="400" controls>
                <source
                  src="https://s3-eu-west-1.amazonaws.com/links.immidi.com/demo-for-instagram-previews-in-immidi-app.mp4"
                  type="video/mp4"
                />
                {'Your browser does not support the video tag.'}
              </video>
            </div>
          </div>

          {renderRoutes(this.props.route.routes)}
        </div>
      );
    }

    return (
      <div>
        <Header history={history} title={this.props.title} />
        <SideMenu history={history} features={features} />
        <div className={css(styles.content)}>
          <TransitionGroup
            component="div"
            transitionName="page"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={1}
          >
            {renderRoutes(this.props.route.routes)}
          </TransitionGroup>
        </div>
        {this.props.loading && (
          <Overlay>
            <Spinner />
          </Overlay>
        )}
      </div>
    );
  }

  static marginRight = '50px';
  static marginBottom = '50px';

  static styles = StyleSheet.create({
    content: {
      paddingLeft: `calc(${SIDE_MENU_WIDTH} + ${Page.marginRight})`,
      paddingRight: Page.marginRight,
      height: `calc(100% - ${headerHeight} - ${Page.marginBottom})`,
      // overflow: 'auto',
      paddingTop: headerHeight,
      paddingBottom: Page.marginBottom,
    },
    tempContainer: {
      paddingTop: '80px',
      // paddingTop: '20px',
    },
    tempWrapper: {
      width: '600px',
    },
  });
}

export default connect((state) => {
  return {
    loading: !state.reduxAsyncConnect.loaded,
    features: state.brand.features || {modules: {}},
    loggedInAs: state.auth.email,
  };
})(Page);
