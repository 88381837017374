import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  LLUXE_BNL,
  ACD_NL_ACD,
  LPPD_NL_LPPD,
  INSIGHTS_ALLOWED_BRANDS,
} from '../../config/brands';

class Insights extends Component {
  componentDidMount() {
    const {brandId} = this.props;
    if (INSIGHTS_ALLOWED_BRANDS.indexOf(brandId) === -1) {
      window.location.href = '/';
    }
  }

  getURL() {
    const {brandId} = this.props;

    switch (brandId) {
      case ACD_NL_ACD:
        return 'https://datastudio.google.com/embed/reporting/a376dea2-258f-40d4-b7cb-07b55123bab4/page/p_15q40c0upc';
      case LLUXE_BNL:
        return 'https://datastudio.google.com/embed/reporting/bad031ad-5df0-4e68-af5f-077ad74282cc/page/u3KCC';
      case LPPD_NL_LPPD:
        return 'https://datastudio.google.com/embed/reporting/e54e5cd4-d878-4caf-830f-0d07a4d7130f/page/0FgxB';
      default:
        return '';
    }
  }

  render() {
    const iframe = `<iframe width="1200" height="900" src="${this.getURL()}" frameborder="0" style="border:0" allowfullscreen></iframe>`;

    return (
      <div>
        <div dangerouslySetInnerHTML={{__html: iframe}} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    brandId: state.brand.id,
  };
}

export default connect(mapStateToProps)(Insights);
