import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {asyncConnect} from 'redux-connect';
import {connect} from 'react-redux';
import {css, StyleSheet} from 'aphrodite';
import {injectIntl, defineMessages} from 'react-intl';

import {
  createCategory,
  selectCategory,
  updateCategory,
  updateCategoryLocally,
  uploadCategoryIcon,
} from '../../redux/modules/categories';
import {
  UpsertButton,
  UploadButton,
  FloatingLabel,
} from '../../helpers/formComponents';
import CategoryValidation from './CategoryValidation';
import terms from '../../helpers/terms';
import Form from '../../components/Form/Form';
import _ from 'lodash';
import {showErrors} from '../../helpers/errors';

class CategoryDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    category: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  _submit = () => {
    const errors = CategoryValidation(this.props.category);
    if (_.isEmpty(errors)) {
      this.setState({loading: true}, () => {
        if (
          this.props.category &&
          this.props.category.id &&
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.id
        ) {
          this.props.dispatch(updateCategory(this.props.category)).then(() => {
            this.setState({loading: false});
          });
        } else {
          this.props
            .dispatch(createCategory(this.props.category))
            .then((category) => {
              this.props.history.push(`/categories/edit/${category.id}`);
            });
        }
      });
    } else {
      showErrors(errors);
    }
  };

  _onDrop = (files) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props
          .dispatch(uploadCategoryIcon(this.props.category, files[0]))
          .then(() => {
            this.setState({
              loading: false,
              iconName: files[0].name,
              iconPreview: files[0].preview,
            });
          });
      }
    );
  };

  _onValueChange = (key, value) => {
    let category = {...this.props.category};
    category[key] = value;

    this.props.dispatch(updateCategoryLocally(category));
  };

  render() {
    const {
      category,
      hasCategoryIcons,
      match: {params},
    } = this.props;
    const {name = '', icon = ''} = category;
    const {iconName, iconPreview, loading} = this.state;
    const {styles} = CategoryDetails;
    const iconImage = iconPreview ? iconPreview : icon;
    const isEdit = params && params.id && category && category.id;

    const messages = defineMessages({
      categoryNamePlaceholder: {
        id: 'CategoryDetails.categoryNamePlaceholder',
        description:
          'The placeholder in the name field when adding a existing category',
        defaultMessage: 'Enter Name',
      },
      uploadCategoryIconButtonLabel: {
        id: 'CategoryDetails.uploadCategoryIconButtonLabel',
        description:
          'The label on the button which uploads an icon for a new category',
        defaultMessage: 'Upload Icon',
      },
      changeCategoryIconButtonLabel: {
        id: 'CategoryDetails.changeCategoryIconButtonLabel',
        description:
          'The label on the button which changes an icon for a category',
        defaultMessage: 'Change Icon',
      },
    });

    return (
      <div>
        <Form style={styles.form}>
          <FloatingLabel
            label={this.props.intl.formatMessage(terms.category)}
            placeholder={this.props.intl.formatMessage(
              messages.categoryNamePlaceholder
            )}
            type="text"
            autoFocus={!category}
            value={name}
            onValueChange={this._onValueChange}
            name="name"
          />
          {isEdit && hasCategoryIcons && (
            <div className={css(styles.previewWrapper)}>
              {iconImage ? (
                <img
                  src={iconImage}
                  alt={name}
                  className={css(styles.preview)}
                />
              ) : (
                <div className={css(styles.noImage)}>
                  {this.props.intl.formatMessage(terms.noImage)}
                </div>
              )}
              <UploadButton
                uploadCss={styles.upload}
                onDrop={this._onDrop}
                multiple={false}
                label={
                  icon
                    ? this.props.intl.formatMessage(
                        messages.changeCategoryIconButtonLabel
                      )
                    : this.props.intl.formatMessage(
                        messages.uploadCategoryIconButtonLabel
                      )
                }
                assetsName={iconName}
              />
            </div>
          )}
          <UpsertButton
            wrapperStyle={styles.button}
            loading={loading}
            type="button"
            onClick={this._submit}
            value={isEdit}
          />
        </Form>
      </div>
    );
  }

  static styles = StyleSheet.create({
    upload: {
      minWidth: 82,
    },
    previewWrapper: {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: 20,
    },
    preview: {
      width: 38,
      height: 38,
      alignSelf: 'center',
    },
    noImage: {
      fontSize: 12,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
    form: {
      marginTop: 50,
      height: 500,
      width: 380,
    },
    button: {
      width: '100%',
    },
  });
}

export default asyncConnect([
  {
    key: 'load',
    promise: (helpers) => {
      if (helpers.match.params.id) {
        return helpers.store.dispatch(selectCategory(helpers.match.params.id));
      } else return helpers.store.dispatch(updateCategoryLocally({}));
    },
  },
])(
  connect((state) => {
    return {
      category: state.categories.current,
      hasCategoryIcons: state.brand.features.hasCategoryIcons,
    };
  })(injectIntl(CategoryDetails))
);
