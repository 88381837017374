import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Modal} from 'antd';
import moment from 'moment';
import {StyleSheet} from 'aphrodite';
import {injectIntl, defineMessages, FormattedMessage} from 'react-intl';
import _ from 'lodash';

import {publish, updateMessageLocally} from '../../redux/modules/message';
import {unPublish} from '../../redux/modules/message';
import {Button, DatePicker, Select} from '../../helpers/formComponents';
import validate from './MessagePublishFormValidation';
import Form from '../../components/Form/Form';
import terms from '../../helpers/terms';
import {showErrors} from '../../helpers/errors';

const initialValues = {
  expirationDate: moment().add(1, 'months').toDate(),
};

const {confirm} = Modal;

class MessagePublishForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    message: PropTypes.object,
    updateMessage: PropTypes.func,
    submitting: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      publishing: false,
    };
    this.messages = this._defineMessages();
  }

  _onValueChange = (key, value) => {
    let message = {...this.props.message};
    message[key] = value;

    this.props.dispatch(updateMessageLocally(message));
  };

  _onChange = (field, value) => {
    this.props.updateMessage(field, value);
  };

  _onConfirmPublish = () => {
    this.setState({
      publishing: true,
    });
    this.props.dispatch(publish(this.props.message)).then(() => {
      this.setState({
        publishing: false,
      });
    });
  };

  _onConfirmUnPublish = () => {
    this.setState({
      publishing: true,
    });
    this.props.dispatch(unPublish(this.props.message)).then(() => {
      this.setState({
        publishing: false,
      });
    });
  };

  _showConfirmPublish = () => {
    const errors = validate(this.props.message);

    if (_.isEmpty(errors)) {
      confirm({
        title: this.props.intl.formatMessage(
          this.messages.confirmPublishModalHeader
        ),
        content: this.props.intl.formatMessage(
          this.messages.confirmPublishModalDescription,
          {
            publicationDate: moment(this.props.message.publicationDate).format(
              'DD.MM.YY'
            ),
            publicationTime: moment(this.props.message.publicationDate).format(
              'HH:mm'
            ),
          }
        ),
        onOk: this._onConfirmPublish,
        okText: this.props.intl.formatMessage(terms.confirm),
        cancelText: this.props.intl.formatMessage(terms.cancel),
      });
    } else {
      showErrors(errors);
    }
  };

  _showConfirmUnPublish = () => {
    confirm({
      title: this.props.intl.formatMessage(
        this.messages.confirmUnpublishModalHeader
      ),
      content: this.props.intl.formatMessage(
        this.messages.confirmUnpublishModalDescription
      ),
      onOk: this._onConfirmUnPublish,
      okText: this.props.intl.formatMessage(terms.confirm),
      cancelText: this.props.intl.formatMessage(terms.cancel),
    });
  };

  _makeButton = (submitting, onClick, label) => {
    const {styles} = MessagePublishForm;
    return (
      <Button
        wrapperStyle={styles.publishButton}
        loading={submitting || this.state.publishing}
        type="button"
        onClick={onClick}
      >
        {label}
      </Button>
    );
  };

  _defineMessages = () => {
    return defineMessages({
      publishButtonLabel: {
        id: 'MessagePublishForm.publishButtonLabel',
        description: 'The label on the publish button',
        defaultMessage: 'Publish',
      },
      unpublishButtonLabel: {
        id: 'MessagePublishForm.unpublishButtonLabel',
        description: 'The label on the unpublish button',
        defaultMessage: 'Unpublish',
      },
      confirmPublishModalHeader: {
        id: 'MessagePublishForm.confirmPublishModalHeader',
        description:
          'The header label on the modal which appears when the user is about to publish a message',
        defaultMessage: 'Confirm Publish',
      },
      confirmPublishModalDescription: {
        id: 'MessagePublishForm.confirmPublishModalDescription',
        description:
          'The text on the modal which appears when the user is about to publish a message. Use curly braces around the two variable names ({publicationTime} and {publicationDate}) like in the English reference text to indicate where the time and date of publication should be displayed in this translation.',
        defaultMessage:
          'Your message will be published on {publicationDate} at {publicationTime}.',
      },
      confirmUnpublishModalHeader: {
        id: 'MessagePublishForm.confirmUnpublishModalHeader',
        description:
          'The header label on the modal which appears when the user is about to unpublish a message',
        defaultMessage: 'Confirm Unpublish',
      },
      confirmUnpublishModalDescription: {
        id: 'MessagePublishForm.confirmUnpublishModalDescription',
        description:
          'The text on the modal which appears when the user is about to publish a message. Use curly braces around the two variable names ({publicationTime} and {publicationDate}) like in the English reference text to indicate where the time and date of publication should be displayed in this translation.',
        defaultMessage: 'Are you sure you want to unpublish this message?',
      },
      publicationDatePickerLabel: {
        id: 'MessagePublishForm.publicationDatePickerLabel',
        description:
          'The label above the input for the publication date and time',
        defaultMessage: 'Publication Date and Time',
      },
      expirationDatePickerLabel: {
        id: 'MessagePublishForm.expirationDatePickerLabel',
        description:
          'The label above the input for the expiration date and time',
        defaultMessage: 'Expiration Date',
      },
      segmentGroupSelectorPlaceholder: {
        id: 'MessagePublishForm.segmentGroupSelectorPlaceholder',
        description: 'The placeholder of the target group input field',
        defaultMessage: 'Select Target Audience',
      },
    });
  };

  render() {
    const {
      message: {
        expirationDate = initialValues.expirationDate,
        publicationDate,
        segments,
      },
      submitting,
    } = this.props;
    const {styles} = MessagePublishForm;

    return (
      <Form style={styles.form}>
        <DatePicker
          date={publicationDate}
          label={this.props.intl.formatMessage(
            this.messages.publicationDatePickerLabel
          )}
          onChange={this._onValueChange.bind(this, 'publicationDate')}
          onBlur={this._onChange.bind(this, 'publicationDate')}
          includeTime={true}
        />

        <DatePicker
          date={expirationDate}
          label={this.props.intl.formatMessage(
            this.messages.expirationDatePickerLabel
          )}
          onChange={this._onValueChange.bind(this, 'expirationDate')}
          onBlur={this._onChange.bind(this, 'expirationDate')}
        />

        <Select
          local={false}
          placeholder={this.props.intl.formatMessage(
            this.messages.segmentGroupSelectorPlaceholder
          )}
          label={this.props.intl.formatMessage(terms.segments)}
          multiple={true}
          value={segments}
          required={true}
          onChange={this._onChange.bind(this, 'segments')}
          resource="segments"
          labelKey="name"
          name="segments"
        />

        {(() => {
          switch (this.props.message.status) {
            case 'message/draft':
              return (
                <div>
                  {this._makeButton(
                    submitting,
                    this._showConfirmPublish,
                    this.props.intl.formatMessage(
                      this.messages.publishButtonLabel
                    )
                  )}
                </div>
              );
            case 'message/published':
              return (
                <div>
                  {this._makeButton(
                    submitting,
                    this._showConfirmPublish,
                    this.props.intl.formatMessage(terms.update)
                  )}
                  {this._makeButton(
                    submitting,
                    this._showConfirmUnPublish,
                    this.props.intl.formatMessage(
                      this.messages.unpublishButtonLabel
                    )
                  )}
                </div>
              );
            case 'message/scheduled':
              return (
                <div>
                  {this._makeButton(
                    submitting,
                    this._showConfirmUnPublish,
                    this.props.intl.formatMessage(
                      this.messages.unpublishButtonLabel
                    )
                  )}
                </div>
              );
            case 'message/processing':
            case 'message/changed':
              return (
                <div>
                  <p>
                    <FormattedMessage
                      id="MessagePublishForm.processingText"
                      description="The text that is displayed while the message is being published or updated"
                      defaultMessage="Processing..."
                    />
                  </p>
                </div>
              );
            case 'message/expired':
              return (
                <div>
                  {this._makeButton(
                    submitting,
                    this._showConfirmPublish,
                    this.props.intl.formatMessage(
                      this.messages.publishButtonLabel
                    )
                  )}
                  {this._makeButton(
                    submitting,
                    this._showConfirmUnPublish,
                    this.props.intl.formatMessage(
                      this.messages.unpublishButtonLabel
                    )
                  )}
                </div>
              );
            default:
              return (
                <div>
                  <p>{'No status found...'}</p>
                </div>
              );
          }
        })()}
      </Form>
    );
  }

  static styles = StyleSheet.create({
    publishButton: {
      width: '100%',
    },
    form: {
      width: '400px',
    },
  });
}

export default injectIntl(MessagePublishForm);
