import routes from '../../helpers/routes';
import {saveAs} from 'file-saver';

const ACTION_SELECT_MESSAGE = 'GET_MESSAGE';
const ACTION_LOAD_MESSAGES = 'LOAD_MESSAGES';

export function reducer(
  state = {
    currentMessage: {
      brandId: '',
    },
  },
  action
) {
  switch (action.type) {
    case ACTION_SELECT_MESSAGE:
      return {
        ...state,
        currentMessage: action.data,
      };
    case ACTION_LOAD_MESSAGES:
      return {
        ...state,
        messages: action.data,
      };
    default:
      return state;
  }
}

export const createMessage = () => {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client.post(`${routes.messages}`).then(selectMessage(dispatch)),
    });
  };
};

export const deleteMessage = (message) => {
  return (dispatch, getState) => {
    return dispatch({
      promise: (client) =>
        client.delete(`${routes.messages}/${message.id}`).then(() => {
          return dispatch({
            type: ACTION_LOAD_MESSAGES,
            data: getState().messages.messages.filter(
              (msg) => msg.id !== message.id
            ),
          });
        }),
    });
  };
};

export const getMessages = () => {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .get(`${routes.messages}`)
          .then((messages) =>
            dispatch({type: ACTION_LOAD_MESSAGES, data: messages})
          ),
    });
  };
};

export const loadMessage = (id) => {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client.get(`${routes.messages}/${id}`).then(selectMessage(dispatch)),
    });
  };
};

export const refreshCurrent = () => {
  return (dispatch, getState) => {
    if (getState().messages.currentMessage.id) {
      return dispatch(loadMessage(getState().messages.currentMessage.id));
    }
  };
};

export const saveMessage = (message) => {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .put(`${routes.messages}/${message.id}`, message)
          .then(selectMessage(dispatch)),
    });
  };
};

export const updateMessageLocally = (message) => {
  return (dispatch) => {
    return dispatch({
      type: ACTION_SELECT_MESSAGE,
      data: message,
    });
  };
};

export const unPublish = (message) => {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .post(`${routes.messages}/${message.id}/unPublish`)
          .then(selectMessage(dispatch)),
    });
  };
};

export const publish = (message) => {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .post(`${routes.messages}/${message.id}/publish`)
          .then(selectMessage(dispatch)),
    });
  };
};

export function uploadMessageImage(message, images) {
  const data = new FormData();
  for (let i = 0; i < images.length; i++) {
    data.append('file', images[i], 'image');
  }

  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .post(`${routes.messages}/${message.id}/upload-image`, data)
          .then(selectMessage(dispatch)),
    });
  };
}

export function selectMessage(dispatch) {
  return (message) => {
    return dispatch({
      type: ACTION_SELECT_MESSAGE,
      data: message,
    });
  };
}

export const exportMessages = () => {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .get(`${routes.messages}/?type=xlsx`, {responseType: 'arraybuffer'})
          .then((result) => {
            const blob = new Blob([result], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            saveAs(blob, `messages_${new Date()}.xlsx`);
          }),
    });
  };
};
