import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {asyncConnect} from 'redux-connect';
import {connect} from 'react-redux';
import {injectIntl, defineMessages} from 'react-intl';

import terms from '../../helpers/terms';
import {getCategories, deleteCategory} from '../../redux/modules/categories';
import FixedDataTable from '../../components/FixedDataTable/FixedDataTable';
import TextCell from '../../components/FixedDataTable/TextCell';
import {Column, Cell} from 'fixed-data-table-2';

class CategoryOverview extends Component {
  static propTypes = {
    categories: PropTypes.array,
    dispatch: PropTypes.func,
  };

  _filter = (value) => (category) =>
    category.name && category.name.toLowerCase().includes(value);

  _onCreateCategory = () => {
    this.props.history.push('/categories/create');
  };

  _editCategory = (category) => () => {
    this.props.history.push({
      pathname: `/categories/edit/${category.id}`,
      search: '',
    });
  };

  _defineMessages() {
    return defineMessages({
      categoryDeleteConfirmLabel: {
        id: 'CategoryOverview.confirmLabel',
        defaultMessage:
          'Are you sure you want to permanently delete this category?',
        description:
          'The text which is displayed if the user is about to delete a category',
      },
      categoryIconColumnTitle: {
        id: 'CategoryOverview.categoryIconColumnTitle',
        defaultMessage: 'Icon',
        description: 'The title of the icon column in the category table',
      },
    });
  }

  render() {
    const {dispatch, categories} = this.props;
    const messages = this._defineMessages();

    return (
      <div>
        <FixedDataTable
          inputData={categories}
          dispatch={dispatch}
          filter={this._filter}
          createItem={this._onCreateCategory}
          deleteItem={deleteCategory}
          editItem={this._editCategory}
          confirmLabel={this.props.intl.formatMessage(
            messages.categoryDeleteConfirmLabel
          )}
        >
          <Column
            headerTitle={this.props.intl.formatMessage(terms.name)}
            flexGrow={1}
            displayCell={(category, props) => (
              <TextCell {...props}>{category.name}</TextCell>
            )}
            width={180}
            sortingField={(category) => category.name}
            columnKey={'name'}
          />

          {this.props.hasCategoryIcons && (
            <Column
              headerTitle={this.props.intl.formatMessage(
                messages.categoryIconColumnTitle
              )}
              align={'center'}
              displayCell={(category, props) => (
                <Cell {...props}>
                  {category.icon ? (
                    <img
                      width="26"
                      height="26"
                      src={category.icon}
                      alt="category icon"
                    />
                  ) : null}
                </Cell>
              )}
              width={80}
            />
          )}
        </FixedDataTable>
      </div>
    );
  }
}

export default asyncConnect([
  {
    key: 'load',
    promise: (helpers) => helpers.store.dispatch(getCategories()),
  },
])(
  connect((state) => {
    return {
      categories: state.categories.categories,
      hasCategoryIcons: state.brand.features.hasCategoryIcons,
    };
  })(injectIntl(CategoryOverview))
);
