import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {asyncConnect} from 'redux-connect';
import {connect} from 'react-redux';
import {defineMessages, injectIntl} from 'react-intl';
import {Column} from 'fixed-data-table-2';
import moment from 'moment';

import {
  deleteSegment,
  loadAllSegments,
  uploadSegmentsAndClientsFromExcel,
} from '../../redux/modules/segments';
import terms from '../../helpers/terms';
import FixedDataTable, {
  SORT_DIRECTIONS,
} from '../../components/FixedDataTable/FixedDataTable';
import TextCell from '../../components/FixedDataTable/TextCell';
import {COUNTRY_BRAND_WEB_CONSOLE} from '../../redux/modules/brand';
import {UploadButton} from '../../helpers/formComponents';
import {css, StyleSheet} from 'aphrodite';
import {Modal} from 'antd';
import Spinner from '../../components/Spinner/Spinner';
import {V2_BRANDS} from '../../config/brands';

class SegmentOverview extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    segments: PropTypes.array,
    CurrentBrandShortName: PropTypes.string,
    subBrands: PropTypes.object,
    webConsoleType: PropTypes.string,
    segment: PropTypes.array,
    clients: PropTypes.array,
    isV2: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  _editSegment = (segment) => () => {
    this.props.history.push({
      pathname: `/segments/edit/${segment.id}`,
      search: '',
    });
  };

  _viewSegment = (segment) => () => {
    this.props.history.push(`/segments/view/${segment.id}`);
  };

  _onCreateSegment = () => {
    this.props.history.push('segments/create');
  };

  _filter = (value) => (segment) => {
    return segment.name && segment.name.toLowerCase().indexOf(value) !== -1;
  };

  _addBrandName = () => {
    const {
      segments,
      subBrands,
      webConsoleType,
      brandId,
      CurrentBrandShortName,
    } = this.props;
    if (webConsoleType === COUNTRY_BRAND_WEB_CONSOLE) {
      return segments.map((segment) => {
        const hasBelongsBrand = Object.keys(subBrands).find(
          (subBrand) => subBrand === segment.belongsToBrand,
        );
        if (hasBelongsBrand === brandId) {
          segment.brandName = CurrentBrandShortName;
        } else if (hasBelongsBrand) {
          segment.brandName = subBrands[hasBelongsBrand].shortName;
        }
        return segment;
      });
    }
    return segments;
  };

  _uploadFeedback = ({newClients, deletedClients, totalClients}) => {
    let msg = '';

    const messages = defineMessages({
      clientsAddedFeedback: {
        id: 'SegmentOverview.clientsAddedFeedback',
        description:
          'A feedback message which is displayed after uploading a client excel sheet. It tells the user how many new clients have been uploaded.',
        defaultMessage: 'new client(s) added',
      },
      clientsDeletedFeedback: {
        id: 'SegmentOverview.clientsDeletedFeedback',
        description:
          'A feedback message which is displayed after uploading a client excel sheet. It tells the user how many new clients have been deleted.',
        defaultMessage: 'client(s) deleted',
      },
      clientsTotalFeedback: {
        id: 'SegmentOverview.clientsTotalFeedback',
        description:
          'A feedback message which is displayed after uploading a client excel sheet. It tells the user how what the total number of clients is after the upload.',
        defaultMessage: 'Total client(s)',
      },
      clientsUploadConfirmation: {
        id: 'SegmentOverview.clientsUploadConfirmation',
        description:
          'A feedback message which is displayed after uploading a client excel sheet. It confirms for the user that the upload has been successful.',
        defaultMessage:
          'The client list has been updated for this target group',
      },
    });

    if (newClients > 0) {
      msg += `${newClients} ${this.props.intl.formatMessage(
        messages.clientsAddedFeedback,
      )}, `;
    }
    if (deletedClients > 0) {
      msg += `${deletedClients} ${this.props.intl.formatMessage(
        messages.clientsDeletedFeedback,
      )}, `;
    }

    msg += `${this.props.intl.formatMessage(
      messages.clientsTotalFeedback,
    )}: ${totalClients}`;

    Modal.info({
      title: this.props.intl.formatMessage(messages.clientsUploadConfirmation),
      content: msg,
      onOk: this._onOK,
      okText: this.props.intl.formatMessage(terms.ok),
    });
  };

  _onOK = () => {};

  _onDropFile = (files) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props
          .dispatch(uploadSegmentsAndClientsFromExcel(files[0], false))
          .then(this._uploadFeedback)
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      },
    );
  };

  render() {
    const {dispatch, webConsoleType, isV2} = this.props;
    const {loading} = this.state;
    const segments = this._addBrandName();
    const {styles} = SegmentOverview;

    const messages = defineMessages({
      segmentRegisteredClientsColumn: {
        id: 'SegmentOverview.segmentRegisteredClientsColumn',
        defaultMessage: 'Registered/All Clients',
        description:
          "The title of the 'Registered over All Clients'-ratio column in the target groups table",
      },
      segmentSubBrandColumnTitle: {
        id: 'SegmentOverview.segmentSubBrandColumnTitle',
        defaultMessage: 'SubBrand',
        description:
          'The title of the subBrand column in the target groups table',
      },
      segmentTypeColumnTitle: {
        id: 'SegmentOverview.segmentTypeColumnTitle',
        defaultMessage: 'Type',
        description: 'The title of the type column in the target groups table',
      },
      segmentCreatedColumnTitle: {
        id: 'SegmentOverview.segmentCreatedColumnTitle',
        defaultMessage: 'Created',
        description:
          'The title of the creation date column in the target groups table',
      },
      segmentConfirmDeleteLabel: {
        id: 'SegmentOverview.confirmDeleteLabel',
        defaultMessage:
          'Are you sure you want to permanently delete this segment?',
        description:
          'The confirmation message that shows up after you click the delete button.',
      },
      uploadMultipleSegment: {
        id: 'SegmentOverview.uploadMultipleSegment',
        defaultMessage: 'Bulk Upload Target Group',
        description: 'Uplaod multiple segments together',
      },
    });

    return (
      <>
        {!isV2 && (
          <div className={css(styles.uploadButton)}>
            {loading ? (
              <Spinner />
            ) : (
              <UploadButton
                onDrop={this._onDropFile}
                multiple={false}
                label={this.props.intl.formatMessage(
                  messages.uploadMultipleSegment,
                )}
                assetsName={null}
              />
            )}
          </div>
        )}
        <FixedDataTable
          inputData={segments}
          dispatch={dispatch}
          webConsoleType={webConsoleType}
          filter={this._filter}
          createItem={this._onCreateSegment}
          deleteItem={deleteSegment}
          editItem={this._editSegment}
          viewItem={this._viewSegment}
          confirmLabel={this.props.intl.formatMessage(
            messages.segmentConfirmDeleteLabel,
          )}>
          <Column
            headerTitle={this.props.intl.formatMessage(terms.name)}
            flexGrow={1}
            displayCell={(segment, props) => (
              <TextCell {...props}>{segment.name}</TextCell>
            )}
            width={180}
            sortingField={(segment) => segment.name}
            columnKey={'name'}
          />
          <Column
            headerTitle={this.props.intl.formatMessage(
              messages.segmentRegisteredClientsColumn,
            )}
            flexGrow={1}
            displayCell={(segment, props) => (
              <TextCell {...props}>
                {segment.analytics
                  ? `${segment.analytics.registeredClients || ''}/${
                      segment.analytics.totalClients || ''
                    }`
                  : '/'}
              </TextCell>
            )}
            width={180}
            // sortingField={(segment) => (]segment.analytics.registeredClients / segment.analytics.totalClients)}
            // columnKey={'analytics'} TODO: how to sort?
          />
          {webConsoleType === COUNTRY_BRAND_WEB_CONSOLE && (
            <Column
              headerTitle={this.props.intl.formatMessage(
                messages.segmentSubBrandColumnTitle,
              )}
              displayCell={(segment, props) => (
                <TextCell {...props}>{segment.brandName}</TextCell>
              )}
              width={100}
              sortingField={(segment) => segment.brandName}
              columnKey={'SubBrand'}
            />
          )}
          <Column
            headerTitle={this.props.intl.formatMessage(
              messages.segmentTypeColumnTitle,
            )}
            displayCell={(segment, props) => (
              <TextCell {...props}>{segment.type}</TextCell>
            )}
            width={100}
            sortingField={(segment) => segment.type}
            columnKey={'type'}
          />
          <Column
            headerTitle={this.props.intl.formatMessage(
              messages.segmentCreatedColumnTitle,
            )}
            displayCell={(segment, props) => (
              <TextCell {...props}>
                {moment(segment.createdAt).format('DD.MM.YY')}
              </TextCell>
            )}
            width={120}
            sortingField={(segment) => segment.createdAt}
            columnKey={'defaultLabel'}
            initialSortDirection={SORT_DIRECTIONS.DESC}
          />
        </FixedDataTable>
      </>
    );
  }

  static styles = StyleSheet.create({
    uploadButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '4%',
    },
  });
}

export default asyncConnect([
  {
    key: 'load',
    promise: (helpers) => helpers.store.dispatch(loadAllSegments()),
  },
])(
  connect((state) => {
    return {
      CurrentBrandShortName: state.brand.shortName,
      subBrands: state.brand.subBrands,
      webConsoleType: state.brand.webConsoleType,
      segments: state.segments.segments,
      segment: state.segments.currentSegment,
      clients: state.segments.clients,
      isV2: V2_BRANDS.indexOf(state.brand.id) > -1,
    };
  })(injectIntl(SegmentOverview)),
);
