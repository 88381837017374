import terms from '../../helpers/terms';
import {validateEmail} from '../../helpers/validation';

export default (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = terms.required;
  } else if (!validateEmail(values.email)) {
    // TODO [I18N] translate
    errors.email = 'should be a valid email address';
  }
  if (!values.name) {
    errors.name = terms.required;
  }

  // TODO should profile picture be a required field?
  if (!values.role) {
    errors.role = terms.required;
  }

  if (!values.segment && values.isAdmin) {
    errors.segment = terms.required;
  }

  return errors;
};
