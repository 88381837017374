import React, {Component} from 'react';
import moment from 'moment';
import {StyleSheet, css} from 'aphrodite';
import {injectIntl, defineMessages} from 'react-intl';

// settings
const dateFormat = 'DD.MM.YY';
const previewScreenWidth = 231;
const previewScreenHeight = 412;

// colors
const white = '#fff';
const black = '#000';
const lightGrey = '#d9dbda';
const ICON_SIZE = 44;
// assets
import BACK_ARROW from '../../assets/images/backGreyArrow.png';
import CTA_PHONE_ICON from '../../assets/images/lppd/phoneGrey.png';
import CTA_EMAIL_ICON from '../../assets/images/lppd/icEmailGrey.png';
import CTA_WEB_ICON from '../../assets/images/lppd/linkGrey.png';
import CTA_CHAT_ICON from '../../assets/images/lppd/chatGrey.png';
import CTA_WHATSAPP_ICON from '../../assets/images/lppd/whatsappGrey.png';
import CTA_CALENDAR_ICON from '../../assets/images/lppd/calendarGrey.png';
import CtaHeader from './rpb/CtaHeader';
import CtaRow from './rpb/CtaRow';

const CTA_ICONS = {
  phone: CTA_PHONE_ICON,
  web: CTA_WEB_ICON,
  email: CTA_EMAIL_ICON,
  chat: CTA_CHAT_ICON,
  whatsapp: CTA_WHATSAPP_ICON,
  calendar: CTA_CALENDAR_ICON,
};

class MessagePreviewLPPD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  _defineMessages = () => {
    return defineMessages({
      messageNoImageLabel: {
        id: 'MessagePreview.messageNoImageLabel',
        description:
          'The placeholder for when there is no image added to a message',
        defaultMessage: 'Please upload a picture',
      },
      moreLabel: {
        id: 'MessagePreviewNF.moreLabel',
        description:
          'The label of the more button on the primary Call To Action at the bottom of the screen.',
        defaultMessage: 'MORE',
      },
      closeLabel: {
        id: 'MessagePreviewNF.close',
        description: 'The label of the close button.',
        defaultMessage: 'CLOSE',
      },
    });
  };

  _openCtaMenu = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  };

  render() {
    const {
      message: {description, title, publicationDate, image},
      categories,
      ctas,
    } = this.props;
    const {menuOpen} = this.state;
    const {styles} = MessagePreviewLPPD;

    const messages = this._defineMessages();

    const categoryName = categories.length > 0 ? categories[0].name : '...';
    const formatedPublicationDate = publicationDate
      ? moment(publicationDate).format(dateFormat)
      : '...';

    const ctasFormated = ctas.map((cta) => {
      return {
        label: cta.defaultLabel,
        icon: CTA_ICONS[cta.type],
      };
    });

    // If removing cta and one is left the menuOpen is left as open
    // since there is only one cta it should be closed
    if (ctasFormated.length < 2 && this.state.menuOpen) {
      this.setState({menuOpen: false});
    }
    const hasMenuButton = !menuOpen && ctasFormated.length > 1;

    const titleStyle = {
      fontFamily: 'ProximaNova',
      fontSize: 16,
      color: black,
      letterSpacing: 0.3,
      flex: 1,
      wordBreak: 'break-word',
      marginTop: 20,
    };

    return (
      <div className={css(styles.preview)}>
        <div className={css(styles.container)}>
          <div className={css(styles.header)}>
            <div className={css(styles.backButton)}>
              <img
                src={BACK_ARROW}
                className={css(styles.backButtonAsset)}
                alt="backButton"
              />
            </div>
          </div>

          <div className={css(styles.imageDiv)}>
            {image ? (
              <img className={css(styles.image)} src={image} alt="message" />
            ) : (
              <div className={css(styles.noImage)}>
                <div className={css(styles.noImageLabel)}>
                  {this.props.intl.formatMessage(messages.messageNoImageLabel)}
                </div>
              </div>
            )}
            {this.props.brandIcon ? (
              <div className={css(styles.brandLogo)}>
                <img
                  className={css(styles.brandLogoImg)}
                  alt="brandLogo"
                  src={this.props.brandIcon}
                />
              </div>
            ) : null}
          </div>

          <div className={css(styles.contentWrapper)}>
            <div style={titleStyle}>{title || '' || ''}</div>
            <div className={css(styles.categoryAndDate)}>
              <div className={css(styles.category)}>
                {categoryName.toUpperCase() + ' - ' + formatedPublicationDate}
              </div>
            </div>

            <div className={css(styles.description)}>{description}</div>
          </div>
        </div>

        <div className={styles.ctaContainer}>
          {menuOpen && ctasFormated.length > 1 && (
            <CtaHeader
              onClick={this._openCtaMenu}
              label={'GET IN TOUCH'}
              color={'black'}
              labelBackgroundColor={'black'}
            />
          )}

          {menuOpen &&
            ctasFormated.length > 1 &&
            ctasFormated.slice(1).map((cta) => {
              //Secondary CTA's
              return (
                <CtaRow
                  label={cta.label}
                  icon={cta.icon}
                  fontColor={'#fff'}
                  secondaryBackgroundColor={'#fff'}
                  secondaryFontColor={'#000'}
                  hasMenuButton={false}
                  openMenu={this._openCtaMenu}
                  menuOpen={menuOpen}
                  hasBorder={true}
                />
              );
            })}

          {ctasFormated.length > 0 && (
            //Primary CTA's
            <CtaRow
              label={ctasFormated[0].label}
              icon={ctasFormated[0].icon}
              backgroundColor={'#000'}
              fontColor={'#fff'}
              // When color change exists on cta's
              primaryBackgroundColor={black}
              secondaryBackgroundColor={'#fff'}
              secondaryFontColor={'#000'}
              hasMenuButton={hasMenuButton}
              openMenu={this._openCtaMenu}
              menuOpen={menuOpen}
            />
          )}
        </div>
      </div>
    );
  }

  static styles = StyleSheet.create({
    preview: {
      width: previewScreenWidth,
      height: previewScreenHeight,
      overflow: 'hidden',
      margin: 53,
      marginRight: 190,
      marginTop: 70,
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      overflowY: 'scroll',
      paddingRight: 20,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      position: 'absolute',
      height: 30,
      width: previewScreenWidth,
      alignItems: 'center',
    },
    logo: {
      marginLeft: 12,
      height: 20,
    },
    brandLogo: {
      position: 'absolute',
      bottom: -(ICON_SIZE / 2) + 4,
      left: 12,
      height: ICON_SIZE,
      width: ICON_SIZE,
    },
    brandLogoImg: {
      height: ICON_SIZE,
      width: ICON_SIZE,
      borderRadius: ICON_SIZE / 2,
      borderWidth: 1,
      borderColor: white,
    },
    backButton: {
      marginLeft: 16,
    },
    backButtonAsset: {
      height: 8,
    },
    imageDiv: {
      width: previewScreenWidth,
      position: 'relative',
    },
    image: {
      width: previewScreenWidth,
    },
    noImage: {
      display: 'flex',
      width: previewScreenWidth,
      backgroundColor: lightGrey,
      height: 150,
      justifyContent: 'center',
      alignContent: 'center',
    },
    noImageLabel: {
      paddingTop: 70,
      color: '#000',
      fontSize: 11,
    },
    bottomWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 16,
    },
    contentWrapper: {
      marginTop: 14,
      marginLeft: 14,
    },
    categoryAndDate: {
      flexDirection: 'row',
      marginTop: 3,
      marginBottom: 12,
    },
    category: {
      fontFamily: 'ProximaNova',
      fontSize: 6,
      fontWeight: '600',
      letterSpacing: 1.4,
      color: black,
    },
    description: {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      display: 'flex',
      fontFamily: 'ProximaNova',
      fontSize: 11,
      letterSpacing: 0.34,
      lineHeight: 1.6,
      color: '#6b6b6b',
      paddingBottom: 14,
    },
    bottomContainer: {
      display: 'flex',
      flex: 1,
    },
    closeButton: {
      position: 'relative',
      top: 45,
      left: 75,
      backgroundColor: black,
      height: 90,
      width: 90,
      borderRadius: 45,
    },
    closeText: {
      color: white,
      textAlign: 'center',
      fontSize: 12,
      paddingTop: 10,
    },
    ctaList: {
      marginTop: 20,
    },
    ctaItem: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 25,
      marginRight: 25,
      marginTop: 8,
      borderBottom: '1px solid #e35425',
    },
    ctaLabel: {
      fontSize: 12,
      paddingTop: 4,
      paddingBottom: 4,
    },
    ////////////// CTA primary
    ctaBottom: {
      display: 'flex',
      backgroundColor: black,
      width: previewScreenWidth,
      height: 46,
    },
    labelContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },
    label: {
      fontFamily: 'DINNextLTPro-Bold',
      fontSize: 12,
      letterSpacing: 0.7,
      color: white,
      paddingLeft: 16,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    moreContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 46,
      width: 46,
      backgroundColor: lightGrey,
    },
    moreText: {
      fontFamily: 'DINNextLTPro-Medium',
      color: 'tomato',
      fontSize: 10,
      letterSpacing: 1.1,
    },
    rowWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
  });
}

export default injectIntl(MessagePreviewLPPD);
