/* eslint-disable react/jsx-no-literals */

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import moment from 'moment';
import {StyleSheet, css} from 'aphrodite';
import ReactStateAnimation from 'react-state-animation';

import logo from '../../assets/images/ic-shu-uemura-logo.png';
import menu from '../../assets/images/ic_menu_shu_preview.png';
import back from '../../assets/images/ic_back_arrow_shu_preview.png';
import ctaOpen from '../../assets/images/ic_cta_article_preview.png';
import ctaClose from '../../assets/images/ic_cta_back_arrow_preview.png';

export default class MessagePreviewShuuemura extends Component {
  static propTypes = {
    lookup: PropTypes.object,
    message: PropTypes.object,
    categories: PropTypes.array.isRequired,
    ctas: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ctaVisible: false,
    };
    // react state animation wrapper
    this._animate = new ReactStateAnimation(this);
  }

  _onCtaButtonClick = () => {
    this.setState({ctaVisible: !this.state.ctaVisible});
  };

  _renderWeblink(webCta) {
    const {styles} = MessagePreviewShuuemura;
    return (
      <u className={css(styles.weblink)} key={webCta.id}>
        <span className={css(styles.webText)}>{webCta.defaultLabel}</span>
      </u>
    );
  }

  _renderCta(cta) {
    const {styles} = MessagePreviewShuuemura;
    switch (cta.type) {
      case 'email':
        return (
          <div key={cta.id}>
            <div>
              <u className={css(styles.underlineText)}>
                <span className={css(styles.label)}>email</span>
              </u>
            </div>
            <span className={css(styles.labelText)}>{cta.defaultLabel}</span>
          </div>
        );
      case 'phone':
        return (
          <div key={cta.id}>
            <div>
              <u className={css(styles.underlineText)}>
                <span className={css(styles.label)}>call</span>
              </u>
            </div>
            <span className={css(styles.labelText)}>{cta.defaultLabel}</span>
          </div>
        );
      case 'chat':
        return (
          <div key={cta.id}>
            <u className={css(styles.underlineText)}>
              <span className={css(styles.label)}>chat</span>
            </u>
            <span className={css(styles.labelText)}>{cta.defaultLabel}</span>
          </div>
        );
      case 'whatsapp':
        return (
          <div key={cta.id}>
            <div>
              <u className={css(styles.underlineText)}>
                <span className={css(styles.label)}>whatsapp</span>
              </u>
            </div>
            <span className={css(styles.labelText)}>{cta.defaultLabel}</span>
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const {
      message: {description, title, publicationDate, image},
      ctas,
      categories,
    } = this.props;

    const {ctaVisible} = this.state;
    const categoryName = categories.length > 0 ? categories[0].name : '';
    const {styles} = MessagePreviewShuuemura;

    return (
      <div
        className={css(
          styles.preview,
          ctaVisible ? styles.backgroundColorBlack : styles.backgroundColorWhite
        )}
      >
        <div className={css(styles.header)}>
          <img className={css(styles.headerBack)} src={back} alt="backLogo" />
          <img
            className={css(styles.headerImage)}
            src={logo}
            alt="headerLogo"
          />
          <img className={css(styles.headerMenu)} src={menu} alt="menuLogo" />
        </div>

        {!ctaVisible && (
          <div className={css(styles.messageContainer)}>
            <div className={css(styles.imageContainer)}>
              {image && !ctaVisible && (
                <img className={css(styles.image)} src={image} alt="message" />
              )}
            </div>
            <div className={css(styles.messageContent)}>
              <div className={css(styles.categoryAndPublication)}>
                <div className={css(styles.categoryName)}>
                  <span>{categoryName.toUpperCase()}</span>
                </div>

                <span className={css(styles.publicationDate)}>
                  {moment(publicationDate).format('DD.MM.YY')}
                </span>
              </div>
              <hr className={css(styles.line)} />
              <h2 className={css(styles.title)}>{title}</h2>
              <pre className={css(styles.description)}>
                {description}
                {ctaVisible}
              </pre>
              <div className={css(styles.weblinks)}>
                {ctas
                  .filter((cta) => cta.type === 'web')
                  .map((webCta) => this._renderWeblink(webCta))}
              </div>
            </div>
          </div>
        )}

        <div className={css(styles.ctas)}>
          {ctaVisible &&
            ctas
              .filter((cta) => cta.type !== 'web')
              .map((cta) => this._renderCta(cta))}

          {ctas.some((cta) => cta.type !== 'web') && (
            <img
              className={css(styles.ctaOpenButton)}
              onClick={this._onCtaButtonClick}
              src={ctaVisible ? ctaClose : ctaOpen}
              alt="ctaOpenButtonLogo"
            />
          )}
        </div>
      </div>
    );
  }

  static HELVETICA_NEUE_LTSTD_LTEX = 'HelveticaNeueLTStd-LtEx';

  static previewWidth = 246;
  static previewHeight = 426;

  static headerHeight = 36;

  static styles = StyleSheet.create({
    backgroundColorBlack: {
      backgroundColor: 'black',
    },
    backgroundColorWhite: {
      backgroundColor: 'white',
    },
    preview: {
      width: MessagePreviewShuuemura.previewWidth,
      height: MessagePreviewShuuemura.previewHeight,
      overflow: 'scroll',
      margin: 53,
      marginRight: 190,
      marginTop: 70,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    headerImage: {
      width: '115px',
      height: '20px',
    },
    headerMenu: {
      width: 16,
      height: 11,
    },
    headerBack: {
      width: 14,
      height: 20,
    },
    messageContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      height:
        MessagePreviewShuuemura.previewHeight -
        MessagePreviewShuuemura.headerHeight,
    },
    overlay: {
      position: 'absolute',
      width: MessagePreviewShuuemura.previewWidth,
      height:
        MessagePreviewShuuemura.previewHeight -
        MessagePreviewShuuemura.headerHeight,
      opacity: 0.5,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'black',
      height: MessagePreviewShuuemura.headerHeight,
      paddingLeft: 8,
      paddingRight: 8,
    },
    imageContainer: {
      marginBottom: 12,
    },
    image: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    categoryName: {
      color: '#333333',
      display: 'flex',
      fontFamily: MessagePreviewShuuemura.HELVETICA_NEUE_LTSTD_LTEX,
      fontSize: '11px',
      lineHeight: '14px',
      letterSpacing: '0.2px',
      flexDirection: 'row',
      alignSelf: 'center',
    },
    title: {
      display: 'flex',
      fontSize: 23.4,
      fontFamily: MessagePreviewShuuemura.HELVETICA_NEUE_LTSTD_LTEX,
      color: 'rgb(51, 51, 51)',
      lineHeight: '30px',
      letterSpacing: 1,
      marginTop: 10,
      marginBottom: 0,
      wordBreak: 'break-word',
    },
    description: {
      fontSize: 12.5,
      fontFamily: MessagePreviewShuuemura.HELVETICA_NEUE_LTSTD_LTEX,
      color: '#494949',
      lineHeight: '22px',
      marginTop: '10px',
      marginBottom: '8px',
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
    },
    weblinks: {
      marginBottom: 12,
    },
    weblink: {
      order: 2,
      display: 'flex',
      flexDirection: 'row',
      color: '#ff0099',
      fontSize: '11.5px',
      letterSpacing: '0.25px',
      fontFamily: MessagePreviewShuuemura.HELVETICA_NEUE_LTSTD_LTEX,
      lineHeight: '21px',
      alignItems: 'center',
    },
    messageContent: {
      marginLeft: 12,
      marginRight: 12,
    },
    categoryAndPublication: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    publicationDate: {
      fontSize: '10px',
      fontFamily: MessagePreviewShuuemura.HELVETICA_NEUE_LTSTD_LTEX,
      color: '#A9A9A9',
      lineHeight: '13px',
      letterSpacing: '0.2px',
    },
    ctas: {
      position: 'absolute',
      bottom: 0,
      marginTop: 'auto', //basically: `justify-self: 'flex-end'`
      marginBottom: 42,
    },
    line: {
      borderWidth: '0px 0px 1px',
      borderBottomStyle: 'solid',
      borderBottomColor: '#737373',
    },
    label: {
      color: 'white',
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: '26px',
    },
    webText: {
      color: '#494949',
    },
    underlineText: {
      color: '#ff0099',
    },
    ctaOpenButton: {
      marginLeft: MessagePreviewShuuemura.previewWidth - 55,
      width: 35,
      height: 35,
    },
    labelText: {
      color: 'white',
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: '13px',
      marginBottom: 7,
    },
  });
}
