import makeClient from '../../helpers/fetcher';
import {tracker} from './analyticsMiddleware';

const clientMiddleware = ({dispatch, getState}) => {
  return (next) => (action) => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }

    const {promise} = action;

    if (!promise) {
      return next(action);
    }

    if (getState().auth.loggedIn) {
      return promise(makeClient());
    } else {
      console.log('Skipped request, no auth');
      return Promise.reject();
    }
  };
};

export default [clientMiddleware, tracker];
