import terms from '../../helpers/terms';
import {validateClientId} from '../../helpers/validation';

export default (values, {brandId}) => {
  const errors = {};
  if (!values.name) {
    errors.name = terms.required;
  }
  if (!values.clientId) {
    errors.clientId = terms.required;
  } else {
    const clientIdCheck = validateClientId(brandId, values.clientId);
    if (!clientIdCheck.status) {
      errors.clientId = clientIdCheck.errorMessage;
    }
  }
  return errors;
};
