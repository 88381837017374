import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {asyncConnect} from 'redux-connect';
import {connect} from 'react-redux';
import {css, StyleSheet} from 'aphrodite';
import {defineMessages, injectIntl} from 'react-intl';
import convertAndDownload from '../../helpers/converter';
import {
  createClient,
  deselectClient,
  getClients,
  resetClientPassword,
  selectClient,
  updateClient,
  updateClientLocally,
} from '../../redux/modules/clients';
import {
  Button,
  FloatingLabel,
  Select,
  UpsertButton,
} from '../../helpers/formComponents';
import Form from '../../components/Form/Form';
import validate from './clientValidation';
import terms from '../../helpers/terms';
import {loadAllSegments} from '../../redux/modules/segments';
import {getBrandId, SUB_BRAND_WEB_CONSOLE} from '../../redux/modules/brand';
import {V2_BRANDS} from '../../config/brands';
import _ from 'lodash';
import {showErrors} from '../../helpers/errors';

class ClientDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      resettingPassword: false,
    };
  }

  static propTypes = {
    hasPassword: PropTypes.bool.isRequired,
    clients: PropTypes.array,
    dispatch: PropTypes.func,
    client: PropTypes.object,
    password: PropTypes.string,
    webConsoleType: PropTypes.string,
    segments: PropTypes.array,
    isV2: PropTypes.bool,
  };

  _componentWillUnmount() {
    this.props.dispatch(deselectClient());
  }

  _arrayToString = (segmentsArray) => {
    return segmentsArray.join('|');
  };

  _stringToArray = (segmentsString) => {
    return segmentsString.split('|');
  };

  _onChange = (field) => (value) => {
    this._onValueChange(field, value);
  };

  _onValueChange = (key, value) => {
    let client = {...this.props.client};
    client[key] = value;

    this.props.dispatch(updateClientLocally(client));
  };

  _submit = () => {
    const brandId = this.props.dispatch(getBrandId());
    const errors = validate(this.props.client, {
      brandId,
      webConsoleType: this.props.webConsoleType,
    });
    if (_.isEmpty(errors)) {
      this._saveMessage();
    } else {
      showErrors(errors);
    }
  };

  _saveMessage = () => {
    this.setState({submitting: true}, () => {
      const toSend = this._restructureObj(this.props.client);
      if (
        this.props.client &&
        this.props.client.id &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.id
      ) {
        this.props
          .dispatch(updateClient(toSend.id, toSend))
          // .then(() => this.props.dispatch(selectClient(toSend.id)))
          .then(() => this.setState({submitting: false}));
      } else {
        this.props.dispatch(createClient(toSend)).then((client) => {
          if (this.props.hasPassword && !this.props.isV2) {
            const {id, password, name} = client;
            convertAndDownload(
              [
                {
                  id,
                  password,
                },
              ],
              `${id}_${name}_password.csv`
            );
          }
          return this.props.history.push(`/clients/edit/${client.id}`);
        });
      }
    });
  };

  _restructureObj = (values) => {
    const {webConsoleType, segments} = this.props;

    if (webConsoleType === SUB_BRAND_WEB_CONSOLE) {
      // Type all does not exist amongst the segments list hence finds the ones that are not in the list.
      const segmentsTypeAll = values.segments
        .split('|')
        .filter(
          (segmentId) => !segments.find((segment) => segment.id === segmentId)
        );
      const mergedSegments = [].concat
        .apply(
          [],
          [values.salesSegments, values.genericSegments, segmentsTypeAll]
        )
        .join('|');

      values.segments = mergedSegments;
      delete values.salesSegments;
      delete values.genericSegments;
      return values;
    }

    return {
      ...values,
      segments: this._arrayToString(values.segments),
    };
  };

  _onResetClientPasswordClick = () => {
    this.setState({resettingPassword: true}, () => {
      this.props
        .dispatch(resetClientPassword(this.props.client.id))
        .then((password) => {
          const {id, name} = this.props.client;
          convertAndDownload(
            [
              {
                id,
                password,
              },
            ],
            `${id}_${name}_password.csv`
          );
          this.setState({resettingPassword: false});
        });
    });
  };

  _segmentOptions = (type) => {
    const segments = this.props.segments;
    return segments
      .filter((segment) => segment.type === type)
      .map((segment) => ({id: segment.id, label: segment.name}));
  };

  render() {
    const {
      client: {name, id, segments, salesSegments, genericSegments},
      webConsoleType,
      client,
      password,
      hasPassword,
      isV2,
      match: {params},
    } = this.props;
    const {styles} = ClientDetails;
    const isEdit = params && params.id && client && client.id;

    const messages = defineMessages({
      clientIdPlaceholder: {
        id: 'ClientDetails.clientIdPlaceholder',
        defaultMessage: 'Enter Client ID',
        description: 'The placeholder text of the client id input field',
      },
      clientNamePlaceholder: {
        id: 'ClientDetails.clientNamePlaceholder',
        defaultMessage: 'Enter Client Name',
        description: 'The placeholder text of the client name input field',
      },
      clientGenericTargetGroupLabel: {
        id: 'ClientDetails.clientGenericTargetGroupLabel',
        defaultMessage: 'Enter client to Generic Target Group(s)',
        description: 'The label of the client generic target group selector',
      },
      clientTargetGroupLabel: {
        id: 'ClientDetails.clientTargetGroupLabel',
        defaultMessage: 'Enter client to Target Group(s)',
        description: 'The label of the client target group selector',
      },
      clientSalesTargetGroupPlaceholder: {
        id: 'ClientDetails.clientSalesTargetGroupPlaceholder',
        defaultMessage: 'Sales Target Group(s)',
        description:
          'The placeholder text of the client sales target group selector',
      },
      clientTargetGroupPlaceholder: {
        id: 'ClientDetails.clientTargetGroupPlaceholder',
        defaultMessage: 'Add client to Target Group(s)',
        description: 'The placeholder text of the client target group selector',
      },
      resetPassword: {
        id: 'ClientDetails.resetPasswordLabel',
        defaultMessage: 'Reset password',
        description:
          'The label for the button on which you can click to get a new password',
      },
      newPassword: {
        id: 'ClientDetails.newPasswordLabel',
        defaultMessage: 'New password',
        description: 'The text that preceeds the generated password. ',
      },
    });
    return (
      <div className={css(styles.container)}>
        <Form style={styles.form}>
          {isEdit ? (
            <div className={css(styles.control)}>
              <label>{this.props.intl.formatMessage(terms.clientId)}</label>
              <div>{client.id}</div>
            </div>
          ) : (
            <FloatingLabel
              label={this.props.intl.formatMessage(terms.clientId)}
              placeholder={this.props.intl.formatMessage(
                messages.clientIdPlaceholder
              )}
              type="text"
              name="id"
              value={id}
              onValueChange={this._onValueChange}
            />
          )}
          <FloatingLabel
            label={this.props.intl.formatMessage(terms.clientName)}
            placeholder={this.props.intl.formatMessage(
              messages.clientNamePlaceholder
            )}
            type="text"
            autoFocus={!client}
            name="name"
            value={name}
            onValueChange={this._onValueChange}
          />
          {webConsoleType === SUB_BRAND_WEB_CONSOLE && (
            <Select
              local={true}
              label={this.props.intl.formatMessage(
                messages.clientTargetGroupLabel
              )}
              placeholder={this.props.intl.formatMessage(
                messages.clientSalesTargetGroupPlaceholder
              )}
              multiple={true}
              value={salesSegments}
              required={false}
              options={this._segmentOptions('sales')}
              onChange={this._onChange('salesSegments')}
              disabled={true}
              name="salesSegments"
            />
          )}
          {webConsoleType === SUB_BRAND_WEB_CONSOLE && (
            <Select
              local={true}
              label={this.props.intl.formatMessage(
                messages.clientGenericTargetGroupLabel
              )}
              placeholder={this.props.intl.formatMessage(
                messages.clientTargetGroupPlaceholder
              )}
              multiple={true}
              value={genericSegments}
              required={true}
              onChange={this._onChange('genericSegments')}
              options={this._segmentOptions('generic')}
              resource="genericSegments"
              name="genericSegments"
            />
          )}
          {webConsoleType !== SUB_BRAND_WEB_CONSOLE && (
            <Select
              local={false}
              label={this.props.intl.formatMessage(
                messages.clientTargetGroupLabel
              )}
              placeholder={this.props.intl.formatMessage(
                messages.clientTargetGroupPlaceholder
              )}
              multiple={true}
              value={segments}
              required={true}
              onChange={this._onChange('segments')}
              resource="segments"
              labelKey="name"
              name="segments"
            />
          )}
          <UpsertButton
            loading={this.state.submitting}
            value={isEdit}
            wrapperStyle={styles.button}
            onClick={this._submit}
          />
        </Form>

        {hasPassword && !isV2 && isEdit && (
          <div className={css(styles.resetPassword)}>
            <Button
              onClick={this._onResetClientPasswordClick}
              loading={this.state.resettingPassword}
            >
              {this.props.intl.formatMessage(messages.resetPassword)}
            </Button>
            <div>
              {password ? (
                <span>
                  {this.props.intl.formatMessage(messages.newPassword)}
                  {':'}
                  &nbsp;
                  <span className={css(styles.password)}>{password}</span>
                </span>
              ) : (
                <span />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  static styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    form: {
      marginTop: 50,
      width: 380,
    },
    control: {
      position: 'relative',
      width: '100%',
      borderBottom: '1px solid #ddd',
      paddingBottom: '10px',
      marginBottom: '20px',
      backgroundColor: 'rgba(0, 211, 211, 0.26)',
    },
    button: {
      width: '100%',
    },
    resetPassword: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 380,
      marginTop: 50,
    },
    password: {
      fontStyle: 'italic',
    },
  });
}

export default asyncConnect([
  {
    key: 'load',
    promise: (helpers) => {
      const _createClientObj = () => {
        const state = helpers.store.getState();
        const webConsoleType = state.brand.webConsoleType;
        const client = state.clients.current;
        const segments = state.segments.segments;
        if (webConsoleType === SUB_BRAND_WEB_CONSOLE) {
          const salesSegments = segments
            .filter((segment) => segment.type === 'sales')
            .filter((segment) => client.segments.includes(segment.id))
            .map((segment) => segment.id);

          const genericSegments = segments
            .filter((segment) => segment.type === 'generic')
            .filter((segment) => client.segments.includes(segment.id))
            .map((segment) => segment.id);

          return {
            ...client,
            salesSegments,
            genericSegments,
          };
        }

        return {
          ...client,
          segments: client.segments.split('|'),
        };
      };
      if (helpers.match.params.id) {
        return Promise.all([
          helpers.store.dispatch(getClients()).then(async () => {
            await helpers.store.dispatch(loadAllSegments());
            await helpers.store.dispatch(selectClient(helpers.match.params.id));
            return helpers.store.dispatch(
              updateClientLocally(_createClientObj())
            );
          }),
        ]);
      } else {
        return helpers.store.dispatch(deselectClient());
      }
    },
  },
])(
  connect((state) => {
    return {
      webConsoleType: state.brand.webConsoleType,
      hasPassword: state.brand.features.hasPassword,
      client: state.clients.current,
      password: state.clients.password,
      segments: state.segments.segments,
      isV2: V2_BRANDS.indexOf(state.brand.id) > -1,
    };
  })(injectIntl(ClientDetails))
);
