import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {asyncConnect} from 'redux-connect';
import {connect} from 'react-redux';
import {injectIntl, defineMessages} from 'react-intl';
import ctaGroupTypeAll from '../../assets/images/ctaGroupTypeAll.png';
import ctaGroupTypeGroup from '../../assets/images/ctaGroupTypeGroup.png';
import {deleteCTA, deselectCta, loadCTAS} from '../../redux/modules/ctas';
import FixedDataTable from '../../components/FixedDataTable/FixedDataTable';
import TextCell from '../../components/FixedDataTable/TextCell';
import {Column} from 'fixed-data-table-2';
import {css, StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
  segmentTypes: {
    height: 20,
    width: 20,
  },
});

class CTASOverview extends Component {
  static propTypes = {
    ctas: PropTypes.array,
    dispatch: PropTypes.func,
  };

  componentDidMount = () => {
    // Clear category form on overview, to make sure there is no fields entered on 'create state'
    this.props.dispatch(deselectCta());
  };

  _editCta = (cta) => () => {
    this.props.history.push({
      pathname: `/ctas/edit/${cta.id}`,
      search: '',
    });
  };

  _createCta = () => {
    this.props.history.push('/ctas/create');
  };

  _filter = (value) => (cta) => {
    return (
      (cta.name && cta.name.toLowerCase().indexOf(value) !== -1) ||
      (cta.defaultLabel &&
        cta.defaultLabel.toLowerCase().indexOf(value) !== -1) ||
      (cta.defaultValue &&
        cta.defaultValue.toLowerCase().indexOf(value) !== -1) ||
      (cta.type && cta.type.toLowerCase().indexOf(value) !== -1)
    );
  };

  _defineMessages() {
    return defineMessages({
      reference: {
        id: 'CTASOverview.reference',
        defaultMessage: 'Reference',
        description: 'The title of the CTA reference column',
      },
      type: {
        id: 'CTASOverview.type',
        defaultMessage: 'Type',
        description: 'The title of the CTA type column',
      },
      segmentType: {
        id: 'CTASOverview.segmentType',
        defaultMessage: 'Segment Type',
        description: 'The title of the CTA segmentType column',
      },
      defaultMessage: {
        id: 'CTASOverview.defaultMessage',
        defaultMessage: 'Default Message',
        description: 'The title of the CTA default message column',
      },
      labelColumnTitle: {
        id: 'CTAGroups.labelColumnTitle',
        defaultMessage: 'CTA Label',
        description:
          'The title of the label column in the target groups table of a cta of type group',
      },
      valueColumnTitle: {
        id: 'CTAGroups.valueColumnTitle',
        defaultMessage: 'CTA Value',
        description:
          'The title of the value column in the target groups table of a cta of type group',
      },
      confirmLabel: {
        id: 'CTASOverview.confirmLabel',
        defaultMessage: 'Are you sure you want to permanently delete this CTA?',
        description:
          'The confirmation message that is shown when the user is about to delete a CTA',
      },
    });
  }

  render() {
    const {dispatch, ctas} = this.props;
    const messages = this._defineMessages();
    return (
      <div>
        <FixedDataTable
          inputData={ctas}
          dispatch={dispatch}
          filter={this._filter}
          createItem={this._createCta}
          deleteItem={deleteCTA}
          editItem={this._editCta}
          confirmLabel={this.props.intl.formatMessage(messages.confirmLabel)}
        >
          <Column
            headerTitle={this.props.intl.formatMessage(messages.reference)}
            flexGrow={1}
            displayCell={(ctas, props) => (
              <TextCell {...props}>{ctas.name}</TextCell>
            )}
            width={180}
            sortingField={(ctas) => ctas.name}
            columnKey={'name'}
          />
          <Column
            headerTitle={this.props.intl.formatMessage(messages.type)}
            displayCell={(ctas, props) => (
              <TextCell {...props}>{ctas.type}</TextCell>
            )}
            width={110}
            sortingField={(ctas) => ctas.type}
            columnKey={'type'}
          />
          <Column
            headerTitle={this.props.intl.formatMessage(messages.segmentType)}
            displayCell={(ctas, props) => (
              <TextCell {...props}>
                <img
                  alt={'icon cta type'}
                  src={
                    ctas.segmentType === 'all'
                      ? ctaGroupTypeAll
                      : ctaGroupTypeGroup
                  }
                  className={css(styles.segmentTypes)}
                />
              </TextCell>
            )}
            width={140}
            sortingField={(ctas) => ctas.segmentType}
            columnKey={'segment type'}
          />
          <Column
            headerTitle={this.props.intl.formatMessage(
              messages.labelColumnTitle
            )}
            flexGrow={1}
            displayCell={(ctas, props) => (
              <TextCell {...props}>{ctas.defaultLabel}</TextCell>
            )}
            width={200}
            sortingField={(ctas) => ctas.defaultLabel}
            columnKey={'defaultLabel'}
          />
          <Column
            headerTitle={this.props.intl.formatMessage(
              messages.valueColumnTitle
            )}
            flexGrow={1}
            displayCell={(ctas, props) => (
              <TextCell {...props}>{ctas.defaultValue}</TextCell>
            )}
            width={200}
            sortingField={(ctas) => ctas.defaultValue}
            columnKey={'defaultValue'}
          />
        </FixedDataTable>
      </div>
    );
  }
}

export default asyncConnect([
  {
    key: 'load',
    promise: (helpers) => helpers.store.dispatch(loadCTAS()),
  },
])(
  connect((state) => {
    return {
      ctas: state.cta.ctas || [],
    };
  })(injectIntl(CTASOverview))
);
