import React, {Component} from 'react';
import moment from 'moment';
import {StyleSheet, css} from 'aphrodite';
import CtaRow from './rpb/CtaRow';
import CtaHeader from './rpb/CtaHeader';

import {injectIntl, defineMessages} from 'react-intl';

// settings
const dateFormat = 'DD MMMM.YYYY';
const previewScreenWidth = 231;
const previewScreenHeight = 412;

// colors
const white = '#fff';
const dark = '#171c21';
const dark70 = 'rgba(23, 28, 33, 0.7)';

// assets
import LOGO_IMAGE from '../../assets/images/rpb/logoSmall.png';
import BACK_ARROW from '../../assets/images/rpb/backArrow.png';
import CTA_PHONE_ICON from '../../assets/images/rpb/callPhone.png';
import CTA_EMAIL_ICON from '../../assets/images/rpb/mailMask.png';
import CTA_WEB_ICON from '../../assets/images/rpb/linkMask.png';
import CTA_CHAT_ICON from '../../assets/images/rpb/messageChat.png';

const CTA_ICONS = {
  phone: CTA_PHONE_ICON,
  web: CTA_WEB_ICON,
  email: CTA_EMAIL_ICON,
  chat: CTA_CHAT_ICON,
  whatsapp: CTA_PHONE_ICON,
};

class MessagePreviewRPB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  _defineMessages = () => {
    return defineMessages({
      messageNoImageLabel: {
        id: 'MessagePreview.messageNoImageLabel',
        description:
          'The placeholder for when there is no image added to a message',
        defaultMessage: 'Please upload a picture',
      },
      ctaHeaderLabel: {
        id: 'MessagePreview.ctaHeaderLabel',
        defaultMessage: 'Get in touch',
        description: 'The text on the cta header when it is expanded',
      },
    });
  };

  _openCtaMenu = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  };

  render() {
    const {
      message: {description, title, publicationDate, image},
      categories,
      ctas,
    } = this.props;
    const {menuOpen} = this.state;
    const {styles} = MessagePreviewRPB;

    const messages = this._defineMessages();
    const ctasFormated = ctas.map((cta) => {
      return {
        label: cta.defaultLabel,
        icon: CTA_ICONS[cta.type],
      };
    });

    const hasMenuButton = !menuOpen && ctasFormated.length > 1;
    const categoryName = categories.length > 0 ? categories[0].name : '...';
    const categoryColor = categories.length > 0 ? categories[0].color : 'grey';
    const capitalizedCategoryName = categoryName
      ? categoryName.charAt(0).toUpperCase() + categoryName.slice(1)
      : '';
    const formatedPublicationDate = publicationDate
      ? moment(publicationDate).format(dateFormat)
      : '...';

    // styles with category colors
    const categoryContainerStyle = {
      position: 'relative',
      top: -20,
      left: 115,
      width: 100,
      backgroundColor: categoryColor,
    };

    const titleStyle = {
      fontFamily: 'BentonSans-Bold',
      boxSizing: 'border-box',
      fontSize: 18,
      color: dark,
      paddingLeft: 16,
      letterSpacing: 0.3,
      marginBottom: 18,
      display: 'flex',
      wordBreak: 'break-word',
      borderLeft: `3px solid ${categoryColor}`,
    };

    const dateStyle = {
      fontFamily: 'BentonSans-Medium',
      fontSize: 9,
      paddingLeft: 16,
      marginBottom: 18,
      color: categoryColor,
    };

    return (
      <div className={css(styles.preview)}>
        <div className={css(styles.container)}>
          <div className={css(styles.header)}>
            <div className={css(styles.backButton)}>
              <img
                src={BACK_ARROW}
                className={css(styles.backButtonAsset)}
                alt="backButton"
              />
            </div>

            <img className={css(styles.logo)} src={LOGO_IMAGE} alt="logo" />
          </div>

          <div className={css(styles.imageDiv)}>
            {image ? (
              <img className={css(styles.image)} src={image} alt="message" />
            ) : (
              <div className={css(styles.noImage)}>
                <div className={css(styles.noImageLabel)}>
                  {this.props.intl.formatMessage(messages.messageNoImageLabel)}
                </div>
              </div>
            )}

            <div style={categoryContainerStyle}>
              <div className={css(styles.category)}>
                {capitalizedCategoryName}
              </div>
            </div>
          </div>

          <div className={css(styles.bottomWrapper)}>
            <div style={titleStyle}>{(title || '').toUpperCase() || ''}</div>
            <div style={dateStyle}>{formatedPublicationDate}</div>

            <div className={css(styles.description)}>{description}</div>
          </div>
        </div>

        <div className={styles.ctaContainer}>
          {menuOpen && ctasFormated.length > 1 && (
            <CtaHeader
              onClick={this._openCtaMenu}
              label={this.props.intl.formatMessage(messages.ctaHeaderLabel)}
              color={categoryColor}
            />
          )}

          {menuOpen &&
            ctasFormated.length > 1 &&
            ctasFormated.slice(1).map((cta) => {
              return (
                <CtaRow
                  label={cta.label}
                  icon={cta.icon}
                  color={categoryColor}
                  hasMenuButton={false}
                  openMenu={this._openCtaMenu}
                />
              );
            })}

          {ctasFormated.length > 0 && (
            <CtaRow
              label={ctasFormated[0].label}
              icon={ctasFormated[0].icon}
              color={categoryColor}
              hasMenuButton={hasMenuButton}
              openMenu={this._openCtaMenu}
            />
          )}
        </div>
      </div>
    );
  }

  static styles = StyleSheet.create({
    preview: {
      width: previewScreenWidth,
      height: previewScreenHeight,
      overflow: 'hidden',
      margin: 53,
      marginRight: 190,
      marginTop: 70,
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      overflowY: 'scroll',
      paddingRight: 20,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      position: 'absolute',
      height: 30,
      width: previewScreenWidth,
      backgroundColor: white,
      alignItems: 'center',
    },
    logo: {
      marginRight: 16,
      height: 20,
    },
    backButton: {
      marginLeft: 16,
    },
    backButtonAsset: {
      height: 8,
    },
    imageDiv: {
      width: previewScreenWidth,
    },
    image: {
      width: previewScreenWidth,
    },
    noImage: {
      display: 'flex',
      width: previewScreenWidth,
      backgroundColor: '#ccc',
      height: 150,
      justifyContent: 'center',
      alignContent: 'center',
    },
    noImageLabel: {
      paddingTop: 70,
      color: '#000',
      fontSize: 11,
    },
    bottomWrapper: {
      paddingBottom: 30,
    },
    category: {
      fontFamily: 'BentonSans-Bold',
      padding: 6,
      fontSize: 9,
      letterSpacing: 0.6,
      color: white,
    },
    description: {
      fontFamily: 'BentonSans-Regular',
      paddingLeft: 16,
      fontSize: 11,
      color: dark70,
      lineHeight: 1.5,
      letterSpacing: 0.6,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      display: 'flex',
    },
    ctaContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
  });
}

export default injectIntl(MessagePreviewRPB);
