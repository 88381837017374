import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {asyncConnect} from 'redux-connect';
import {connect} from 'react-redux';
import moment from 'moment';
import {defineMessages, injectIntl} from 'react-intl';
import MessagesChart from './MessagesChart';
import {
  createMessage,
  deleteMessage,
  exportMessages,
  getMessages,
} from '../../redux/modules/message';
import {COUNTRY_BRAND_WEB_CONSOLE, getBrand} from '../../redux/modules/brand';
import terms from '../../helpers/terms';

import FixedDataTable, {
  SORT_DIRECTIONS,
} from '../../components/FixedDataTable/FixedDataTable';
import TextCell from '../../components/FixedDataTable/TextCell';
import {Column} from 'fixed-data-table-2';

class MessageOverview extends Component {
  static propTypes = {
    lookup: PropTypes.object,
    messages: PropTypes.array,
    dispatch: PropTypes.func,
    brandId: PropTypes.string,
  };

  _editMessage = (message) => () => {
    this.props.history.push({
      pathname: `/messages/${message.id}`,
      search: '',
    });
  };

  _onCreateMessage = () => {
    this.props
      .dispatch(createMessage())
      .then((message) =>
        this.props.history.push(`/messages/create/${message.data.id}`)
      );
  };

  _filter = (value) => (message) => {
    return (
      (message.title && message.title.toLowerCase().indexOf(value) !== -1) ||
      (message.description &&
        message.description.toLowerCase().indexOf(value) !== -1)
    );
  };

  _getData = () => {
    return this.props.messages.sort((a, b) => {
      if (
        a.publicationDate === undefined ||
        new Date(a.publicationDate).getTime() >
          new Date(b.publicationDate).getTime()
      ) {
        return -1;
      }
      if (
        b.publicationDate === undefined ||
        new Date(a.publicationDate).getTime() <
          new Date(b.publicationDate).getTime()
      ) {
        return 1;
      }
      return 0;
    });
  };

  _defineMessages() {
    return defineMessages({
      confirmLabel: {
        id: 'MessageOverview.confirmLabel',
        defaultMessage:
          'Are you sure you want to permanently delete this message?',
        description:
          'The confirmation message that is shown when the user is about to delete a message',
      },
      searchMessagesLabel: {
        id: 'MessageOverview.searchMessagesLabel',
        defaultMessage: 'Search messages',
        description:
          'The search bar label in the top-right of the app which filters through the messages',
      },
      messageStatusColumnTitle: {
        id: 'MessageOverview.messageStatusColumnTitle',
        defaultMessage: 'Status',
        description: 'The title of the message status column',
      },
      messagePublicationDateColumnTitle: {
        id: 'MessageOverview.messagePublicationDateColumnTitle',
        defaultMessage: 'Date',
        description: 'The title of the publication date column',
      },
      messageTitleColumnTitle: {
        id: 'MessageOverview.messageTitleColumnTitle',
        defaultMessage: 'Message Title',
        description: 'The title of the message title column',
      },
      openRateColumnTitle: {
        id: 'MessageOverview.openRateColumnTitle',
        defaultMessage: 'Open Rate',
        description: 'The title of the open rate column',
      },
      expirationDateColumnTitle: {
        id: 'MessageOverview.expirationDateColumnTitle',
        defaultMessage: 'Expires on',
        description: 'The title of the expiration date column',
      },
      likeColumnTitle: {
        id: 'MessageOverview.likeColumnTitle',
        defaultMessage: 'Likes',
        description: 'The title of the likes column',
      },
    });
  }

  render() {
    const {
      dispatch,
      messages,
      hasLikesOnMessages,
      brandId,
      features,
      webConsoleType,
    } = this.props;
    const allCategories = this.props.lookup.categories || {};
    const messagesIntl = this._defineMessages();

    return (
      <div>
        {webConsoleType !== COUNTRY_BRAND_WEB_CONSOLE && (
          <MessagesChart
            messages={messages}
            brandId={brandId}
            webConsoleType={webConsoleType}
          />
        )}
        {features.modules.includes('messages') && (
          <FixedDataTable
            inputData={this._getData()}
            dispatch={dispatch}
            filter={this._filter}
            createItem={this._onCreateMessage}
            deleteItem={deleteMessage}
            editItem={this._editMessage}
            confirmLabel={this.props.intl.formatMessage(
              messagesIntl.confirmLabel
            )}
            export={() => this.props.dispatch(exportMessages())}
            // filterLabel={this.props.intl.formatMessage(  TODO: not yet implemented in new FixedDataTable
            //   messagesIntl.searchMessagesLabel,
            // )}
          >
            <Column
              headerTitle={this.props.intl.formatMessage(
                messagesIntl.messageStatusColumnTitle
              )}
              displayCell={(message, props) => {
                if (!message.status) return null; // TODO: Is this a necessary check?
                let newStatus = message.status.split('/')[1];
                return (
                  <TextCell {...props}>
                    {newStatus.charAt(0).toUpperCase() + newStatus.slice(1)}
                  </TextCell>
                );
              }}
              width={90}
              sortingField={(message) => message.status}
              columnKey={'status'}
            />
            <Column
              headerTitle={this.props.intl.formatMessage(
                messagesIntl.messagePublicationDateColumnTitle
              )}
              displayCell={(message, props) => (
                <TextCell {...props}>{`${moment(message.publicationDate).format(
                  'DD.MM.YY'
                )} at ${moment(message.publicationDate).format(
                  'HH:mm'
                )}`}</TextCell>
              )}
              width={130}
              sortingField={(message) => message.publicationDate}
              columnKey={'publicationDate'}
              initialSortDirection={SORT_DIRECTIONS.DESC}
            />
            <Column
              headerTitle={this.props.intl.formatMessage(
                messagesIntl.messageTitleColumnTitle
              )}
              flexGrow={1}
              displayCell={(message, props) => (
                <TextCell {...props}>{message.title}</TextCell>
              )}
              width={200}
              sortingField={(message) => message.title}
              columnKey={'title'}
            />
            <Column
              headerTitle={this.props.intl.formatMessage(terms.category)}
              flexGrow={1}
              displayCell={(message, props) => {
                if (message.categories && message.categories.length > 0) {
                  const categoryId = message.categories[0];
                  const category = allCategories[categoryId];
                  return (
                    <TextCell {...props}>
                      {category ? category.name : ''}
                    </TextCell>
                  );
                } else {
                  return <TextCell {...props} />;
                }
              }}
              width={80}
              sortingField={(message) => message.categories}
              columnKey={'categories'}
            />
            {/* <Column
              headerTitle={this.props.intl.formatMessage(
                messagesIntl.openRateColumnTitle
              )}
              displayCell={(message, props) => {
                if (message.analytics) {
                  const opens = message.analytics.uniqueClientOpens || '';
                  const sent = message.analytics.uniqueClientSent || '';
                  return <TextCell {...props}>{`${opens}/${sent}`}</TextCell>;
                } else {
                  return <TextCell {...props}>{`/`}</TextCell>;
                }
              }}
              width={160}
              // sortingField={(message) => message.openRate}
              // columnKey={'openRate'} TODO filtering on?
            /> */}

            {/* {hasLikesOnMessages && (
              <Column
                headerTitle={this.props.intl.formatMessage(
                  messagesIntl.likeColumnTitle
                )}
                displayCell={(message, props) => {
                  if (message.likes) {
                    const likes = message.likes || '';
                    return <TextCell {...props}>{`${likes}`}</TextCell>;
                  }
                }}
                width={100}
                sortingField={(message) => message.likes}
              />
            )} */}
            <Column
              headerTitle={this.props.intl.formatMessage(
                messagesIntl.expirationDateColumnTitle
              )}
              displayCell={(message, props) => (
                <TextCell {...props}>
                  {moment(message.expirationDate).format('DD.MM.YY')}
                </TextCell>
              )}
              width={120}
              sortingField={(message) => message.expirationDate}
              columnKey={'expirationDate'}
              initialSortDirection={SORT_DIRECTIONS.DESC}
            />
          </FixedDataTable>
        )}
      </div>
    );
  }
}

export default asyncConnect([
  {
    key: 'load',
    promise: (helpers) => {
      return helpers.store.dispatch(getMessages());
    },
  },
  {
    key: 'load2',
    promise: (helpers) => {
      return helpers.store.dispatch(getBrand());
    },
  },
])(
  connect((state) => {
    return {
      messages: state.messages.messages || [],
      lookup: state.lookup,
      brandId: state.brand.id,
      webConsoleType: state.brand.webConsoleType,
      hasLikesOnMessages: state.brand.features.hasLikesOnMessages,
      features: state.brand.features,
    };
  })(injectIntl(MessageOverview))
);
