export const LP = 'com.immidi.lp';
export const ESSIE = 'com.immidi.essie';
export const MATRIXNL = 'MatrixNL';
export const SHU = 'ShuNL';
export const DECLEOR = 'com.immidi.decleor';
export const CARECOSMETICS = 'com.immidi.carecosmetics';
export const UFS_PL = 'com.immidi.ufs.pl';
export const UFS_BE = 'com.immidi.ufs.be';
export const RPB_NL = 'com.immidi.rpb.nl';
export const NF = 'com.immidi.nf.de';

export const LPPD = 'com.immidi.lppd';
export const LPPD_NL = 'com.immidi.lppd.nl';
export const LPPD_NL_LPPD = 'com.immidi.lppd.nl.lpp';
// export const LPPD_UK = 'com.immidi.lppd.uk';
export const LPPD_NL_REDKEN = 'com.immidi.lppd.nl.redken';
export const LPPD_NL_SHU = 'com.immidi.lppd.nl.shu';
export const LPPD_UK_REDKEN = 'com.immidi.lppd.uk.redken';
export const LLUXE_BNL = 'com.immidi.lluxe.bnl';
export const BD = 'com.immidi.bd';

// ACD BRANDS

export const ACD = 'com.immidi.acd';
export const ACD_NL = 'com.immidi.acd.bnl';
export const ACD_NL_ACD = 'com.immidi.acd.bnl.acd';
export const ACD_NL_LAROCHEPOSAY = 'com.immidi.acd.bnl.larocheposay';
export const ACD_NL_VICHY = 'com.immidi.acd.bnl.vichy';
export const ACD_NL_CERAVE = 'com.immidi.acd.bnl.cerave';

export const DPP = 'com.immidi.dpp';
export const DPP_DACH = 'com.immidi.dpp.dach';
export const DPP_DACH_LP = 'com.immidi.dpp.dach.lp';
export const DPP_DACH_KERASTASE = 'com.immidi.dpp.dach.kerastase';
export const DPP_DACH_REDKEN = 'com.immidi.dpp.dach.redken';
export const DPP_DACH_MATRIX = 'com.immidi.dpp.dach.matrix';
export const DPP_DACH_SHU = 'com.immidi.dpp.dach.shu';
export const DPP_DACH_LPP = 'com.immidi.dpp.dach.lpp';

export const V2_BRANDS = [
  ACD,
  ACD_NL,
  ACD_NL_ACD,
  ACD_NL_LAROCHEPOSAY,
  ACD_NL_VICHY,
  ACD_NL_CERAVE,
  DPP,
  DPP_DACH,
  DPP_DACH_LP,
  DPP_DACH_KERASTASE,
  DPP_DACH_REDKEN,
  DPP_DACH_MATRIX,
  DPP_DACH_SHU,
  DPP_DACH_LPP,
];

export const INSIGHTS_ALLOWED_BRANDS = [LPPD_NL_LPPD, LLUXE_BNL, ACD_NL_ACD];
