import routes from '../../helpers/routes';

const ACTION_SELECT_CTA = 'SELECT_CTA';
const ACTION_GET_CTAS = 'GET_CTAS';

export function reducer(
  state = {
    current: {
      segmentType: '',
      type: '',
    },
  },
  action
) {
  switch (action.type) {
    case ACTION_SELECT_CTA: {
      return {
        ...state,
        current: action.data,
      };
    }
    case ACTION_GET_CTAS:
      return {
        ...state,
        ctas: action.data,
      };
    default:
      return state;
  }
}

export const createCta = (values) => {
  return (dispatch) => {
    return dispatch({
      promise: (client) => client.post(`${routes.ctas}`, values),
    });
  };
};

export function updateCTA(values) {
  return (dispatch, getState) => {
    return dispatch({
      promise: (cta) =>
        cta
          .put(`${routes.ctas}/${getState().cta.current.id}`, values)
          .then((updatedCta) =>
            dispatch({type: ACTION_SELECT_CTA, data: updatedCta})
          )
          .catch((err) => console.log(err)),
    });
  };
}

export const loadCTAS = () => {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .get(`${routes.ctas}`)
          .then((ctas) => dispatch({type: ACTION_GET_CTAS, data: ctas})),
    });
  };
};

export function deleteCTA(cta) {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .delete(`${routes.ctas}/${cta.id}`)
          .then(() => dispatch(loadCTAS())),
    });
  };
}

export const loadCTA = (id) => {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .get(`${routes.ctas}/${id}`)
          .then(selectCta(dispatch))
          .catch(() => selectCta(dispatch)()),
    });
  };
};

export function selectCta(dispatch) {
  return (cta) => {
    return dispatch({
      type: ACTION_SELECT_CTA,
      data: cta,
    });
  };
}

export function deselectCta() {
  return (dispatch) => {
    return dispatch({
      type: ACTION_SELECT_CTA,
      data: {},
    });
  };
}

export const updateCtaLocally = (cta) => {
  return (dispatch) => {
    return dispatch({
      type: ACTION_SELECT_CTA,
      data: cta,
    });
  };
};

export function addGroupToCta(cta, group) {
  return (dispatch) => {
    return dispatch({
      promise: (httpClient) =>
        httpClient.put(`${routes.ctas}/${cta.id}/groups`, group).then((cta) =>
          dispatch({
            type: ACTION_SELECT_CTA,
            data: cta,
          })
        ),
    });
  };
}

export function deleteGroupFromCta(cta, group) {
  return (dispatch) => {
    return dispatch({
      promise: (httpClient) =>
        httpClient
          .delete(
            `${routes.ctas}/${cta.id}/groups/${group.segmentGroupId}`,
            group
          )
          .then(selectCta(dispatch)),
    });
  };
}

export const uploadCtaIcsFile = (icsFile) => (dispatch) => {
  if (icsFile.type === 'text/calendar') {
    const data = new FormData();
    data.append('file', icsFile, 'icsFile');

    return dispatch({
      promise: (client) =>
        client.post(`${routes.ctas}/upload-ics`, data).then(),
    });
  } else throw new Error('File type not supported');
};
