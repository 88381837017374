/* eslint-disable react/jsx-no-literals */

//TODO: when finishing this component, eneble eslint again.

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {css, StyleSheet} from 'aphrodite';
import moment from 'moment';

export default class ConversationList extends Component {
  static propTypes = {
    onSelectConversation: PropTypes.func.isRequired,
  };

  _renderItem = (
    {
      id,
      name,
      lastMessageTime,
      conversation: {
        unreadUser = 0,
        lastMessage,
        segments,
        lastMessageSenderName,
      },
    },
    index
  ) => {
    const {conversations, onSelectConversation} = this.props;
    const {styles} = ConversationList;

    return (
      <div
        className={css(styles.item)}
        key={index}
        onClick={() => onSelectConversation(conversations[index])}
      >
        <div className={css(styles.info)}>clientId: {id}</div>
        <div className={css(styles.info)}>clientName: {name}</div>
        <div className={css(styles.info)}>unreadUser: {unreadUser}</div>
        <div className={css(styles.info)}>segments: {segments}</div>
        <div className={css(styles.info)}>
          lastMessageSenderName: {lastMessageSenderName}
        </div>
        <div className={css(styles.info)}>
          lastMessageTime: {moment(lastMessageTime).format()}
        </div>
        <div className={css(styles.infoLastMessage)}>
          lastMessage: {lastMessage}
        </div>
      </div>
    );
  };

  render() {
    const {conversations} = this.props;
    const {styles} = ConversationList;

    return (
      <div className={css(styles.list)}>
        {conversations.map(this._renderItem)}
      </div>
    );
  }

  static styles = StyleSheet.create({
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
    item: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',

      marginBottom: 12,
    },
    info: {
      width: 'calc(100% / 3)',
    },
    infoLastMessage: {
      width: '100%',
    },
  });
}
