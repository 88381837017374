import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';

import {StyleSheet, css} from 'aphrodite';

import './styles.css';
import {injectIntl} from 'react-intl';

import mainStyles from '../../helpers/styles';
import makeClient from '../../helpers/fetcher';
import routes from '../../helpers/routes';
import InputWrapper from '../InputWrapper/InputWrapper';
import _ from 'lodash';

export default injectIntl(
  class Select extends Component {
    constructor(props) {
      super(props);
      if (props.value) {
        this.state = {
          isShowLabel: true,
          apiOptions: [],
        };
      } else {
        this.state = {
          isShowLabel: false,
          apiOptions: [],
        };
      }
    }

    static propTypes = {
      value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      multiple: PropTypes.bool,
      onChange: PropTypes.func,
      updateMessage: PropTypes.func,
      name: PropTypes.string,
      options: PropTypes.array,
      placeholder: PropTypes.string,
      local: PropTypes.bool,
      label: PropTypes.string,
      touched: PropTypes.bool,
      error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      onBlur: PropTypes.func,
      resource: PropTypes.string,
      filter: PropTypes.func,
      alwaysShowLabel: PropTypes.bool,
    };

    _getValue = (value) => {
      if (typeof value === 'string') return value;
      else if (typeof value === 'object') return value.id;
    };

    _onChange = (values) => {
      const {multiple, onChange, updateMessage, name} = this.props;
      if (values) {
        if (multiple) {
          const updatedValues = _.uniq(values.map(this._getValue));
          onChange(updatedValues);
          if (updateMessage) {
            updateMessage(name, updatedValues);
          }
        } else {
          onChange(values.id);
          if (updateMessage) {
            updateMessage(name, values.id);
          }
        }
      }
      if (values) {
        this.setState({
          isShowLabel: true,
        });
      }
      if (values.length === 0) {
        this.setState({
          isShowLabel: false,
        });
      }
    };

    loadOptions = (inputValue, _callback) => {
      const {filter, labelKey = 'name'} = this.props;
      makeClient()
        .get(routes[this.props.resource])
        .then((data) => {
          let dataToBeTransformed = data || [];
          let dataToBeTransformedWithFilter = data || [];
          if (filter) {
            dataToBeTransformed = filter(data);
            dataToBeTransformedWithFilter = filter(data);
          }
          const transformedData = dataToBeTransformed.map((item) => ({
            value: item.id,
            ...item,
          }));

          if (inputValue) {
            dataToBeTransformedWithFilter =
              dataToBeTransformedWithFilter.filter(
                (a) =>
                  (a[labelKey] || '').search(new RegExp(inputValue, 'i')) > -1
              );
          }
          const transformedDataWithFilter = dataToBeTransformedWithFilter.map(
            (item) => ({
              value: item.id,
              ...item,
            })
          );
          this.setState({
            apiOptions: transformedData,
          });
          _callback(transformedDataWithFilter);
        });
    };

    render() {
      const {
        options,
        local,
        placeholder,
        multiple,
        value,
        label,
        touched,
        error,
        onBlur,
        disabled,
        transformInitialValue = false,
        ...rest
      } = this.props;
      const {apiOptions} = this.state;
      const {isShowLabel} = this.state;
      const {styles, sty} = Select;
      let transformedOptions;
      let transformedSelectedValue;
      if (options || (transformInitialValue && apiOptions)) {
        transformedOptions = (options || apiOptions).map((item) => {
          return {value: item.id, ...item};
        });
        if (value) {
          transformedSelectedValue = (options || apiOptions).find(
            (item) => item.id === value
          );
        }
      }

      //TODO use aphrodite for `sty `
      return (
        <InputWrapper>
          <div>
            <div className={css(styles.labelAndError)}>
              <label
                style={
                  this.props.alwaysShowLabel || isShowLabel
                    ? sty.labelShow
                    : sty.labelHide
                }
              >
                {label}
              </label>
              {touched && error && (
                <div className={css(styles.error)}>
                  {typeof error === 'string'
                    ? error
                    : this.props.intl.formatMessage(error)}
                </div>
              )}
            </div>
            {local ? (
              <div>
                <ReactSelect
                  {...rest}
                  name="input field name"
                  options={transformedOptions}
                  onChange={this._onChange}
                  clearable={false}
                  placeholder={placeholder}
                  value={transformedSelectedValue || ''}
                  isMulti={multiple}
                  isDisabled={disabled}
                />
              </div>
            ) : (
              <div>
                <AsyncSelect
                  {...rest}
                  value={transformedSelectedValue || value || ''}
                  loadOptions={this.loadOptions}
                  getOptionValue={(option) => option.value || option}
                  getOptionLabel={(option) => {
                    return (
                      (apiOptions.find((op) => op.id === option) || {}).name ||
                      option?.name ||
                      option[0] ||
                      ''
                    );
                  }}
                  cacheOptions={true}
                  defaultOptions={true}
                  onBlur={() => (onBlur ? onBlur(value) : null)}
                  onChange={this._onChange}
                  clearable={false}
                  isMulti={multiple}
                  placeholder={placeholder}
                  isDisabled={disabled}
                />
              </div>
            )}
          </div>
        </InputWrapper>
      );
    }

    static sty = {
      labelHide: {
        position: 'relative',
        top: '22px',
        opacity: 0,
        fontSize: '14px',
        fontWeight: 600,
        color: '#666666',
      },
      labelShow: {
        position: 'relative',
        top: '-5px',
        opacity: 1,
        fontSize: '14px',
        fontWeight: 600,
        color: '#666666',
        transition: 'top 0.5s',
      },
    };

    static styles = StyleSheet.create({
      labelAndError: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
      },
      ...mainStyles,
    });
  }
);
