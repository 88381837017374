import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {css, StyleSheet} from 'aphrodite';
import _ from 'lodash';
import MessagePreviewMatrix from './MessagePreviewMatrix';
import MessagePreviewShuuemura from './MessagePreviewShuuemura';
import MessagePreviewEssie from './MessagePreviewEssie';
import MessagePreviewLP from './MessagePreviewLP';
import MessagePreviewDecleor from './MessagePreviewDecleor';
import MessagePreviewACD from './MessagePreviewACD';
import MessagePreviewCarecosmetics from './MessagePreviewCarecosmetics';
import MessagePreviewUFS from './MessagePreviewUFS';

import iphoneFrame from './../../assets/images/iphone_message_preview.png';
import {
  CARECOSMETICS,
  DECLEOR,
  ESSIE,
  LLUXE_BNL,
  LP,
  LPPD,
  MATRIXNL,
  NF,
  RPB_NL,
  SHU,
  BD,
  UFS_BE,
  UFS_PL,
  ACD_NL,
  DPP_DACH,
} from '../../config/brands';
import MessagePreviewRPB from './MessagePreviewRPB';
import MessagePreviewNF from './MessagePreviewNF';
import MessagePreviewLoreal from './MessagePreviewLoreal';
import MessagePreviewBD from './MessagePreviewBD';

export default class MessagePreview extends Component {
  static propTypes = {
    message: PropTypes.object,
    lookup: PropTypes.object,
  };

  _renderMessagePreview(brandId, ctas, categories, tag) {
    const {tags = {}} = this.props.brand;

    if (brandId.includes(LPPD)) {
      return (
        <MessagePreviewLoreal
          ctas={ctas}
          brandIcon={this.props.brandIcon}
          categories={categories}
          {...this.props}
        />
      );
    } else if (brandId.includes(ACD_NL) || brandId.includes(DPP_DACH)) {
      return (
        <MessagePreviewACD
          ctas={ctas}
          categories={categories}
          {...this.props}
        />
      );
    }

    switch (brandId) {
      case BD:
        return (
          <MessagePreviewBD
            ctas={ctas}
            brandIcon={(tags[tag] || {}).url}
            categories={categories}
            {...this.props}
          />
        );
      case LLUXE_BNL:
        return (
          <MessagePreviewLoreal
            ctas={ctas}
            brandIcon={(tags[tag] || {}).url}
            categories={categories}
            {...this.props}
          />
        );
      case MATRIXNL:
        return (
          <MessagePreviewMatrix
            ctas={ctas}
            categories={categories}
            {...this.props}
          />
        );
      case SHU:
        return (
          <MessagePreviewShuuemura
            ctas={ctas}
            categories={categories}
            {...this.props}
          />
        );
      case ESSIE:
        return (
          <MessagePreviewEssie
            ctas={ctas}
            categories={categories}
            {...this.props}
          />
        );
      case LP:
        return (
          <MessagePreviewLP
            ctas={ctas}
            categories={categories}
            {...this.props}
          />
        );
      case DECLEOR:
        return (
          <MessagePreviewDecleor
            ctas={ctas}
            categories={categories}
            {...this.props}
          />
        );
      case CARECOSMETICS:
        return (
          <MessagePreviewCarecosmetics
            ctas={ctas}
            categories={categories}
            {...this.props}
          />
        );
      case UFS_BE:
      case UFS_PL:
        return (
          <MessagePreviewUFS
            ctas={ctas}
            categories={categories}
            {...this.props}
          />
        );
      case RPB_NL:
        return (
          <MessagePreviewRPB
            ctas={ctas}
            categories={categories}
            {...this.props}
          />
        );
      case NF:
        return (
          <MessagePreviewNF
            ctas={ctas}
            categories={categories}
            {...this.props}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const {
      message: {categories: categoryIds, ctas: ctaIds, brandId, tag},
      lookup,
    } = this.props;

    const categories = Object.values(_.pick(lookup.categories, categoryIds));
    const ctas = Object.values(_.pick(lookup.ctas, ctaIds));

    const {styles} = MessagePreview;
    return (
      <div className={css(styles.frame)}>
        {this._renderMessagePreview(brandId, ctas, categories, tag)}
        <div className={css(styles.frameOverlay)} />
      </div>
    );
  }

  static styles = StyleSheet.create({
    frame: {
      position: 'relative',
      marginTop: '20px',
      height: 522,
    },
    frameOverlay: {
      position: 'absolute',
      top: 0,
      left: -6,
      background: `url("${iphoneFrame}")`,
      width: 349,
      height: 572,
      pointerEvents: 'none',
    },
  });
}
