import PropTypes from 'prop-types';
import React, {Component} from 'react';
import cx from 'classnames';

export default class TabList extends Component {
  render() {
    return (
      <ul
        className={cx('ReactTabs__TabList', this.props.className)}
        role="tablist"
      >
        {this.props.children}
      </ul>
    );
  }
}

TabList.displayName = 'TabList';

TabList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
