import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

import InputWrapper from '../InputWrapper/InputWrapper';

export default injectIntl(
  class FloatingLabel extends Component {
    static propTypes = {
      value: PropTypes.string,
      onChange: PropTypes.func,
      name: PropTypes.string,
      onValueChange: PropTypes.func,
      onBlur: PropTypes.func,
      label: PropTypes.string,
      touched: PropTypes.bool,
      error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      placeholder: PropTypes.string,
      type: PropTypes.string,
      updateMessageOnBlur: PropTypes.func,
      disabled: PropTypes.bool,
    };

    constructor(props) {
      super(props);
      this.state = {
        isShowLabel: !!props.value,
      };
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
      this.setState({
        isShowLabel: !!nextProps.value,
      });
    };

    onChangeHandler = () => {
      let inputValue = this._input.value;
      if (inputValue) {
        this.setState({
          isShowLabel: true,
        });
      }
      if (!inputValue) {
        this.setState({
          isShowLabel: false,
        });
      }
      if (this.props.onChange) {
        this.props.onChange(inputValue);
      }
      // for live update in preview
      if (this.props.onValueChange) {
        this.props.onValueChange(this.props.name, inputValue);
      }
    };

    _onBlur = () => {
      if (this.props.onBlur) this.props.onBlur();
      if (this.props.updateMessageOnBlur) {
        let inputValue = this._input.value;
        this.props.updateMessageOnBlur(this.props.name, inputValue);
      }
    };

    render() {
      const {
        value = '',
        label,
        type,
        placeholder,
        disabled,
        onValueChange, // eslint-disable-line no-unused-vars
        updateMessageOnBlur, // eslint-disable-line no-unused-vars
        ...rest
      } = this.props;

      const {styles} = FloatingLabel;
      return (
        <InputWrapper>
          <div style={styles.control}>
            <div style={styles.labelAndError}>
              <div>
                <label
                  style={
                    this.state.isShowLabel ? styles.labelShow : styles.labelHide
                  }
                >
                  {label}
                </label>
              </div>
            </div>
            {type === 'textArea' ? (
              <textarea
                ref={(i) => {
                  this._input = i;
                }}
                style={styles.textArea}
                {...rest}
                value={value}
                onChange={this.onChangeHandler}
                onBlur={this._onBlur}
                placeholder={placeholder}
                disabled={disabled}
              />
            ) : (
              <input
                style={styles.input}
                ref={(i) => {
                  this._input = i;
                }}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
                value={value}
                onBlur={this._onBlur}
                onChange={this.onChangeHandler}
              />
            )}
          </div>
        </InputWrapper>
      );
    }

    static styles = {
      labelHide: {
        position: 'absolute',
        top: '23px',
        opacity: 0,
        fontSize: '14px',
        fontWeight: 600,
        color: '#666666',
      },
      labelShow: {
        position: 'absolute',
        top: '5px',
        opacity: 1,
        fontSize: '14px',
        fontWeight: 600,
        color: '#666666',
        transition: 'top 0.5s',
      },
      control: {
        position: 'relative',
        width: '100%',
        paddingTop: '24px',
        marginBottom: '10px',
      },
      input: {
        display: 'block',
        width: '100%',
        border: 0,
        outline: 0,
        resize: 'none',
        borderBottom: '1px solid #ddd',
        paddingBottom: '10px',
        fontSize: '16px',
      },
      error: {
        position: 'absolute',
        fontSize: 12,
        color: '#ff4a51',
        top: '5px',
        width: '100%',
      },
      labelAndError: {
        display: 'flex',
        pointerEvents: 'none',
        justifyContent: 'space-between',
        width: '87%',
      },
      textArea: {
        width: '100%',
        resize: 'none',
        border: 0,
        borderBottom: '1px solid #ddd',
        outline: 0,
        fontSize: '16px',
        marginBottom: '15px',
      },
    };
  }
);
