import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {asyncConnect} from 'redux-connect';
import {connect} from 'react-redux';
import {css, StyleSheet} from 'aphrodite';
import {injectIntl} from 'react-intl';
import validate from './validate';
import terms from '../../helpers/terms';
import {
  createUser,
  deselectUser,
  getUsers,
  selectUser,
  updateUser,
  updateUserLocally,
  uploadBackgroundImage,
  uploadProfilePicture,
} from '../../redux/modules/users';
import {
  Checkbox,
  FloatingLabel,
  Select,
  UploadButton,
  UpsertButton,
} from '../../helpers/formComponents';
import Form from '../../components/Form/Form';
import _ from 'lodash';
import {showErrors} from '../../helpers/errors';

function mapStateToProps(state) {
  const {webConsoleType, subBrands} = state.brand;
  return {
    user: state.users.current,
    webConsoleType,
    subBrands,
    segments: state.segments.segments,
  };
}

export default asyncConnect([
  {
    key: 'load',
    promise: async (helpers) => {
      if (helpers.match.params.id) {
        await helpers.store.dispatch(getUsers());
        await helpers.store.dispatch(selectUser(helpers.match.params.id));
        const state = helpers.store.getState();
        return await helpers.store.dispatch(
          updateUserLocally(state.users.current)
        );
      } else {
        return helpers.store.dispatch(deselectUser());
      }
    },
  },
])(
  injectIntl(
    connect(mapStateToProps)(
      class UserDetails extends Component {
        constructor(props) {
          super(props);
          this.state = {loading: false};
        }

        static propTypes = {
          users: PropTypes.array,
          dispatch: PropTypes.func,
          user: PropTypes.object,
          values: PropTypes.object,
        };

        _renderSubBrandFields = (fieldName) => {
          const {subBrands, segments, user, intl} = this.props;

          const {styles} = UserDetails;
          const brandShortName = fieldName.replace('segment_', '');
          const subBrandIds = Object.keys(subBrands);
          const brandId = subBrandIds.find(
            (subBrandId) => subBrands[subBrandId].shortName === brandShortName
          );
          const data = user[fieldName];
          let options = segments
            .filter(
              (item) => item.belongsToBrand === brandId && item.type === 'sales'
            )
            .map((item) => ({id: item.id, label: item.name}));
          options.unshift({
            id: undefined,
            label: intl.formatMessage(terms.notConnected),
          });

          return (
            <div className={css(styles.subBrandField)} key={fieldName}>
              <Select
                local={true}
                placeholder={intl.formatMessage(terms.segmentPlaceholder)}
                label={subBrands[brandId].name}
                alwaysShowLabel={true}
                multiple={false}
                value={data}
                required={false}
                onChange={this._onChange(fieldName)}
                options={options}
                name={fieldName}
              />
            </div>
          );
        };

        _onChange = (field) => (value) => {
          this._onValueChange(field, value);
        };

        _saveMessage = async () => {
          this.setState({loading: true});
          const {webConsoleType, user, dispatch} = this.props;
          if (webConsoleType === 'COUNTRY_BRAND_WEB_CONSOLE' && !user.isAdmin) {
            const segments = [];
            for (const field in user) {
              if (field.startsWith('segment_')) {
                const valueForField = user[field];
                if (valueForField) segments.push(valueForField);
              }
            }
            user.segment = segments;
          }

          if (user) {
            const userId = user.id;
            await dispatch(updateUser(userId, user));
            // await dispatch(selectUser(userId));
            this.setState({loading: false});
          } else {
            try {
              const user = await dispatch(createUser(user));

              // There is a delay on auth0 processing the created user.
              //  without the timeout auth0 does not return the new user.
              await new Promise((resolve) => {
                setTimeout(resolve, 2000);
              });

              this.props.history.push(`/users/edit/${user.user_id}`);

              this.setState({loading: false});
            } catch (e) {
              console.log('Error while saving', e);
            }
          }
        };

        _submit = () => {
          const errors = validate(this.props.user);
          if (_.isEmpty(errors)) {
            this._saveMessage();
          } else {
            showErrors(errors);
          }
        };

        _onDrop = (files) => {
          const {dispatch} = this.props;
          this.setState({loading: true}, () => {
            dispatch(uploadProfilePicture(this.props.user, files[0])).then(
              () => {
                this.setState({
                  picturePreview: files[0].preview,
                  pictureName: files[0].name,
                  loading: false,
                });
              }
            );
          });
        };

        _onDropBackgroundImage = (files) => {
          const {dispatch} = this.props;
          this.setState({loading: true}, () => {
            dispatch(uploadBackgroundImage(this.props.user, files[0])).then(
              () => {
                this.setState({
                  backgroundImagePreview: files[0].preview,
                  backgroundImagePictureName: files[0].name,
                  loading: false,
                });
              }
            );
          });
        };

        _onValueChange = (key, value) => {
          let user = {...this.props.user};
          user[key] = value;
          this.props.dispatch(updateUserLocally(user));
        };

        render() {
          const {
            intl,
            user: {
              name = '',
              email = '',
              picture = '',
              segment,
              role,
              phone = '',
              backgroundImage = '',
              isAdmin = false,
            },
            user,
            webConsoleType,
            match: {params},
          } = this.props;
          const {
            pictureName,
            picturePreview,
            backgroundImagePreview,
            backgroundImageName,
            loading,
          } = this.state;
          const {styles} = UserDetails;
          const profilePicture = picturePreview ? picturePreview : picture;
          const backgroundImageValue = backgroundImagePreview
            ? backgroundImagePreview
            : backgroundImage;

          const subBrandFields = {};
          Object.keys(user).map((field) => {
            if (field.startsWith('segment_')) {
              subBrandFields[field] = user[field];
            }
            return null;
          });
          const isEdit = params && params.id && user && user.id;

          return (
            <div>
              <Form style={styles.form}>
                <FloatingLabel
                  label={intl.formatMessage(terms.email)}
                  type="text"
                  placeholder={intl.formatMessage(terms.emailPlaceholder)}
                  autoFocus={!user}
                  value={email}
                  onValueChange={this._onValueChange}
                  name="email"
                />
                <FloatingLabel
                  label={intl.formatMessage(terms.name)}
                  type="text"
                  placeholder={intl.formatMessage(terms.namePlaceholder)}
                  autoFocus={!user}
                  value={name}
                  onValueChange={this._onValueChange}
                  name="name"
                />
                <FloatingLabel
                  label={intl.formatMessage(terms.phonenumber)}
                  type="text"
                  placeholder={intl.formatMessage(terms.phonenumberPlaceholder)}
                  autoFocus={!user}
                  value={phone}
                  onValueChange={this._onValueChange}
                  name="phone"
                />
                <Select
                  local={true}
                  placeholder={intl.formatMessage(terms.rolePlaceholder)}
                  label={intl.formatMessage(terms.role)}
                  multiple={false}
                  value={role}
                  required={true}
                  onChange={this._onChange('role')}
                  options={[
                    {id: 'sales', label: 'sales'},
                    {id: 'marketing', label: 'marketing'},
                  ]}
                  name="role"
                />
                <div className={css(styles.wrapperCheckbox)}>
                  <Checkbox
                    isChecked={isAdmin}
                    onChange={this._onChange('isAdmin')}
                    label={intl.formatMessage(terms.administrator)}
                  />
                </div>

                {webConsoleType === 'COUNTRY_BRAND_WEB_CONSOLE' &&
                role === 'sales' ? (
                  <div className={css(styles.subBrandContainer)}>
                    {Object.keys(subBrandFields).map(
                      this._renderSubBrandFields
                    )}
                  </div>
                ) : (
                  <Select
                    local={false}
                    placeholder={intl.formatMessage(terms.segmentPlaceholder)}
                    label={intl.formatMessage(terms.segment)}
                    multiple={false}
                    transformInitialValue={true}
                    value={segment}
                    required={true}
                    onChange={this._onChange('segment')}
                    resource="segments"
                    complete={true}
                    labelKey="name"
                    name="segments"
                  />
                )}

                {isEdit && (
                  <div className={css(styles.previewWrapper)}>
                    {profilePicture ? (
                      <img
                        src={profilePicture}
                        alt={name}
                        className={css(styles.preview)}
                      />
                    ) : (
                      <div className={css(styles.noImage)}>
                        {intl.formatMessage(terms.noImage)}
                      </div>
                    )}

                    <UploadButton
                      uploadCss={styles.upload}
                      onDrop={this._onDrop}
                      multiple={false}
                      label={
                        picture
                          ? intl.formatMessage(
                              terms.changeProfilePictureButtonLabel
                            )
                          : intl.formatMessage(
                              terms.uploadProfilePictureButtonLabel
                            )
                      }
                      assetsName={pictureName}
                    />
                  </div>
                )}

                {isEdit && (
                  <div className={css(styles.previewWrapper)}>
                    {backgroundImageValue ? (
                      <img
                        src={backgroundImageValue}
                        alt={name}
                        className={css(styles.previewBackgroundImage)}
                      />
                    ) : (
                      <div className={css(styles.noBackgroundImage)}>
                        {intl.formatMessage(terms.noImage)}
                      </div>
                    )}
                    <UploadButton
                      uploadCss={styles.upload}
                      onDrop={this._onDropBackgroundImage}
                      multiple={false}
                      label={
                        backgroundImage
                          ? intl.formatMessage(
                              terms.changeBackgroundImageButtonLabel
                            )
                          : intl.formatMessage(
                              terms.uploadBackgroundImageButtonLabel
                            )
                      }
                      assetsName={backgroundImageName}
                    />
                  </div>
                )}
                <UpsertButton
                  wrapperStyle={styles.button}
                  onClick={this._submit}
                  value={isEdit}
                  loading={loading}
                />
              </Form>
            </div>
          );
        }

        static styles = StyleSheet.create({
          form: {
            marginTop: 50,
            width: 380,
          },
          previewWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 20,
          },
          preview: {
            width: 80,
            height: 80,
            borderRadius: 40,
            objectFit: 'cover',
          },
          wrapperCheckbox: {
            marginTop: '20px',
            marginBottom: '20px',
          },
          previewBackgroundImage: {
            width: 80,
            height: 80,
            objectFit: 'cover',
          },
          noImage: {
            fontSize: 11,
            width: 80,
            height: 80,
            backgroundColor: '#ccc',
            borderRadius: 40,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          },
          noBackgroundImage: {
            fontSize: 11,
            width: 80,
            height: 80,
            backgroundColor: '#ccc',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          },
          upload: {
            minWidth: 82,
          },
          control: {
            position: 'relative',
            width: '100%',
            borderBottom: '1px solid #ddd',
            paddingBottom: '10px',
            marginBottom: '20px',
            backgroundColor: 'rgba(211, 211, 211, 0.26)',
          },
          button: {
            width: '100%',
          },
          subBrandContainer: {
            padding: 20,
            marginTop: 20,
            border: '1px solid #ddd',
          },
          subBrandField: {
            marginTop: 15,
          },
        });
      }
    )
  )
);
