import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {StyleSheet, css} from 'aphrodite';

const HEIGHT = 32;
const WIDTH = 216;
const ICON_SIZE = 12;
const ICON_MARGIN = 16;

import MORE_DOTS from '../../../assets/images/rpb/moreDots.png';

export default class CtaHeader extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    containerColor: PropTypes.string,
  };

  render() {
    const {label, onClick, color, labelBackgroundColor} = this.props;
    const {styles} = CtaHeader;

    const expandButtonStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: HEIGHT,
      width: ICON_SIZE + ICON_MARGIN * 2,
      background: color,
    };

    const customLabelContainer = StyleSheet.create({
      style: {background: labelBackgroundColor},
    }).style;

    return (
      <div className={css(styles.container)}>
        <div className={css(styles.labelContainer, customLabelContainer)}>
          <div className={css(styles.label)}>{label}</div>
        </div>
        <div style={expandButtonStyle} onClick={onClick}>
          <img src={MORE_DOTS} width={20} alt="More ctas" />
        </div>
      </div>
    );
  }

  static styles = StyleSheet.create({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      bottom: 41,
      left: 52,
      width: WIDTH,
      height: HEIGHT,
      flexDirection: 'row',
      background: 'linear-gradient(to right, #84bce3, #244291)',
      margin: '0px 8px',
      borderRadius: '16px',
    },
    labelContainer: {
      display: 'flex',
      flex: 1,
      backgroundColor: '#000',
      alignItems: 'center',
    },
    label: {
      fontFamily: 'BentonSans-Bold',
      paddingLeft: 16,
      fontSize: 8,
      letterSpacing: 0.7,
      color: 'white',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    iconContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#000',
      height: HEIGHT,
      width: ICON_SIZE + ICON_MARGIN * 2,
    },
  });
}
