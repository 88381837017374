import routes from '../../helpers/routes';

const ACTION_LOAD_CATEGORIES = 'LOAD_CATEGORIES';
const ACTION_SELECT_CATEGORY = 'SELECT_CATEGORY';
const ACTION_UPDATE_CATEGORY = 'UPDATE_CATEGORY';

export function reducer(
  state = {
    current: {},
  },
  action
) {
  switch (action.type) {
    case ACTION_LOAD_CATEGORIES:
      return {
        ...state,
        categories: action.data,
      };
    case ACTION_SELECT_CATEGORY:
      return {
        ...state,
        current: action.data,
      };
    case ACTION_UPDATE_CATEGORY:
      return {
        ...state,
        current: action.data,
      };
    default:
      return state;
  }
}

export function getCategories() {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .get(`${routes.categories}`)
          .then((categories) =>
            dispatch({type: ACTION_LOAD_CATEGORIES, data: categories})
          ),
    });
  };
}

export function createCategory(values) {
  return (dispatch) => {
    return dispatch({
      promise: (client) => client.post(`${routes.categories}`, values),
    });
  };
}

export function deleteCategory(category) {
  return (dispatch, getState) => {
    return dispatch({
      promise: (http) =>
        http.delete(`${routes.categories}/${category.id}`).then(() =>
          dispatch({
            type: ACTION_LOAD_CATEGORIES,
            data: getState().categories.categories.filter(
              (cl) => cl.id !== category.id
            ),
          })
        ),
    });
  };
}

export function selectCategory(id) {
  return (dispatch) => {
    return dispatch({
      promise: (http) =>
        http.get(`${routes.categories}/${id}`).then((category) =>
          dispatch({
            type: ACTION_SELECT_CATEGORY,
            data: category,
          })
        ),
    });
  };
}

export function updateCategory(values) {
  return (dispatch, getState) => {
    return dispatch({
      promise: (category) =>
        category
          .put(
            `${routes.categories}/${getState().categories.current.id}`,
            values
          )
          .then((updatedCategory) =>
            dispatch({type: ACTION_UPDATE_CATEGORY, data: updatedCategory})
          )
          .catch((err) => console.log(err)),
    });
  };
}

export const updateCategoryLocally = (category) => {
  return (dispatch) => {
    return dispatch({
      type: ACTION_UPDATE_CATEGORY,
      data: category,
    });
  };
};

export function uploadCategoryIcon(category, icon) {
  const data = new FormData();
  data.append('file', icon, 'icon');
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .post(`${routes.categories}/${category.id}/upload-icon`, data)
          .then((category) => {
            dispatch({
              type: ACTION_UPDATE_CATEGORY,
              data: category,
            });
            return dispatch({type: ACTION_SELECT_CATEGORY, data: category});
          }),
    });
  };
}
