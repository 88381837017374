import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Modal} from 'antd';
import {connect} from 'react-redux';
import {asyncConnect} from 'redux-connect';
import SegmentForm from './SegmentForm';
import AddClient from './AddClient';
import ClientsInSegment from '../../components/ClientsInSegment/ClientsInSegment';
import {
  addClientToSegment,
  createSegment,
  deselectSegment,
  downloadNewClients,
  getClientsForSegment,
  loadAllSegments,
  loadSegment,
  saveSegment,
  selectSegment,
} from '../../redux/modules/segments';
import {defineMessages, injectIntl} from 'react-intl';
import terms from '../../helpers/terms';
import {V2_BRANDS} from '../../config/brands';
import {SUB_BRAND_WEB_CONSOLE} from '../../redux/modules/brand';

class SegmentDetail extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    segment: PropTypes.object,
    segments: PropTypes.array,
    form: PropTypes.object,
    brandId: PropTypes.string,
    subBrands: PropTypes.object,
    webConsoleType: PropTypes.string,
    isV2: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      addingClient: false,
      savingName: false,
      fileName: null,
    };
  }

  _onSaveSegment = (data) => {
    this.setState({savingName: true}, () => {
      if (
        this.props.segment &&
        this.props.segment.id &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.id
      ) {
        this.props.dispatch(saveSegment(this.props.segment, data)).then(() => {
          this.setState({savingName: false});
        });
      } else {
        this.props
          .dispatch(createSegment(data))
          .then((segment) =>
            this.props.history.push(`/segments/edit/${segment.id}`)
          );
      }
    });
  };

  _addClient = (data) => {
    this.setState(
      {
        addingClient: true,
      },
      () => {
        this.props
          .dispatch(
            addClientToSegment(this.props.segment, {
              id: data.clientId,
              name: data.name,
              emailId: data.emailId,
            })
          )
          .then(() => {
            this.setState({
              addingClient: false,
            });
            this._showClientAddedConfirmation();
          })
          .catch((e) => {
            this.setState(
              this._makeClientFailedState(e.response || e),
              this._showClientAddedFailure
            );
          });
      }
    );
  };

  _makeClientFailedState(e) {
    const messages = this._defineMessages();
    if (e.data) {
      switch (e.data.errorType) {
        case 'CantAddClientToSegmentInSubbrand':
          return {
            label: messages.clientNotAddedModalLabel.defaultMessage,
            description: messages.clientNotAddedModalMessage.defaultMessage,
            addingClient: false,
          };
        case 'CantAddClientToSegmentDoesNotFollowBrand':
          return {
            label: messages.clientNotAddedModalLabel.defaultMessage,
            description:
              messages.clientNotAddedFollowedBrandModalMessage.defaultMessage,
            addingClient: false,
          };
        default:
          return {
            label: messages.generalErrorLabel.defaultMessage,
            description: messages.generalErrorMessage.defaultMessage,
            addingClient: false,
          };
      }
    }
    return {
      label: messages.generalErrorLabel.defaultMessage,
      description: messages.generalErrorMessage.defaultMessage,
      addingClient: false,
    };
  }

  _showClientAddedConfirmation = () => {
    Modal.info({
      title: this.props.intl.formatMessage(this._getModalMessage().title),
      content: this.props.intl.formatMessage(this._getModalMessage().text),
      onOk: this._confirmClientAdded,
      okText: this.props.intl.formatMessage(terms.ok),
    });
  };

  _showClientAddedFailure = () => {
    Modal.error({
      title: this.state.label,
      content: this.state.description,
      okText: this.props.intl.formatMessage(terms.ok),
    });
  };

  _getModalMessage = () => {
    const {hasPassword, segment} = this.props;
    const messages = this._defineMessages();

    if (hasPassword) {
      // For when excel upload is done
      return segment.passwordDownload.length > 0
        ? {
            title: messages.newClientAddedModalTitle,
            text: messages.newClientAddedModalText,
          }
        : {
            title: messages.existingClientAddedModalTitle,
            text: messages.existingClientAddedModalText,
          };
    } else {
      return segment.queryType === 'created'
        ? {
            title: messages.newClientAddedModalTitle,
            text: messages.newClientAddedNoPasswordModalText,
          }
        : {
            title: messages.existingClientAddedModalTitle,
            text: messages.existingClientAddedNoPasswordModalText,
          };
    }
  };

  _confirmClientAdded = () => {
    this.props.dispatch(
      downloadNewClients(
        `${this.props.segment.name}_${this.state.newClientName}`
      )
    );
    this.setState({
      newClientName: undefined,
    });
  };

  _defineMessages() {
    return defineMessages({
      newClientAddedModalTitle: {
        id: 'SegmentDetail.newClientAddedModalTitle',
        defaultMessage: 'New client added',
        description:
          'The header on the modal that pops up of a new client is added to a target group.',
      },
      newClientAddedModalText: {
        id: 'SegmentDetail.newClientAddedModalText',
        defaultMessage:
          'A new client has been added to the target group. After clicking ok, you can download the password for this client.',
        description:
          'The feedback message on the modal that pops up of a new client is added to a target group.',
      },
      newClientAddedNoPasswordModalText: {
        id: 'SegmentDetail.newClientAddedNoPasswordModalText',
        defaultMessage: 'A new client has been added to the target group.',
        description:
          'The feedback message on the modal that pops up of a new client is added to a target group.',
      },
      existingClientAddedModalTitle: {
        id: 'SegmentDetail.existingClientAddedModalTitle',
        defaultMessage: 'Existing client added',
        description:
          'The header on the modal that pops up of a existing client is added to a target group.',
      },
      existingClientAddedModalText: {
        id: 'SegmentDetail.existingClientAddedModalText',
        defaultMessage:
          'The client has been added to the target group. Since the client already exists, no new password was generated.',
        description:
          'The feedback message on the modal that pops up of a existing client is added to a target group.',
      },
      existingClientAddedNoPasswordModalText: {
        id: 'SegmentDetail.existingClientAddedNoPasswordModalText',
        defaultMessage: 'The client has been added to the target group.',
        description:
          'The feedback message on the modal that pops up of a existing client is added to a target group.',
      },
      clientNotAddedModalLabel: {
        id: 'AddClient.clientsNotAddedFeedback',
        defaultMessage: 'Client could not be added',
        description:
          'The header on the modal that pop ups when a non existing client is being added',
      },
      clientNotAddedModalMessage: {
        id: 'AddClient.clientsNotAddedFeedback',
        defaultMessage:
          'In a generic target group its not possible to add a non-existing client. Please inform your data manager to create this particular client.',
        description:
          'A feedback message which is displayed after uploading a client excel sheet. It gives feedback for the user that the client could not be added',
      },
      clientNotAddedFollowedBrandModalMessage: {
        id: 'AddClient.clientsNotAddedFollowedBrandFeedback',
        defaultMessage:
          "Can't add client that does not follow this brand. Please inform your data manager to add this particular client.",
        description:
          'A feedback message which is displayed after uploading a single client. It gives feedback for the user that the client could not be added when not following that brand.',
      },
      generalErrorLabel: {
        id: 'Error.generalErrorLabel',
        description:
          'This error label will show if something went wrong trying to add a single client',
        defaultMessage: 'Something went wrong',
      },
      generalErrorMessage: {
        id: 'Error.generalErrorMessage',
        description:
          'This error message will show if something went wrong trying to add a single client',
        defaultMessage:
          'An error seemed to occur. Please send a ticket to support.',
      },
    });
  }

  render() {
    const {
      segment,
      dispatch,
      subBrands,
      webConsoleType,
      brandId,
      segments,
      isV2,
      match: {params},
    } = this.props;
    const {savingName, addingClient} = this.state;

    const viewMode = this.props.route.path.includes('view');
    const isEdit = params && params.id && segment && segment.id;

    return (
      <div>
        <SegmentForm
          brandId={brandId}
          savingName={savingName}
          onSubmit={this._onSaveSegment}
          segment={segment}
          segments={segments}
          viewMode={viewMode}
          subBrands={subBrands}
          webConsoleType={webConsoleType}
          dispatch={dispatch}
          isEdit={isEdit}
        />
        {segment && segment.id && (
          <div>
            <AddClient
              shouldRender={!viewMode}
              replaceDisabled={
                segment.type === 'all' && !segment.belongsToBrand
              }
              onDrop={this._onDrop}
              segment={segment}
              addingClient={addingClient}
              hasPassword={this.props.hasPassword}
              submit={this._addClient}
              isV2={isV2}
              dispatch={dispatch}
            />
            <ClientsInSegment
              dispatch={dispatch}
              segment={segment}
              viewMode={viewMode}
            />
          </div>
        )}
      </div>
    );
  }
}

export default asyncConnect([
  {
    key: 'load',
    promise: async (helpers) => {
      if (helpers.match.params.id) {
        return Promise.all([
          helpers.store
            .dispatch(loadSegment(helpers.match.params.id))
            .then((segment) => {
              helpers.store.dispatch(getClientsForSegment(segment));
            }),
          helpers.store.dispatch(loadAllSegments()),
        ]);
      } else {
        await helpers.store.dispatch(deselectSegment());
        await helpers.store.dispatch(loadAllSegments());
        const state = helpers.store.getState();
        const segment = state.segments.currentSegment;
        const webConsoleType = state.brand.webConsoleType;
        const createMode = helpers.route.path.includes('create');

        // if creating new segment
        if (webConsoleType === SUB_BRAND_WEB_CONSOLE && createMode) {
          segment.type = 'generic';
          await helpers.store.dispatch(selectSegment(segment));
        }
        return Promise.resolve();
      }
    },
  },
])(
  connect((state) => {
    return {
      segments: state.segments.segments,
      segment: state.segments.currentSegment,
      brandId: state.brand.id,
      subBrands: state.brand.subBrands,
      webConsoleType: state.brand.webConsoleType,
      hasPassword: state.brand.features.hasPassword,
      isV2: V2_BRANDS.indexOf(state.brand.id) > -1,
    };
  })(injectIntl(SegmentDetail))
);
