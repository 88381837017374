import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {StyleSheet} from 'aphrodite';
import {defineMessages, injectIntl} from 'react-intl';

import Form from '../../components/Form/Form';
import validate from './AddGroupValidation';
import {
  Button,
  Select,
  FloatingLabel,
  UploadButton,
} from '../../helpers/formComponents';
import terms from '../../helpers/terms';
import {uploadCtaIcsFile} from '../../redux/modules/ctas';
import _ from 'lodash';
import {showErrors} from '../../helpers/errors';

class AddCTAGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      icsFileName: null,
      submitting: false,
      group: this._getDefaulGroup(),
    };
  }

  _getDefaulGroup = () => {
    return {
      segmentGroupId: '',
      label: '',
      value: '',
      message: '',
    };
  };

  static propTypes = {
    possibleSegments: PropTypes.array,
    dispatch: PropTypes.func,
    segment: PropTypes.object,
    onAddCtaGroup: PropTypes.func,
  };

  _onValueChange = (key, value) => {
    this.setState({
      group: {
        ...this.state.group,
        [key]: value,
      },
    });
  };

  _onChange = (field) => (value) => {
    this._onValueChange(field, value);
  };

  // TODO probably can improve this by moving this logic to onAddCtaGroup in CTASDetail, because it looks terrible here because of #!$@#* redux-forms
  _submit = () => {
    const errors = validate(this.state.group);
    if (_.isEmpty(errors)) {
      this.setState({submitting: true}, () => {
        const promiseOrUndefined = this.props.onAddCtaGroup(this.state.group);
        if (promiseOrUndefined) {
          promiseOrUndefined.then(() => {
            this.setState({submitting: false, group: this._getDefaulGroup()});
          });
        } else {
          this.setState({submitting: false, group: this._getDefaulGroup()});
        }
      });
    } else {
      showErrors(errors);
    }
  };

  _onDrop = async (files) => {
    try {
      const data = await this.props.dispatch(uploadCtaIcsFile(files[0]));
      this._onChange('value')(data.url);
      this.setState({icsFileName: files[0].name});
    } catch (e) {
      alert('Error uploading file');
    }
  };

  render() {
    const {type} = this.props;
    const {segmentGroupId, label, value, message} = this.state.group;
    const {styles} = AddCTAGroup;

    const messages = defineMessages({
      targetGroupNamePlaceholder: {
        id: 'AddCTAGroup.targetGroupNamePlaceholder',
        defaultMessage: 'Select Target Group to reach',
        description:
          'The placeholder in the target group selector (if the cta is of type group, this will this field will select the target group the cta is aimed at)',
      },
      labelPlaceholder: {
        id: 'AddCTAGroup.labelPlaceholder',
        defaultMessage: 'CTA label for the selected Target Group',
        description: 'The placeholder of the label input field ',
      },
      labelLabel: {
        id: 'AddCTAGroup.labelLabel',
        defaultMessage: 'Label of the CTA',
        description:
          'The label above the CTA label input field, when CTA is of the type group',
      },
      valuePlaceholder: {
        id: 'AddCTAGroup.valuePlaceholder',
        defaultMessage: 'CTA value for the selected Target Group',
        description: 'The placeholder of the cta  ',
      },
      valueLabel: {
        id: 'AddCTAGroup.valueLabel',
        defaultMessage: 'Value of the CTA Label',
        description:
          'The label above the CTA value field, when CTA is of the type group',
      },
      messagePlaceholder: {
        id: 'AddCTAGroup.messagePlaceholder',
        defaultMessage: 'CTA message for the selected Target Group',
        description: 'The placeholder of the cta message',
      },
      messageLabel: {
        id: 'AddCTAGroup.messageLabel',
        defaultMessage: 'Value of the CTA Message',
        description:
          'The message above the CTA value field, when CTA is of the type whatsapp',
      },
      uploadIcsFileButtonLabel: {
        id: 'CTAForm.uploadIcsFileButtonLabel',
        defaultMessage: 'Upload .ics File',
        description:
          'The label on the button which uploads an .ics file for a new cta',
      },
    });

    // TODO when a new cta group is added to the CTA, the second and third field remain filld in with the values of that group, Is this a bug or a feature?
    return (
      <div>
        <Form style={styles.form}>
          <Select
            local={true}
            placeholder={this.props.intl.formatMessage(
              messages.targetGroupNamePlaceholder
            )}
            label={this.props.intl.formatMessage(terms.segment)}
            getOptionLabel={(option) => option.name}
            onChange={this._onChange('segmentGroupId')}
            options={this.props.possibleSegments}
            multiple={false}
            value={segmentGroupId}
            required={true}
            name="segmentGroupId"
          />
          <FloatingLabel
            label={this.props.intl.formatMessage(messages.labelLabel)}
            type="text"
            placeholder={this.props.intl.formatMessage(
              messages.labelPlaceholder
            )}
            onValueChange={this._onValueChange}
            name="label"
            value={label}
          />
          <FloatingLabel
            label={this.props.intl.formatMessage(messages.valueLabel)}
            placeholder={this.props.intl.formatMessage(
              messages.valuePlaceholder
            )}
            type="text"
            disabled={type === 'calendar'}
            onValueChange={this._onValueChange}
            name="value"
            value={value}
          />
          {type === 'calendar' && (
            <UploadButton
              onDrop={this._onDrop}
              multiple={false}
              label={this.props.intl.formatMessage(
                messages.uploadIcsFileButtonLabel
              )}
              assetsName={this.state.icsFileName}
            />
          )}
          {type === 'whatsapp' && (
            <FloatingLabel
              label={this.props.intl.formatMessage(messages.messageLabel)}
              placeholder={this.props.intl.formatMessage(
                messages.messagePlaceholder
              )}
              type="text"
              onValueChange={this._onValueChange}
              name="message"
              value={message}
            />
          )}
          <Button
            onClick={this._submit}
            wrapperStyle={styles.button}
            loading={this.state.submitting}
          >
            {this.props.intl.formatMessage(terms.add)}
          </Button>
        </Form>
      </div>
    );
  }

  static styles = StyleSheet.create({
    form: {
      width: 380,
    },
    button: {
      width: '100%',
    },
  });
}

export default injectIntl(AddCTAGroup);
