import routes from '../../helpers/routes';
const ACTION_LOAD_CONVERSATIONS = 'LOAD_CONVERSATIONS';
const ACTION_SELECT_CONVERSATION = 'SELECT_CONVERSATION';
const ACTION_LOAD_CHATS = 'LOAD_CHATS';

export function reducer(state = {}, action) {
  switch (action.type) {
    case ACTION_LOAD_CONVERSATIONS:
      return {
        ...state,
        loading: false,
        conversations: action.data,
      };
    case ACTION_SELECT_CONVERSATION:
      return {
        ...state,
        current: action.data,
        loading: true,
      };
    case ACTION_LOAD_CHATS:
      return {
        ...state,
        current: action.data,
        loading: false,
      };
    default:
      return state;
  }
}

export function getConversations() {
  return (dispatch) => {
    return dispatch({
      promise: (client) =>
        client
          .get(`${routes.conversations}`)
          .then((conversations) =>
            dispatch({type: ACTION_LOAD_CONVERSATIONS, data: conversations})
          ),
    });
  };
}

export function selectConversation(selectedConversation) {
  return (dispatch) => {
    // Load the chats
    dispatch(loadChats(selectedConversation));
    return dispatch({
      type: ACTION_SELECT_CONVERSATION,
      data: selectedConversation,
    });
  };
}

export function loadChats(selectedConversation) {
  const conversationId = makeConversationId(
    selectedConversation.brandId,
    selectedConversation.id
  );

  return (dispatch) => {
    return dispatch({
      promise: (http) =>
        http
          .get(`${routes.conversations}/${conversationId}`)
          .then((chatMessages) =>
            dispatch({
              type: ACTION_LOAD_CHATS,
              data: chatMessages,
            })
          )
          .catch((e) => {
            console.log('error loading chats', e);
          }),
    });
  };
}

// duplication from back-end
export function makeConversationId(brandId, clientId) {
  return `${brandId}-${clientId}`;
}
