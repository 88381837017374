import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Tab from '../../components/customTab/components/Tab';
import Tabs from '../../components/customTab/components/Tabs';
import TabList from '../../components/customTab/components/TabList';
import TabPanel from '../../components/customTab/components/TabPanel';
import {css, StyleSheet} from 'aphrodite';
import {FormattedMessage} from 'react-intl';
import MessageComposeForm from './MessageComposeForm';
import MessageComposeFormValidation from './MessageComposeFormValidation';
import MessageNotifyForm from './MessageNotifyForm';
import MessageNotifyFormValidation from './MessageNotifyFormValidation';
import MessagePublishForm from './MessagePublishForm';
import Spinner from '../../components/Spinner/Spinner';
import {saveMessage, uploadMessageImage} from '../../redux/modules/message';
import {isEmpty} from '../../helpers/validation';
import {showErrors} from '../../helpers/errors';

const TABS = {
  COMPOSE: 0,
  NOTIFY: 1,
  PUBLISH: 2,
};

export default class MessageFormTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      isUpdating: false,
      imageName: null,
      saved: false,
    };
  }

  static propTypes = {
    message: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
  };

  _validateComposeForm = (values) => {
    return MessageComposeFormValidation(values);
  };

  _validateNotifyForm = (values) => {
    return MessageNotifyFormValidation(values);
  };

  _getFormErrorsIfAny = () => {
    const {
      message: {
        title,
        description,
        ctas,
        categories,
        image,
        images,
        videoUrl,
        notificationHeadline,
      },
    } = this.props;
    const values = {
      title,
      description,
      ctas,
      categories,
      image,
      images,
      videoUrl,
      notificationHeadline,
    };
    const composeFormErrors = this._validateComposeForm(values);
    const notifyFormErrors = this._validateNotifyForm(values);

    return {
      composeFormErrors,
      notifyFormErrors,
    };
  };

  _getCompletedForm = (composeFormErrors, notifyFormErrors) => {
    const isComposeFormValid = isEmpty(composeFormErrors);
    const isNotifyFormValid = isEmpty(notifyFormErrors);

    if (isComposeFormValid && isNotifyFormValid) {
      return TABS.PUBLISH;
    } else if (isComposeFormValid) {
      return TABS.NOTIFY;
    }
    // no need to validate publish form
    return TABS.COMPOSE;
  };

  _validateAndChangeTab = (index, lastTab) => {
    const {composeFormErrors, notifyFormErrors} = this._getFormErrorsIfAny();

    const completedForm = this._getCompletedForm(
      composeFormErrors,
      notifyFormErrors
    );

    /******* 
    lastTab index completedForm   Status  
    0       1     0               Success 
    0       2     0               Fail
    0       0     0               Success 
    1       2     1               Success
    1       2     0               Fail 
    1       0     0/1             Success
    1       1     0/1             Success 
    0/1/2   0/1/2 2               Success 
    *******/

    if (completedForm === TABS.COMPOSE) {
      // If completed form is compose that means the user might have errors om composeform
      // Do not allow user to switch and show the error
      if (index !== TABS.COMPOSE) {
        showErrors(composeFormErrors);
        this.setState({
          selectedTab: completedForm,
        });
      }
    } else if (completedForm === TABS.NOTIFY) {
      // For a form which has notify complete - Allow switching only if user is switching to compose or notify
      if (index === TABS.COMPOSE || index === TABS.NOTIFY) {
        this.setState({selectedTab: index});
      } else {
        showErrors(notifyFormErrors);
        this.setState({
          selectedTab: completedForm,
        });
      }
    } else if (completedForm === TABS.PUBLISH) {
      // Set the tab to index is the form is completed
      this.setState({
        selectedTab: index,
      });
    }
  };

  _onDrop = (files) => {
    this.setState(
      {
        saved: false,
        isUpdating: true,
      },
      () => {
        this.props
          .dispatch(uploadMessageImage(this.props.message, files))
          .then(() => {
            this.setState({
              isUpdating: false,
              imageName: files[0].name,
              saved: true,
            });
            setTimeout(
              function () {
                this.setState({saved: false});
              }.bind(this),
              2000
            );
          });
      }
    );
  };

  _updateMessage = (key, value) => {
    let message = {};
    message[key] = value;
    message['id'] = this.props.message.id;
    this.setState(
      {
        saved: false,
        isUpdating: true,
      },
      () => {
        this.props.dispatch(saveMessage(message)).then(() => {
          this.setState({
            isUpdating: false,
            saved: true,
          });
          setTimeout(
            function () {
              this.setState({saved: false});
            }.bind(this),
            2000
          );
        });
      }
    );
  };

  render() {
    const {message, brand} = this.props;
    const {imageName, isUpdating, saved, selectedTab} = this.state;
    const {styles} = MessageFormTabs;
    return (
      <div className={css(styles.tabsforms)}>
        <div className={css(styles.savetext)}>
          {isUpdating && (
            <FormattedMessage
              id="MessageFormTabs.saving"
              description="The text to be shown while the changes to a message are in the process being saved"
              defaultMessage="saving.."
            />
          )}
        </div>
        <div className={css(styles.savetext)}>
          {saved && (
            <FormattedMessage
              id="MessageFormTabs.saved"
              description="The text to be shown when the changes to a message have been succesfully saved"
              defaultMessage="saved"
            />
          )}
        </div>
        <div className={css(styles.spinner)}>
          {isUpdating ? <Spinner /> : null}
        </div>
        <Tabs
          className={css(styles.tabs)}
          onSelect={this._validateAndChangeTab}
          selectedIndex={selectedTab}
        >
          <TabList>
            <Tab>
              <FormattedMessage
                id="MessageFormTabs.composeTabTitle"
                description="The title of the Compose tab in the message creation process"
                defaultMessage="Compose"
              />
            </Tab>
            <Tab>
              <FormattedMessage
                id="MessageFormTabs.notifyTabTitle"
                description="The title of the Notify tab in the message creation process"
                defaultMessage="Notify"
              />
            </Tab>
            <Tab>
              <FormattedMessage
                id="MessageFormTabs.publishTabTitle"
                description="The title of the Publish tab in the message creation process"
                defaultMessage="Publish"
              />
            </Tab>
          </TabList>
          <TabPanel>
            <MessageComposeForm
              brand={brand}
              message={message}
              onDrop={this._onDrop}
              imageName={imageName}
              updateMessage={this._updateMessage}
              onSubmit={this._validateAndChangeTab.bind(
                this,
                TABS.NOTIFY,
                TABS.COMPOSE
              )}
            />
          </TabPanel>
          <TabPanel>
            <MessageNotifyForm
              message={message}
              updateMessage={this._updateMessage}
              onSubmit={this._validateAndChangeTab.bind(
                this,
                TABS.PUBLISH,
                TABS.NOTIFY
              )}
              dispatch={this.props.dispatch}
            />
          </TabPanel>
          <TabPanel>
            <MessagePublishForm
              message={message}
              updateMessage={this._updateMessage}
              dispatch={this.props.dispatch}
            />
          </TabPanel>
        </Tabs>
      </div>
    );
  }

  static styles = StyleSheet.create({
    tabs: {
      marginTop: '5%',
      marginBottom: '200px',
    },
    tabsforms: {
      // width:'40%'
    },
    spinner: {
      top: '25%',
      position: 'absolute',
      left: '52%',
    },
    savetext: {
      top: '22%',
      position: 'absolute',
      left: '45%',
    },
    nextButton: {
      width: '100%',
    },
  });
}
