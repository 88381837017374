import React from 'react';
import {css, StyleSheet} from 'aphrodite';

import {Cell} from 'fixed-data-table-2';

export default function TextCell(props) {
  const styles = StyleSheet.create({
    cellContent: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      boxSizing: 'border-box',
      display: 'block',
      // paddingLeft: '8px',
      padding: '12px', // TODO center vertically in a dynamic way!
      width: '100%',
    },
    cellOverflow: {
      boxSizing: 'border-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  });

  return (
    <Cell {...props}>
      <div className={css(styles.cellContent)}>
        <div className={css(styles.cellOverflow)}>{props.children}</div>
      </div>
    </Cell>
  );
}
