import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {StyleSheet, css} from 'aphrodite';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {updateMessageLocally} from '../../redux/modules/message';
import {
  UploadButton,
  FloatingLabel,
  Select,
  Button,
} from '../../helpers/formComponents';
import Form from '../../components/Form/Form';
import terms from '../../helpers/terms';
import {SUB_BRAND_WEB_CONSOLE} from '../../redux/modules/brand';
import {V2_BRANDS} from '../../config/brands';

class MessageComposeForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.object,
    brand: PropTypes.object,
    updateMessage: PropTypes.func,
    onSubmit: PropTypes.func,
    imageName: PropTypes.string,
    onDrop: PropTypes.func,
    hasTagOnMessages: PropTypes.bool.isRequired,
  };

  _onValueChange = (key, value) => {
    let message = {...this.props.message};
    message[key] = value;

    this.props.dispatch(updateMessageLocally(message));
  };

  _onSelectChange = (key) => (value) => {
    this._onValueChange(key, value);
    this._onBlur(key, value);
  };

  _onBlur = (key, value) => {
    this.props.updateMessage(key, value);
  };

  _defineMessages() {
    return defineMessages({
      messageTitleLabel: {
        id: 'MessageComposeForm.messageTitleLabel',
        defaultMessage: 'Message Title',
        description: 'The label on the input field for the message title',
      },
      messageSubTitleLabel: {
        id: 'MessageComposeForm.messageSubTitleLabel',
        defaultMessage: 'Message SubTitle',
        description: 'The label on the input field for the message subtitle',
      },
      messageBrandLabel: {
        id: 'MessageComposeForm.messageBrandLabel',
        defaultMessage: 'Brand',
        description:
          'The label on the fixed input field of the brand of the message',
      },
      messageBodyLabel: {
        id: 'MessageComposeForm.messageBodyLabel',
        defaultMessage: 'Message Body',
        description: 'The label on the input field for the message body',
      },
      messageTitlePlaceholder: {
        id: 'MessageComposeForm.messageTitlePlaceholder',
        defaultMessage: 'Enter Message Title',
        description: 'The placeholder in the input field for the message title',
      },
      messageSubTitlePlaceholder: {
        id: 'MessageComposeForm.messageSubTitlePlaceholder',
        defaultMessage: 'Enter Message Sub Title',
        description:
          'The placeholder in the input field for the message subtitle',
      },
      messageBodyPlaceholder: {
        id: 'MessageComposeForm.messageBodyPlaceholder',
        defaultMessage: 'Add the body of your message',
        description: 'The placeholder in the input field for the message body',
      },
      categoryPlaceholder: {
        id: 'MessageComposeForm.categoryPlaceholder',
        defaultMessage: 'Select Category',
        description: 'The placeholder in the input field for the category',
      },
      callToActionsPlaceholder: {
        id: 'MessageComposeForm.callToActionsPlaceholder',
        defaultMessage: 'Select CTA',
        description:
          'The placeholder in the selector field for the call to actions',
      },
      tagsPlaceholder: {
        // used by L'Oreal Luxe for branding messages with an icon. Tags are initialized from the brand model.
        id: 'MessageComposeForm.tagsPlaceholder',
        defaultMessage: 'Select Tag',
        description: 'The placeholder in the selector field for the tags',
      },
      uploadMessageImageButtonLabel: {
        id: 'MessageComposeForm.uploadMessageImageButtonLabel',
        description:
          'The label on the button which uploads an image for a new message',
        defaultMessage: 'Upload Image',
      },
      changeMessageImageButtonLabel: {
        id: 'MessageComposeForm.changeMessageImageButtonLabel',
        description:
          'The label on the button which changes an image for a message',
        defaultMessage: 'Change Image',
      },
      messageVideoUrlLabel: {
        id: 'MessageComposeForm.messageVideoUrlLabel',
        defaultMessage: 'Video URL',
        description: 'The label on the input field for the video url',
      },
      messageVideoUrlPlaceholder: {
        id: 'MessageComposeForm.messageVideoUrlPlaceholder',
        defaultMessage: 'Enter Video Url',
        description: 'The placeholder in the input field for the video url',
      },
    });
  }

  filterOutAccountManagerCta = (ctas) =>
    ctas.filter(
      (item) => item.id !== this.props.brand.accountManager?.chatCTAId
    );

  render() {
    const {
      onSubmit,
      error,
      message,
      brand,
      imageName,
      intl,
      tagOptions,
      hasTagOnMessages,
      isV2,
    } = this.props;
    const {styles} = MessageComposeForm;

    const intlMessages = this._defineMessages();
    return (
      <Form style={styles.form}>
        {brand.webConsoleType === SUB_BRAND_WEB_CONSOLE && (
          <FloatingLabel
            label={intl.formatMessage(intlMessages.messageBrandLabel)}
            type="text"
            placeholder={intl.formatMessage(intlMessages.messageBrandLabel)}
            disabled={true}
            value={brand.name}
            name="name"
          />
        )}

        <FloatingLabel
          label={intl.formatMessage(intlMessages.messageTitleLabel)}
          type="text"
          placeholder={intl.formatMessage(intlMessages.messageTitlePlaceholder)}
          name="title"
          value={message.title}
          updateMessageOnBlur={this._onBlur}
          onValueChange={this._onValueChange}
          autoFocus={!message}
        />

        {brand.features.hasSubTitle && (
          <FloatingLabel
            label={intl.formatMessage(intlMessages.messageSubTitleLabel)}
            type="text"
            placeholder={intl.formatMessage(
              intlMessages.messageSubTitlePlaceholder
            )}
            value={message.subTitle}
            updateMessageOnBlur={this._onBlur}
            onValueChange={this._onValueChange}
            name="subTitle"
          />
        )}

        <FloatingLabel
          label={intl.formatMessage(intlMessages.messageBodyLabel)}
          type="textArea"
          value={message.description}
          placeholder={intl.formatMessage(intlMessages.messageBodyPlaceholder)}
          rows="4"
          updateMessageOnBlur={this._onBlur}
          onValueChange={this._onValueChange}
          name="description"
        />

        <Select
          local={false}
          placeholder={intl.formatMessage(intlMessages.categoryPlaceholder)}
          label={intl.formatMessage(terms.category)}
          multiple={true}
          value={message.categories}
          onChange={this._onSelectChange('categories')}
          resource="categories"
          labelKey="name"
          name="categories"
        />

        <br />

        <Select
          local={false}
          placeholder={intl.formatMessage(
            intlMessages.callToActionsPlaceholder
          )}
          label={intl.formatMessage(terms.callToActions)}
          multiple={true}
          value={message.ctas}
          onChange={this._onSelectChange('ctas')}
          resource="ctas"
          labelKey="name"
          filter={this.filterOutAccountManagerCta}
          name="ctas"
          alwaysShowLabel
        />

        <br />

        {hasTagOnMessages && (
          <Select
            local={true}
            placeholder={intl.formatMessage(intlMessages.tagsPlaceholder)}
            label={intl.formatMessage(terms.tags)}
            multiple={false}
            value={message.tag}
            required={true}
            onChange={this._onSelectChange('tag')}
            options={tagOptions}
            name="tag"
            alwaysShowLabel
          />
        )}

        {isV2 && (
          <FloatingLabel
            label={intl.formatMessage(intlMessages.messageVideoUrlLabel)}
            type="text"
            placeholder={intl.formatMessage(
              intlMessages.messageVideoUrlPlaceholder
            )}
            value={message.videoUrl}
            updateMessageOnBlur={this._onBlur}
            onValueChange={this._onValueChange}
            name="videoUrl"
          />
        )}

        {isV2 && (
          <div className={css(styles.noteStyle)}>
            {`Note: Please make sure the link looks like this - https://player.vimeo.com/video/1234567 where 1234567 is the video code which can be copied from Vimeo`}
          </div>
        )}

        <UploadButton
          onDrop={this.props.onDrop}
          multiple={isV2 ? true : false}
          label={
            message &&
            (message.image || (message.images && message.images.length))
              ? intl.formatMessage(intlMessages.changeMessageImageButtonLabel)
              : intl.formatMessage(intlMessages.uploadMessageImageButtonLabel)
          }
          error={error}
          assetsName={imageName}
          onChange={this._onSelectChange('image')}
          uploadCss={styles.upload}
        />

        <Button wrapperStyle={styles.nextButton} onClick={onSubmit}>
          {intl.formatMessage(terms.next)}
        </Button>
      </Form>
    );
  }

  static styles = StyleSheet.create({
    form: {
      width: 400,
    },
    upload: {
      minWidth: '100px',
    },
    nextButton: {
      width: '100%',
    },
    noteStyle: {
      fontSize: 14,
      fontWeight: 600,
      color: '#666666',
    },
  });
}

function mapStateToProps(state) {
  return {
    tagOptions:
      state.brand.tags &&
      Object.entries(state.brand.tags).map((tag) => ({
        id: tag[0],
        label: tag[1].label,
      })),
    hasTagOnMessages: state.brand.features.hasTagOnMessages,
    isV2: V2_BRANDS.indexOf(state.brand.id) > -1,
  };
}

export default connect(mapStateToProps, null, null, {forwardRef: true})(
  injectIntl(MessageComposeForm)
);
