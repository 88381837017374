import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {StyleSheet, css} from 'aphrodite';

const HEIGHT = 32;
const WIDTH = 216;
const ICON_SIZE = 12;
const ICON_MARGIN = 16;

import MORE_DOTS from '../../../assets/images/rpb/moreDots.png';

export default class CtaRow extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    color: PropTypes.string,
    fontColor: PropTypes.string,
    secondaryBackgroundColor: PropTypes.string,
    secondaryFontColor: PropTypes.string,
    hasMenuButton: PropTypes.bool.isRequired,
    openMenu: PropTypes.func.isRequired,
    menuOpen: PropTypes.bool,
    hasBorder: PropTypes.bool,
  };

  // If secondary styling given as props
  // Used for primary and secondary cta's
  _secondaryStyling = () => {
    const {
      secondaryBackgroundColor,
      secondaryFontColor,
      backgroundColor,
      menuOpen,
    } = this.props;

    if (secondaryBackgroundColor && secondaryFontColor) {
      const openCtastyling = StyleSheet.create({
        colors: {
          color: secondaryFontColor,
          backgroundColor: secondaryBackgroundColor,
        },
      });
      const closedCtastyling = StyleSheet.create({
        colors: {background: backgroundColor},
      });
      return menuOpen ? openCtastyling.colors : closedCtastyling.colors;
    }
  };

  // Default Coloring
  _defaultStyling = () => {
    const {menuOpen} = this.props;
    if (!menuOpen) {
      return StyleSheet.create({
        closed: {
          color: '#fff',
          background: this.props.primaryBackgroundColor,
          margin: '8px',
          borderRadius: '16px',
        },
      }).closed;
    }
    return StyleSheet.create({
      closed: {marginLeft: '8px'},
    }).closed;
  };

  _categoryColor = () => {
    const {color} = this.props;
    if (color) {
      return StyleSheet.create({colors: {backgroundColor: color}}).colors;
    } else {
      return this._secondaryStyling();
    }
  };

  render() {
    const {label, icon, color, hasMenuButton, openMenu, fontColor, hasBorder} =
      this.props;
    const {styles} = CtaRow;
    const transitionedColor = this._secondaryStyling();
    const defaultStyling = this._defaultStyling();
    const categoryColor = this._categoryColor();
    const border = hasBorder ? css(styles.border) : null;
    const menuContainer = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: HEIGHT,
      width: ICON_SIZE + ICON_MARGIN * 2,
      color: fontColor,
      backgroundColor: color,
    };

    return (
      <div className={css(styles.row, transitionedColor, defaultStyling)}>
        <div className={css(styles.labelContainer, transitionedColor)}>
          <div className={css(styles.label, transitionedColor)}>{label}</div>
        </div>

        <div className={css(styles.wrapper)}>
          <div className={css(styles.iconContainer)}>
            <img
              className={css(styles.iconStyle, categoryColor)}
              src={icon}
              alt="Call to action icon"
            />
          </div>

          {hasMenuButton && (
            <div style={menuContainer} onClick={openMenu}>
              <img
                width={20}
                src={MORE_DOTS}
                style={{alignSelf: 'center'}}
                alt="More ctas"
              />
            </div>
          )}
        </div>
        <div className={border} />
      </div>
    );
  }

  static styles = StyleSheet.create({
    row: {
      position: 'relative',
      display: 'flex',
      width: WIDTH,
      height: HEIGHT,
    },
    labelContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },
    label: {
      fontFamily: 'BentonSans-Bold',
      fontSize: 8,
      letterSpacing: 0.7,
      paddingLeft: 16,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: HEIGHT,
      width: ICON_SIZE + ICON_MARGIN * 2,
    },
    iconStyle: {
      width: ICON_SIZE,
      height: ICON_SIZE,
      alignSelf: 'center',
      backgroundColor: 'red',
    },
    border: {
      position: 'absolute',
      bottom: 0,
      left: 8,
      width: 200,
      height: 1,
      backgroundColor: '#d9dbda',
    },
  });
}
