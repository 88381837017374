import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Spinner from '../Spinner/Spinner';

import {colorDefinitions} from '../../helpers/colors';

import {css, StyleSheet} from 'aphrodite';

export default class Button extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.any,
    wrapperStyle: PropTypes.object,
    loading: PropTypes.bool,
  };

  render() {
    let buttonId = 'unknown-button';
    if (typeof this.props.children === 'string') {
      buttonId = this.props.children;
    }
    const {styles} = Button;
    const {onClick} = this.props;
    return (
      <div
        onClick={onClick}
        id={buttonId}
        className={
          this.props.wrapperStyle
            ? css(styles.wrapper, this.props.wrapperStyle)
            : css(styles.wrapper)
        }
      >
        {this.props.loading ? (
          <div className={css(styles.spinnerDiv)}>
            <Spinner />
          </div>
        ) : (
          <div
            className={
              this.props.wrapperStyle
                ? css(styles.button, this.props.wrapperStyle)
                : css(styles.button)
            }
            color={colorDefinitions.buttonCopy}
          >
            <div className={css(styles.buttonText)}>{this.props.children}</div>
          </div>
        )}
      </div>
    );
  }

  static styles = StyleSheet.create({
    wrapper: {
      position: 'relative',
      width: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '20px 0',
    },
    button: {
      ':hover': {
        opacity: 0.75,
      },
      boxSizing: 'border-box',
      display: 'flex',
      position: 'relative',
      opacity: 1,
      borderRadius: 2,
      background: colorDefinitions.primary,
      alignSelf: 'center',
      justifyContent: 'center',
      paddingLeft: 20,
      paddingRight: 20,
      height: 32,
      cursor: 'pointer',
    },
    spinnerDiv: {
      alignSelf: 'center',
      justifyContent: 'center',
      paddingLeft: 20,
      paddingRight: 20,
      height: 32,
    },
    buttonText: {
      color: colorDefinitions.buttonCopy,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  });
}
