import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {css, StyleSheet} from 'aphrodite';
import ReactStateAnimation from 'react-state-animation';

import {TextInput} from '../../helpers/formComponents';
import newIcon from '../../assets/images/ic_new.svg';
import searchIcon from '../../assets/images/ic_search.svg';
import {
  MARGIN_HORIZONTAL,
  HEADER_PADDING,
} from '../../containers/Header/Header';

const ANIMATION_DURATION = 300;
let mouseIsDownOnSearch = false;

class Toolbar extends Component {
  static propTypes = {
    createItem: PropTypes.func,
    defaultSearchPlaceholder: PropTypes.string.isRequired,
    onFilterInputChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showSearchInput: false,
      width: 0,
    };
    this._animate = new ReactStateAnimation(this);
  }

  componentDidMount = () => {
    window.addEventListener('mousedown', this._onClickElseWhere, false);
  };

  componentWillUnmount = () => {
    window.removeEventListener('mousedown', this._onClickElseWhere);
  };

  _mouseDownOnSearch = () => {
    mouseIsDownOnSearch = true;
  };

  _mouseUpOnSearch = () => {
    mouseIsDownOnSearch = false;
  };

  _onClickElseWhere = () => {
    if (!mouseIsDownOnSearch && this.state.showSearchInput) {
      this._hideSearchInput();
    }
  };

  _showSearchInput = () => {
    if (!this.state.showSearchInput) {
      this._animate.linearInOut('width', 240, ANIMATION_DURATION);
      this.setState({showSearchInput: true});
    }
  };
  _hideSearchInput = () => {
    if (this.state.showSearchInput) {
      this._animate.linearInOut('width', 0, ANIMATION_DURATION);
      setTimeout(
        function () {
          this.setState({showSearchInput: false});
        }.bind(this),
        ANIMATION_DURATION
      );
    }
  };

  render() {
    const {styles} = Toolbar;
    return (
      <div className={css(styles.overviewHeader)}>
        <div
          className={css(styles.headerButtonContainer)}
          onMouseDown={this._mouseDownOnSearch}
          onMouseUp={this._mouseUpOnSearch}
        >
          <img
            className={css(styles.headerButtonIcon)}
            onMouseOver={this._showSearchInput}
            src={searchIcon}
            alt="search here"
          />
          {this.state.showSearchInput && (
            <TextInput
              className={css(styles.searchInput)}
              autoFocus={true}
              style={{width: this.state.width}}
              placeholer={this.props.defaultSearchPlaceholder}
              onChange={this.props.onFilterInputChange}
            />
          )}
          {this.props.createItem && (
            <img
              id="createIcon"
              className={css(styles.headerButtonIcon)}
              onClick={this.props.createItem}
              alt="create"
              src={newIcon}
            />
          )}
          <span className={css(styles.countryCode)}>{this.props.name}</span>
        </div>
      </div>
    );
  }

  static styles = StyleSheet.create({
    overviewHeader: {
      position: 'absolute',
      top: 2,
      right: 0,
      height: 34,
      zIndex: 50,
      paddingTop: HEADER_PADDING.top,
      paddingBottom: HEADER_PADDING.bottom,
      pointerEvents: 'none',
      backgroundColor: 'transparent',
    },
    headerButtonContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginRight: MARGIN_HORIZONTAL + 20,
      height: 34,
    },
    countryCode: {
      fontFamily: 'Raleway',
      fontSize: 14,
      fontWeight: 400,
      opacity: 0, // hidden brandname placeholder for correct positioning of search and create buttons.
      color: '#1d1d1b',
    },
    headerButtonIcon: {
      width: 34,
      height: 34,
      pointerEvents: 'All',
      cursor: 'pointer',
      zIndex: 51,
    },
    searchInput: {
      fontSize: 14,
      outline: 0,
      border: 0,
      height: 34,
      marginBottom: '-10px !important',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'rgba(103, 103, 103, 0.5)',
      fontFamily: 'Raleway',
    },
  });
}

export default connect((state) => {
  return {
    name: state.brand.name,
  };
})(Toolbar);
