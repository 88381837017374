export const colors = {
  blue: '#99d3df',
  navy: '#38414D',
  white: '#fff',
  black: '#000',
  copyBlack: '#333333',
  green: '#1F2526',
  errorRed: '#ff4a51',
};

export const colorDefinitions = {
  ...colors,
  primary: colors.blue,
  secondary: colors.navy,
  default: colors.black,
  info: colors.navy,
  success: colors.green,
  error: colors.errorRed,
  buttonCopy: colors.white,
};
