import React, {Component} from 'react';
import {connect} from 'react-redux';
import {css, StyleSheet} from 'aphrodite';
import {ChatFeed} from 'react-chat-ui';

import ConversationList from './ConversationList';
import {getConversations, selectConversation} from '../../redux/modules/chat';

// import Smooch from 'smooch';

class Chat extends Component {
  componentDidMount = () => {
    const {dispatch} = this.props;
    dispatch(getConversations())
      .then(() => {
        const {conversations} = this.props;
        if (conversations.length > 0) {
          dispatch(selectConversation(conversations[0]));
        }
      })
      .catch((e) => {
        console.log('e', e);
      });
    // Smooch.init({
    //   appToken: '98l9i3e6zd8t5b06hzkahzam9',
    //   embedded: true,
    // });
    // Smooch.render(this.smoochContainer);
  };

  onSelectConversation = (conversation) => {
    this.props.dispatch(selectConversation(conversation));
  };

  _mapRoleToType(role) {
    switch (role) {
      case 'sales':
        return 0;
      case 'user':
      default:
        return 1;
    }
  }

  render() {
    const {conversations, currentConversation, loading} = this.props;
    const {styles} = Chat;
    const chats = currentConversation ? currentConversation.chats || [] : [];

    return (
      <div className={css(styles.fullWrapper)}>
        <div className={css(styles.leftSideWrapper)}>
          <ConversationList
            conversations={conversations}
            onSelectConversation={this.onSelectConversation}
          />
        </div>

        <div className={css(styles.rightSideWrapper)}>
          {!loading && (
            <ChatFeed
              messages={chats.map((chat) => ({
                message: `${chat.value} ${chat.conversationId}`,
                type: this._mapRoleToType(chat.role),
              }))}
              hasInputField={false}
              bubblesCentered={false}
              bubbleStyles={{
                text: {
                  fontSize: 18,
                },
                chatbubble: {
                  borderRadius: 5,
                  padding: 6,
                  marginLeft: 6,
                  marginRight: 6,
                },
              }}
            />
          )}
        </div>
      </div>
    );
  }

  static styles = StyleSheet.create({
    fullWrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '100',
    },
    leftSideWrapper: {
      width: '50%',
    },
    rightSideWrapper: {
      width: '50%',
    },
  });
}

//TODO rename `conversation` to `client`?
function mapStateToProps(state) {
  return {
    conversations: state.chat.conversations || [],
    currentConversation: state.chat.current,
    loading: state.chat.loading,
  };
}
// function mapDispatchToProps(state) {

// }

export default connect(mapStateToProps)(Chat);

// <div className={css(styles.leftSideWrapper)}>
//   <ConversationList chatMessages={chatMessages}/>
// </div>
