export default {
  inputWrapper: {
    marginBottom: '10px',
  },
  label: {
    display: 'block',
    fontWeight: '600',
    marginBottom: '10px',
  },
  error: {
    fontSize: 12,
    marginTop: 15,
    color: '#ff4a51',
  },
  success: {
    fontSize: 12,
    marginTop: 10,
    color: '#5eb21b',
  },
};
