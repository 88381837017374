import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {NotificationManager} from 'react-notifications';

import {exchangeCodeForToken, makeAuthUrl} from '../../redux/modules/auth';
import {
  getBrand,
  handleInstagramAuthorization,
} from '../../redux/modules/brand';
import {fetchI18nData} from '../../redux/modules/i18n';

function mapStateToProps(state) {
  return {
    loggedIn: state.auth.loggedIn,
    brand: state.brand,
    brandInitialized: state.brand.initialized,
    i18nInitialized: state.i18n.initialized,
  };
}

export default connect(mapStateToProps)(
  class InitWrapper extends Component {
    static propTypes = {
      dispatch: PropTypes.func.isRequired,
      loggedIn: PropTypes.bool.isRequired,
    };

    UNSAFE_componentWillReceiveProps = async (nextProps) => {
      if (nextProps.brandInitialized && !nextProps.i18nInitialized) {
        await this.props.dispatch(fetchI18nData(nextProps.brand));
      }
    };

    componentDidMount = async () => {
      const {loggedIn} = this.props;
      if (loggedIn) {
        await this.props.dispatch(getBrand());
        if (window.location.search.indexOf('code') !== -1) {
          const code = window.location.search
            .split('&')
            .filter((item) => item.indexOf('code') > -1)[0]
            .split('=')[1];
          this.props
            .dispatch(handleInstagramAuthorization(code))
            .then((response) => {
              // Show notification
              if (response.success === true) {
                NotificationManager.success(
                  'You have successfully linked your Instagram account'
                );
              } else {
                NotificationManager.error(
                  'There was an error while authorizing your Instagram account'
                );
              }
              setTimeout(() => {
                window.location.assign('/');
              }, 2000);
            })
            .catch((e) => {
              NotificationManager.error(
                'There was an error while authorizing your Instagram account'
              );
              setTimeout(() => {
                window.location.assign('/');
              }, 2000);
            });
        }
      } else {
        // Handle Auth callback
        if (
          window.location.hash.indexOf('callback') !== -1 &&
          window.location.search.indexOf('code') !== -1
        ) {
          this.props
            .dispatch(exchangeCodeForToken())
            .then(() => {
              window.location.assign('/');
            })
            .catch((e) => {
              window.location = '/#/login-error';
            });
        } else if (
          // Prevent callback url redirect loop
          window.location.hash.indexOf('callback') === -1 &&
          window.location.hash.indexOf('login-error') === -1
        ) {
          makeAuthUrl().then((url) => {
            window.location = url;
          });
        }
      }
    };

    render() {
      const {children, brandInitialized, i18nInitialized} = this.props;
      if (brandInitialized && i18nInitialized) {
        return <div>{children}</div>;
      } else {
        return null;
      }
    }
  }
);
