import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {asyncConnect} from 'redux-connect';
import {css, StyleSheet} from 'aphrodite';
import {connect} from 'react-redux';

import {loadMessage, refreshCurrent} from '../../redux/modules/message';
import MessageFormTabs from './MessageFormTabs';
import MessagePreview from '../MessagePreview/MessagePreview';

class MessageDetail extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    message: PropTypes.object,
    brand: PropTypes.object,
    params: PropTypes.object,
  };

  // TODO [I18N] ?
  static columns = [{name: 'id'}, {name: 'name'}];

  UNSAFE_componentWillUpdate = (nextProps) => {
    this._checkMessage(nextProps);
  };

  lastCheck = new Date().getTime();

  // Loop that keeps checking if the message needs to be updated
  _checkMessage = (nextProps) => {
    if (this.lastCheck < new Date().getTime() - 1000) {
      this.lastCheck = new Date().getTime();
      switch (nextProps.message.status) {
        case 'message/processing':
        case 'message/changed':
          this.props.dispatch(refreshCurrent());
          break;
        default:
      }
    } else {
      setTimeout(() => {
        this._checkMessage(nextProps);
      }, 200);
    }
  };

  render() {
    const {message} = this.props;
    const {styles} = MessageDetail;
    return (
      <div>
        <div className={css(styles.messageBlock)}>
          <MessageFormTabs message={message} {...this.props} />
          <MessagePreview {...this.props} />
        </div>
      </div>
    );
  }

  static styles = StyleSheet.create({
    messageBlock: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  });
}

export default asyncConnect([
  {
    key: 'load',
    promise: (helpers) => {
      helpers.store.dispatch(loadMessage(helpers.match.params.id));
    },
  },
])(
  connect((state) => {
    return {
      lookup: state.lookup,
      message: state.messages.currentMessage,
      brand: state.brand,
    };
  })(MessageDetail)
);
