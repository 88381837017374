import routes from '../../helpers/routes';

const ACTION_LOAD_USERS = 'LOAD_USERS';
const ACTION_SELECT_USER = 'SELECT_USER';
const ACTION_DESELECT_USER = 'DESELECT_USER';
const ACTION_UPDATE_USER = 'ACTION_UPDATE_USER';
const ACTION_UPDATE_USER_LOCALLY = 'ACTION_UPDATE_USER_LOCALLY';

export function reducer(
  state = {
    current: {},
    users: [],
  },
  action
) {
  switch (action.type) {
    case ACTION_LOAD_USERS:
      return {
        ...state,
        users: action.data,
      };
    case ACTION_SELECT_USER:
      return {
        ...state,
        current: state.users.find((user) => user.id === action.data),
      };
    case ACTION_DESELECT_USER:
      return {
        ...state,
        current: {},
      };
    case ACTION_UPDATE_USER_LOCALLY:
      return {
        ...state,
        current: action.data,
      };
    case ACTION_UPDATE_USER:
      return {
        ...state,
        users: [
          ...state.users.slice(0, action.data.index),
          action.data.update,
          ...state.users.slice(action.data.index + 1),
        ],
      };
    default:
      return state;
  }
}

function mapFieldsForSubBrands(segmentsForAuth0User, state) {
  const {subBrands, webConsoleType} = state.brand;
  const {segments} = (state && state.segments) || [];
  let data = {};

  switch (webConsoleType) {
    case 'COUNTRY_BRAND_WEB_CONSOLE':
      for (const subBrandId in subBrands) {
        if (subBrands.hasOwnProperty(subBrandId)) {
          const nameForField = `segment_${subBrands[subBrandId].shortName}`;
          const valueForField = segments
            .filter(
              (segment) =>
                segmentsForAuth0User.includes(segment.id) &&
                segment.belongsToBrand === subBrandId
            )
            .map((segment) => segment.id);
          data[nameForField] = valueForField[0];
        }
      }
      break;
    default:
      break;
  }
  return data;
}

/*
 * Convert auth0 user object to user objects which we use
 */
function mapAuth0UserFieldsToUser(auth0User, state) {
  const segmentsForAuth0User = (auth0User.app_metadata || {}).segments || [];
  const segmentsSubBrands = mapFieldsForSubBrands(
    segmentsForAuth0User,
    state,
    (auth0User.user_metadata || {}).name
  );
  const appMetaData = auth0User.app_metadata || {};
  const userMetaData = auth0User.user_metadata || {};
  const isAdmin = !!appMetaData.isAdmin;
  return {
    id: auth0User.user_id,
    email: auth0User.email,
    brandId: appMetaData.brandId,
    role: appMetaData.role,
    isAdmin,
    segment: segmentsForAuth0User[0],
    picture: userMetaData.picture,
    name: userMetaData.name,
    phone: userMetaData.phone,
    backgroundImage: userMetaData.backgroundImage,
    ...segmentsSubBrands,
  };
}

export function getUsers() {
  return (dispatch, getState) => {
    return dispatch({
      promise: (http) =>
        http
          .get(`${routes.users}`)
          .then((users) => {
            return users.map((auth0User) =>
              mapAuth0UserFieldsToUser(auth0User, getState())
            );
          })
          .then((users) => dispatch({type: ACTION_LOAD_USERS, data: users}))
          .catch((err) => console.error('error in getUsers', err)),
    });
  };
}

export function selectUser(id) {
  return (dispatch) => dispatch({type: ACTION_SELECT_USER, data: id});
}

export function deselectUser() {
  return (dispatch) => dispatch({type: ACTION_DESELECT_USER});
}

export const updateUserLocally = (user) => {
  return (dispatch) => {
    return dispatch({
      type: ACTION_UPDATE_USER_LOCALLY,
      data: user,
    });
  };
};

export function createUser(values) {
  return (dispatch) => {
    return dispatch({
      promise: (http) =>
        http
          .post(`${routes.users}`, values)
          .catch((err) => console.error('error in createUser', err)),
    });
  };
}

export function deleteUser(user) {
  return (dispatch, getState) => {
    return dispatch({
      promise: (http) =>
        http.delete(`${routes.users}/${user.id}`).then(() =>
          dispatch({
            type: ACTION_LOAD_USERS,
            data: getState().users.users.filter((cl) => cl.id !== user.id),
          })
        ),
    });
  };
}

export function updateUser(id, values) {
  return (dispatch, getState) => {
    return dispatch({
      promise: (http) =>
        http
          .put(`${routes.users}/${id}`, values)
          .then((updatedUser) => {
            const update = mapAuth0UserFieldsToUser(updatedUser, getState());
            dispatch({
              type: ACTION_UPDATE_USER,
              data: {
                update,
                index: getState().users.users.findIndex(
                  (user) => user.id === update.id
                ),
              },
            });
            return updatedUser;
          })
          .catch((err) => console.error('error in updateUser', err)),
    });
  };
}

export function uploadProfilePicture(user, picture) {
  const data = new FormData();
  data.append('file', picture, 'profilePicture');
  return (dispatch, getState) => {
    const {users} = getState().users;
    return dispatch({
      promise: (client) =>
        client
          .post(`${routes.users}/${user.id}/upload-profile-picture`, data)
          .then((auth0User) => {
            const updatedUser = mapAuth0UserFieldsToUser(auth0User, getState());
            // const {users} = getState().users;
            const index = users.findIndex((u) => u.id === updatedUser.id);

            return dispatch({
              type: ACTION_UPDATE_USER,
              data: {index, update: updatedUser},
            });
          }),
    });
  };
}

export function uploadBackgroundImage(user, backgroundImage) {
  const data = new FormData();
  data.append('file', backgroundImage, 'backgroundImage');
  return (dispatch, getState) => {
    const {users} = getState().users;
    return dispatch({
      promise: (client) =>
        client
          .post(`${routes.users}/${user.id}/upload-background-image`, data)
          .then((auth0User) => {
            const updatedUser = mapAuth0UserFieldsToUser(auth0User, getState());
            // const {users} = getState().users;
            const index = users.findIndex((u) => u.id === updatedUser.id);

            return dispatch({
              type: ACTION_UPDATE_USER,
              data: {index, update: updatedUser},
            });
          }),
    });
  };
}
