import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {StyleSheet, css} from 'aphrodite';
import {injectIntl} from 'react-intl';

import {colors} from '../../helpers/colors';
import mainStyles from '../../helpers/styles';
import InputWrapper from '../InputWrapper/InputWrapper';

class UploadButton extends Component {
  static propTypes = {
    label: PropTypes.string,
    assetsName: PropTypes.string,
    error: PropTypes.object,
    uploadCss: PropTypes.any,
    touched: PropTypes.bool,
  };

  render() {
    let buttonId = 'unknown-button';
    if (typeof this.props.label === 'string') {
      buttonId = this.props.label;
    }
    const {error, label, assetsName, uploadCss, touched, ...rest} = this.props;
    const {styles} = UploadButton;
    return (
      <InputWrapper>
        <div className={css(styles.wrapper)}>
          <Dropzone
            id={buttonId}
            {...rest}
            className={css(uploadCss, styles.upload)}
          >
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} className={css(styles.dropButton)}>
                <input {...getInputProps()} />
                <span>{label}</span>
              </div>
            )}
          </Dropzone>

          <label className={css(styles.file)}>{assetsName}</label>

          <div className={css(styles.errorAlignment)}>
            {touched && error && (
              <span className={css(styles.error)}>
                {this.props.intl.formatMessage(error)}
              </span>
            )}
          </div>
        </div>
      </InputWrapper>
    );
  }

  static styles = StyleSheet.create({
    upload: {
      cursor: 'pointer',
      position: 'relative',
      backgroundColor: colors.green,
      color: colors.white,
      padding: '8px 16px',
      margin: '20px 0',
      borderRadius: 2,
      ':hover': {
        opacity: 0.75,
      },
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    file: {
      marginLeft: 20,
      maxWidth: 200,
      overflow: 'visible',
    },
    errorAlignment: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    dropButton: {
      cursor: 'pointer',
      position: 'relative',
      backgroundColor: '#1F2526',
      color: '#fff',
      padding: '8px 16px',
      margin: '20px 0',
      borderRadius: '2px',
    },
    ...mainStyles,
  });
}

export default injectIntl(UploadButton);
