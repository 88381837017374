import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {css} from 'aphrodite';

/**
 * A form that does not submit on Enter keypress.
 */
export default class Form extends Component {
  static propTypes = {
    style: PropTypes.object,
  };

  render() {
    const {style} = this.props;

    return (
      <form
        className={css(style)}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {this.props.children}
      </form>
    );
  }
}
