import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {renderRoutes} from 'react-router-config';

export default class CTAS extends Component {
  static propTypes = {
    children: PropTypes.any,
  };

  render() {
    return <div>{renderRoutes(this.props.route.routes)}</div>;
  }
}
