import {createStore, applyMiddleware, compose} from 'redux';
import middleWares from './middleware/index';
import reducers from './modules';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  /* preloadedState, */ composeEnhancers(applyMiddleware(...middleWares))
);
export default store;
