import React from 'react';
import {css, StyleSheet} from 'aphrodite';
import {Cell} from 'fixed-data-table-2';

export default function PictureCell(props) {
  const BORDER_BOTTOM_WIDTH = 6;
  const HEIGHT = props.height - BORDER_BOTTOM_WIDTH;
  const WIDTH = HEIGHT;

  const styles = StyleSheet.create({
    cell: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    image: {
      width: WIDTH,
      height: HEIGHT,
      objectFit: 'cover',
      borderRadius: HEIGHT / 2,
    },
  });

  return (
    <Cell {...props} className={css(styles.cell)}>
      {<img src={props.source} alt={props.alt} className={css(styles.image)} />}
    </Cell>
  );
}
