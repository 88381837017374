import React, {Component} from 'react';
import {Router as ReactRouter} from 'react-router';
import {injectIntl} from 'react-intl';
import {syncHistoryWithStore} from 'react-router-redux';
import {ReduxAsyncConnect} from 'redux-connect';

import {analytics} from '../../redux/middleware/analyticsMiddleware';
import {createHashHistory} from 'history';
import makeRoutes from './Routes';
import store from '../../redux/store';

analytics.page(location.pathname);
// bust

const history = syncHistoryWithStore(createHashHistory(), store);

export default injectIntl(
  class Router extends Component {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        this.onRouteChanged();
      }
    }

    onRouteChanged() {
      if (
        this.props.location.action === 'REPLACE' ||
        this.props.location.action === 'PUSH'
      ) {
        analytics.page(this.props.location.pathname);
      }
    }

    render() {
      return (
        <ReactRouter history={history}>
          <ReduxAsyncConnect routes={makeRoutes(this.props.intl)} />

          {this.props.children}
        </ReactRouter>
      );
    }
  }
);
