import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, defineMessages} from 'react-intl';
import {deleteUser} from '../../redux/modules/users';
import terms from '../../helpers/terms';
import FixedDataTable from '../../components/FixedDataTable/FixedDataTable';
import TextCell from '../../components/FixedDataTable/TextCell';
import PictureCell from '../../components/FixedDataTable/PictureCell';
import {Column} from 'fixed-data-table-2';

function mapStateToProps(state) {
  return {
    users: state.users.users,
    segments: state.segments.segments,
  };
}

export default injectIntl(
  connect(mapStateToProps)(
    class UsersOverview extends Component {
      static propTypes = {
        users: PropTypes.array,
        dispatch: PropTypes.func,
      };

      _segmentFromId = (id) => {
        if (this.props.segments) {
          const result = (
            this.props.segments.filter((segment) => segment.id === id)[0] || {}
          ).name;
          return result;
        }
      };

      _editUser = (client) => () => {
        this.props.history.push({
          pathname: `/users/edit/${client.id}`,
          search: '',
        });
      };

      _onCreateUser = () => {
        this.props.history.push('/users/create');
      };

      _filter = (value) => (user) => {
        return (
          (user.name && user.name.toLowerCase().indexOf(value) !== -1) ||
          (user.email && user.email.toLowerCase().indexOf(value) !== -1)
        );
      };

      render() {
        const {dispatch, users} = this.props;

        const messages = defineMessages({
          userDeleteConfirmLabel: {
            id: 'UsersOverview.userDeleteConfirmLabel',
            description:
              'The message that is shown when the user is about to delete a user/account manager from the platform',
            defaultMessage:
              'Are you sure you want to permanently delete this user?',
          },
        });

        return (
          <div>
            <FixedDataTable
              inputData={users}
              dispatch={dispatch}
              filter={this._filter}
              createItem={this._onCreateUser}
              deleteItem={deleteUser}
              editItem={this._editUser}
              confirmLabel={this.props.intl.formatMessage(
                messages.userDeleteConfirmLabel
              )}
            >
              <Column
                align={'center'}
                displayCell={(user, props) => {
                  if (user.picture) {
                    return (
                      <PictureCell
                        source={user.picture}
                        alt={user.name}
                        {...props}
                      />
                    );
                  } else {
                    return <TextCell {...props}> </TextCell>;
                  }
                }}
                width={60}
              />
              <Column
                headerTitle={this.props.intl.formatMessage(terms.email)}
                flexGrow={1}
                displayCell={(user, props) => (
                  <TextCell {...props}>{user.email}</TextCell>
                )}
                width={180}
                sortingField={(user) => user.email}
                columnKey={'email'}
              />
              <Column
                headerTitle={this.props.intl.formatMessage(terms.name)}
                flexGrow={1}
                displayCell={(user, props) => (
                  <TextCell {...props}>{user.name}</TextCell>
                )}
                width={150}
                sortingField={(user) => user.name}
                columnKey={'name'}
              />
              <Column
                headerTitle={this.props.intl.formatMessage(terms.role)}
                displayCell={(user, props) => (
                  <TextCell {...props}>{user.role}</TextCell>
                )}
                width={100}
                sortingField={(user) => user.role}
                columnKey={'role'}
              />
              <Column
                headerTitle={this.props.intl.formatMessage(terms.segment)}
                displayCell={(user, props) => (
                  <TextCell {...props}>
                    {this._segmentFromId(user.segment)}
                  </TextCell>
                )}
                width={150}
                sortingField={(user) => user.segment}
                columnKey={'segment'}
              />
            </FixedDataTable>
          </div>
        );
      }
    }
  )
);
