import routes from '../../helpers/routes';

const ACTION_LOAD_CLIENTS = 'LOAD_CLIENTS';
const ACTION_SELECT_CLIENT = 'SELECT_CLIENT';
const ACTION_CREATE_CLIENT = 'CREATE_CLIENT';
const ACTION_DESELECT_CLIENT = 'DESELECT_CLIENT';
const ACTION_UPDATE_CLIENT = 'ACTION_UPDATE_CLIENT';
const ACTION_UPDATE_CLIENT_LOCALLY = 'ACTION_UPDATE_CLIENT_LOCALLY';

export function reducer(
  state = {
    current: {},
    clients: [],
  },
  action
) {
  switch (action.type) {
    case ACTION_LOAD_CLIENTS:
      return {
        ...state,
        clients: action.data,
      };
    case ACTION_CREATE_CLIENT:
      return {
        ...state,
        clients: [...state.clients, action.data],
        current: action.data,
      };
    case ACTION_SELECT_CLIENT:
      return {
        ...state,
        current: state.clients.find((client) => client.id === action.data),
      };
    case ACTION_DESELECT_CLIENT:
      return {
        ...state,
        current: {},
      };
    case ACTION_UPDATE_CLIENT_LOCALLY:
      return {
        ...state,
        current: action.data,
      };
    case ACTION_UPDATE_CLIENT:
      return {
        ...state,
        clients: [
          ...state.clients.slice(0, action.data.index),
          action.data.updatedClient,
          ...state.clients.slice(action.data.index + 1),
        ],
      };
    default:
      return state;
  }
}

export function getClients() {
  return (dispatch) => {
    return dispatch({
      promise: (http) =>
        http
          .get(`${routes.clients}`)
          .then((clients) =>
            dispatch({type: ACTION_LOAD_CLIENTS, data: clients})
          )
          .catch((err) => console.error('error in getClients', err)),
    });
  };
}

export function selectClient(id) {
  return (dispatch) => dispatch({type: ACTION_SELECT_CLIENT, data: id});
}

export function deselectClient() {
  return (dispatch) => dispatch({type: ACTION_DESELECT_CLIENT});
}

export const updateClientLocally = (client) => {
  return (dispatch) => {
    return dispatch({
      type: ACTION_UPDATE_CLIENT_LOCALLY,
      data: client,
    });
  };
};

export function createClient(values) {
  return (dispatch) => {
    return dispatch({
      promise: (http) =>
        http
          .post(`${routes.clients}`, values)
          .then((client) => {
            dispatch({type: ACTION_CREATE_CLIENT, data: client});
            return client;
          })
          .catch((err) => console.error('error in createClient', err)),
    });
  };
}

export function deleteClient(client) {
  return (dispatch, getState) => {
    return dispatch({
      promise: (http) =>
        http
          .delete(`${routes.clients}/${client.id}`)
          .then(() =>
            dispatch({
              type: ACTION_LOAD_CLIENTS,
              data: getState().clients.clients.filter(
                (cl) => cl.id !== client.id
              ),
            })
          )
          .catch((err) => console.error('error in deleteClient', err)),
    });
  };
}

export function updateClient(id, values) {
  return (dispatch, getState) => {
    // Temp fix: Creates duplicates if client is of subbrand (which will not be the case in the future)
    values.brandId = getState().clients.current.brandId;
    return dispatch({
      promise: (http) =>
        http
          .put(`${routes.clients}/${id}`, values)
          .then((updatedClient) =>
            dispatch({
              type: ACTION_UPDATE_CLIENT,
              data: {
                updatedClient,
                index: getState().clients.clients.findIndex(
                  (client) => client.id === updatedClient.id
                ),
              },
            })
          )
          .catch((err) => console.error('error in updateClient', err)),
    });
  };
}

export function resetClientPassword(id) {
  return (dispatch) => {
    return dispatch({
      promise: (http) =>
        http
          .post(`${routes.clients}/${id}/resetClientPassword`, {})
          .catch((err) => console.error('error in resetClientPassword', err)),
    });
  };
}

export function clientsWithExtendedFollowedBrandsSelector(state) {
  const clients = state.clients.clients;
  const segments = state.segments.segments;
  const subBrands = state.brand.subBrands;

  const clientsWithExtendedBrands = clients.map((client) => {
    const clientSegmentsArray = (client.segments || '').split('|');
    const followedBrands = (client.followedBrands || [])
      .filter((brandId) => subBrands[brandId])
      .map((brandId) => {
        const validSegments = segments.filter((seg) => {
          return seg.type === 'sales' && seg.belongsToBrand === brandId;
        });

        return {
          brandId: brandId,
          shortName: subBrands[brandId].shortName,
          linkedToSalesSegment: validSegments.some((seg) => {
            return clientSegmentsArray.includes(seg.id);
          }),
        };
      });

    return {...client, followedBrands};
  });

  return clientsWithExtendedBrands;
}
