import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {renderRoutes} from 'react-router-config';
import {asyncConnect} from 'redux-connect';

import {load} from '../../redux/modules/lookups';

class Messages extends Component {
  static propTypes = {
    children: PropTypes.any,
  };

  render() {
    return <div>{renderRoutes(this.props.route.routes)}</div>;
  }
}

export default asyncConnect([
  {
    key: 'categories',
    promise: (helpers) => {
      return helpers.store
        .dispatch(load('categories'))
        .then((action) => action.data);
    },
  },
  {
    key: 'ctas',
    promise: (helpers) => {
      return helpers.store.dispatch(load('ctas')).then((action) => action.data);
    },
  },
])(Messages);
