import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './redux/store';
import Router from './components/Router/Router';
import I18n from './components/I18n/I18n';
import InitWrapper from './containers/InitWrapper/InitWrapper';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

ReactDOM.render(
  <Provider store={store}>
    <I18n>
      <Router>
        <InitWrapper>
          <NotificationContainer />
        </InitWrapper>
      </Router>
    </I18n>
  </Provider>,
  document.getElementById('root')
);
