import React, {Component} from 'react';

class LoginError extends Component {
  render() {
    /*eslint-disable*/
    return (
      <div>Auth setup incorrect; unable to retrieve a valid user token</div>
    );
    /*eslint-enable*/
  }
}

export default LoginError;
