import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {StyleSheet} from 'aphrodite';
import {injectIntl, defineMessages} from 'react-intl';
import {
  COUNTRY_BRAND_WEB_CONSOLE,
  SUB_BRAND_WEB_CONSOLE,
  DEFAULT_WEB_CONSOLE,
} from '../../redux/modules/brand';
import SegmentValidation from './SegmentValidation';
import {
  UpsertButton,
  FloatingLabel,
  Select,
} from '../../helpers/formComponents';
import Form from '../../components/Form/Form';
import {selectSegment} from '../../redux/modules/segments';
import {showErrors} from '../../helpers/errors';
import _ from 'lodash';

const segmentTypeMessages = defineMessages({
  genericOption: {
    id: 'SegmentForm.genericOption',
    defaultMessage: 'Generic',
    description: 'The Generic option in the target group type dropdown',
  },
  salesOption: {
    id: 'SegmentForm.salesOption',
    defaultMessage: 'Sales',
    description: 'The Sales option in the target group type dropdown',
  },
  allOption: {
    id: 'SegmentForm.allOption',
    defaultMessage: 'All',
    description: 'The All option in the target group type dropdown',
  },
});

const formMessages = defineMessages({
  targetGroupNameLabel: {
    id: 'SegmentForm.targetGroupNameLabel',
    defaultMessage: 'Target Group Name',
    description: 'The label above the target group name input field',
  },
  targetGroupTypeLabel: {
    id: 'SegmentForm.targetGroupTypeLabel',
    defaultMessage: 'Type of Target Group',
    description: 'The label above the target group type input field',
  },
  targetGroupSubBrandLabel: {
    id: 'SegmentForm.targetGroupSubBrandLabel',
    defaultMessage: 'SubBrand',
    description: 'The label above the target group type input field',
  },
  targetGroupNamePlaceholder: {
    id: 'SegmentForm.targetGroupNamePlaceholder',
    defaultMessage: 'Enter Target Group Name',
    description: 'The placeholder in the target group name input field',
  },
  targetGroupTypePlaceholder: {
    id: 'SegmentForm.targetGroupTypePlaceholder',
    defaultMessage: 'Enter Type Of Target Group',
    description: 'The placeholder in the target group type input field',
  },
  subBrandPlaceholder: {
    id: 'SegmentForm.brandPlaceholder',
    defaultMessage: 'Select Brand',
    description: 'The placeholder in the input field for the brands',
  },
});

class SegmentForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    segment: PropTypes.object,
    segments: PropTypes.array,
    savingName: PropTypes.bool,
    brandId: PropTypes.string,
    subBrands: PropTypes.object,
    webConsoleType: PropTypes.string,
    viewMode: PropTypes.bool,
  };

  _submit = () => {
    // Do not update clients in segments api call
    // eslint-disable-next-line
    const {clients, ...rest} = this.props.segment;
    const errors = SegmentValidation(rest);
    if (_.isEmpty(errors)) {
      this.props.onSubmit(rest);
    } else {
      showErrors(errors);
    }
  };

  _getSegmentTypeOptions = () => {
    const {webConsoleType, segment} = this.props;
    let typeOptions = [
      {
        label: this.props.intl.formatMessage(segmentTypeMessages.salesOption),
        id: 'sales',
      },
      {
        label: this.props.intl.formatMessage(segmentTypeMessages.genericOption),
        id: 'generic',
      },
      {
        label: this.props.intl.formatMessage(segmentTypeMessages.allOption),
        id: 'all',
      },
    ];
    const typeAllExists = this._typeAllExists();

    switch (webConsoleType) {
      case COUNTRY_BRAND_WEB_CONSOLE:
        if (typeAllExists) {
          // Removes type all from selector since it already exists for the brand when creating
          if (segment && segment.id === typeAllExists.id) {
            return typeOptions.filter(
              (type) => type.id === 'sales' || type.id === 'all'
            );
          }
          return typeOptions.filter((type) => type.id === 'sales');
        }
        // view and edit (if not changed)
        return typeOptions.filter(
          (type) => type.id === 'sales' || type.id === 'all'
        );

      case SUB_BRAND_WEB_CONSOLE:
      case DEFAULT_WEB_CONSOLE:
      default:
        return typeOptions.filter(
          (type) => type.id === 'generic' || type.id === 'sales'
        );
    }
  };

  _getBrands = () => {
    const {brandId} = this.props;
    const brands = this.props.subBrands;
    let LabeledSubBrands = [];
    // Since the country brand does not exist in the subBrands
    brands[brandId] = {id: brandId};

    for (const brand in brands) {
      // to prevent a lint guard error the body of the for in loop is wrapped
      // https://stackoverflow.com/questions/1963102/what-does-the-jslint-error-body-of-a-for-in-should-be-wrapped-in-an-if-statement
      if (brands.hasOwnProperty(brand)) {
        LabeledSubBrands.push({
          label: brands[brand].name,
          id: brand,
        });
      }
    }

    return LabeledSubBrands;
  };

  _valueChange = (key, value) => {
    let segment = {...this.props.segment};
    segment[key] = value;
    this.props.dispatch(selectSegment(segment));
  };

  // Checks through the segments if the current brand has all
  _typeAllExists = () => {
    const {segments, segment} = this.props;
    if (segments && segment && segment.belongsToBrand) {
      const belongsToBrand = segment.belongsToBrand;
      return segments
        .filter((segment) => segment.belongsToBrand === belongsToBrand)
        .find((segment) => segment.type === 'all');
    }
  };

  render() {
    const {segment, webConsoleType, viewMode, isEdit} = this.props;
    const {styles} = SegmentForm;

    return (
      <Form style={styles.form}>
        <FloatingLabel
          label={this.props.intl.formatMessage(
            formMessages.targetGroupNameLabel
          )}
          placeholder={this.props.intl.formatMessage(
            formMessages.targetGroupNamePlaceholder
          )}
          type="text"
          name="name"
          value={segment.name}
          disabled={viewMode}
          onValueChange={this._valueChange}
        />

        <Select
          local={true}
          options={this._getSegmentTypeOptions()}
          label={this.props.intl.formatMessage(
            formMessages.targetGroupTypeLabel
          )}
          placeholder={this.props.intl.formatMessage(
            formMessages.targetGroupTypePlaceholder
          )}
          multiple={false}
          onChange={this._valueChange.bind(this, 'type')}
          required={true}
          autoFocus={!segment}
          disabled={webConsoleType === SUB_BRAND_WEB_CONSOLE || viewMode}
          name="type"
          value={segment.type}
        />

        {webConsoleType === COUNTRY_BRAND_WEB_CONSOLE && (
          <Select
            local={true}
            options={this._getBrands()}
            placeholder={this.props.intl.formatMessage(
              formMessages.subBrandPlaceholder
            )}
            label={this.props.intl.formatMessage(
              formMessages.targetGroupSubBrandLabel
            )}
            multiple={false}
            onChange={this._valueChange.bind(this, 'belongsToBrand')}
            required={true}
            name="belongsToBrand"
            value={segment.belongsToBrand}
            disabled={viewMode}
          />
        )}

        {!viewMode && (
          <UpsertButton
            wrapperStyle={styles.button}
            onClick={this._submit}
            loading={this.props.savingName}
            value={isEdit}
          />
        )}
      </Form>
    );
  }

  static styles = StyleSheet.create({
    form: {
      marginTop: 50,
      width: 380,
    },
    button: {
      width: '100%',
    },
  });
}

export default injectIntl(SegmentForm);
