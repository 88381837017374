import axios from 'axios';
import cache from 'memory-cache';
import store from '../redux/store.js';
import {logout} from '../redux/modules/auth.js';

const CACHE_TIMEOUT = 1000;

export let token;

class Client {
  constructor(axios) {
    this.axios = axios;
  }

  get = (url, config) => {
    if (cache.get(url)) {
      return Promise.resolve(cache.get(url));
    }
    return this.axios
      .get(getFullUrl(url), {...getConfig(), ...config})
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        cache.put(url, data, CACHE_TIMEOUT);
        return data;
      });
  };

  delete = (url, config) => {
    return this.axios
      .delete(getFullUrl(url), {...getConfig(), ...config})
      .then((response) => response.data);
  };

  head = (url, config) => {
    return this.axios
      .head(getFullUrl(url), {...getConfig(), ...config})
      .then((response) => response.data);
  };

  post = (url, data, config) => {
    return this.axios
      .post(getFullUrl(url), data, {...getConfig(), ...config})
      .then((response) => response.data);
  };

  put = (url, data, config) => {
    return this.axios
      .put(getFullUrl(url), data, {...getConfig(), ...config})
      .then((response) => response.data);
  };

  patch = (url, data, config) => {
    return this.axios
      .patch(getFullUrl(url), data, {...getConfig(), ...config})
      .then((response) => response.data);
  };
}

export default function makeClient(customHeaders) {
  const headers = {...customHeaders};
  if (token) {
    headers.Authorization = 'Bearer ' + token;
  }
  const axiosInstance = axios.create({
    headers,
  });

  axiosInstance.interceptors.request.use(
    function (request) {
      return request;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.status === 401) {
        store.dispatch(logout());
        return;
      }
      return Promise.reject(error);
    }
  );

  return new Client(axiosInstance);
}

function getFullUrl(url) {
  return url;
}

function getConfig() {
  return {};
}

export function setToken(newToken) {
  token = newToken;
}
