import React, {Component} from 'react';
import {IntlProvider} from 'react-intl';
import {connect} from 'react-redux';

function mapStateToProps(state) {
  return {
    dispatch: state.dispatch,
    initialized: state.i18n.initialized,
    messages: state.i18n.messages,
    language: state.i18n.language,
    version: state.i18n.version,
  };
}

export default connect(mapStateToProps)(
  class I18n extends Component {
    // Uncomment this to mark translatable strings
    // componentDidMount = () => {
    //   Object.keys(messages).map(key => {
    //     messages[key] = `[${messages[key]}]`;
    //   });
    // }

    render() {
      const {language, messages, version, children} = this.props;

      return (
        <IntlProvider
          // makes Missing Translation error into warnings for better console visibility - https://github.com/formatjs/formatjs/issues/465#issuecomment-643887472
          onError={(err) => {
            if (err.code === 'MISSING_TRANSLATION') {
              // console.warn('Missing translation', err.message);
              return;
            }
            throw err;
          }}
          locale={language}
          messages={messages || {}}
          defaultLocale="en"
          key={version}
        >
          {children}
        </IntlProvider>
      );
    }
  }
);
