import terms from '../../helpers/terms';
import {validateClientId} from '../../helpers/validation';
import {SUB_BRAND_WEB_CONSOLE} from '../../redux/modules/brand';

export default (values, {brandId, webConsoleType}) => {
  const errors = {};
  if (
    webConsoleType !== SUB_BRAND_WEB_CONSOLE &&
    (!values.segments || values.segments.length === 0)
  ) {
    errors.segments = terms.required;
  }

  if (
    webConsoleType === SUB_BRAND_WEB_CONSOLE &&
    (!values.genericSegments || values.genericSegments.length === 0)
  ) {
    //|| values.genericSegments.length === 0
    errors.genericSegments = terms.required;
  }

  if (!values.name) {
    errors.name = terms.required;
  }
  if (!values.id) {
    errors.id = terms.required;
  } else {
    const clientIdCheck = validateClientId(brandId, values.id);
    if (!clientIdCheck.status) {
      errors.id = clientIdCheck.errorMessage;
    }
  }
  return errors;
};
