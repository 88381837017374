import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {asyncConnect} from 'redux-connect';
import CTAForm from './CTAForm';
import AddCTAGroup from './AddCTAGroup';
import CTAGroups from './CTAGroups';
import {
  updateCTA,
  loadCTA,
  addGroupToCta,
  deleteGroupFromCta,
} from '../../redux/modules/ctas';
import {loadAllSegments} from '../../redux/modules/segments';
import {createCta} from '../../redux/modules/ctas';

class CTASDetail extends Component {
  static propTypes = {
    cta: PropTypes.object,
    brand: PropTypes.object,
    segments: PropTypes.array,
    dispatch: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      possibleSegments: this._getPossibleSegments(),
    };
  }

  _isGroup = () => {
    return this.props.cta.segmentType === 'group';
  };

  _getPossibleSegments = () => {
    let possibleSegments;
    if (this.props.cta && this.props.cta.groups) {
      let idArray = this.props.cta.groups.map((group) => {
        return group.segmentGroupId;
      });
      possibleSegments = this.props.segments.filter((segment) => {
        return idArray.indexOf(segment.id) === -1;
      });
    }
    return possibleSegments || this.props.segments;
  };

  _updateCTA = () => {
    if (this.props.cta && this.props.cta.id) {
      return this.props.dispatch(updateCTA(this.props.cta));
    } else {
      return this.props
        .dispatch(createCta(this.props.cta))
        .then((cta) => this.props.history.push(`/ctas/edit/${cta.id}`));
    }
  };

  _addCtaGroup = (group) => {
    return this.props
      .dispatch(addGroupToCta(this.props.cta, group))
      .then(() => {
        this.setState({
          possibleSegments: this._getPossibleSegments(),
        });
      });
  };

  _deleteCtaGroup = (group) => {
    return this.props
      .dispatch(deleteGroupFromCta(this.props.cta, group))
      .then(() => {
        this.setState({
          possibleSegments: this._getPossibleSegments(),
        });
      });
  };

  render() {
    const {
      cta,
      segments,
      brand,
      dispatch,
      match: {params},
    } = this.props;
    const {possibleSegments} = this.state;
    const isGroup = this._isGroup();
    const isEdit = params && params.id && cta && cta.id;

    return (
      <div>
        <CTAForm
          submit={this._updateCTA}
          brand={brand}
          cta={cta}
          segments={segments}
          dispatch={dispatch}
          isEdit={isEdit}
        />
        {cta && cta.id && isGroup && (
          <AddCTAGroup
            cta={cta}
            segments={segments}
            possibleSegments={possibleSegments}
            onAddCtaGroup={this._addCtaGroup}
            dispatch={dispatch}
          />
        )}
        {cta && cta.id && isGroup && (
          <CTAGroups
            cta={cta}
            segments={segments}
            onDeleteCtaGroup={this._deleteCtaGroup}
            dispatch={dispatch}
          />
        )}
      </div>
    );
  }
}

export default asyncConnect([
  {
    key: 'load',
    promise: (helpers) => {
      if (helpers.match.params.id) {
        helpers.store.dispatch(loadCTA(helpers.match.params.id));
      } else {
        return Promise.resolve();
      }
    },
  },
  {
    key: 'allSegments',
    promise: (helpers) => helpers.store.dispatch(loadAllSegments()),
  },
])(
  connect((state) => {
    return {
      brand: state.brand,
      cta: state.cta.current,
      segments: state.segments.segments,
    };
  })(CTASDetail)
);
