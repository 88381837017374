import React from 'react';
import {css, StyleSheet} from 'aphrodite';

import {SORT_DIRECTIONS} from './FixedDataTable';
import TextCell from './TextCell';

const ASCENDING_SORT_INDICATOR = '↓';
const DESCENDING_SORT_INDICATOR = '↑';

export default class SortHeaderCell extends React.Component {
  _getSortIndicator(sortDirection, isSorted, initialSortDirection) {
    if (isSorted) {
      return sortDirection === SORT_DIRECTIONS.ASC
        ? ASCENDING_SORT_INDICATOR
        : DESCENDING_SORT_INDICATOR;
    } else {
      return initialSortDirection === SORT_DIRECTIONS.ASC
        ? ASCENDING_SORT_INDICATOR
        : DESCENDING_SORT_INDICATOR;
    }
  }

  render() {
    const {
      sort,
      onSortChange,
      children,
      columnKey,
      initialSortDirection,
      ...rest
    } = this.props;
    const {styles} = SortHeaderCell;

    const isSorted = sort.direction && sort.columnKey === columnKey;

    return (
      <TextCell onClick={onSortChange} className={css(styles.cell)} {...rest}>
        <a>
          {children}
          &nbsp;
          <span className={css(isSorted ? styles.active : styles.inactive)}>
            {this._getSortIndicator(
              sort.direction,
              isSorted,
              initialSortDirection
            )}
          </span>
        </a>
      </TextCell>
    );
  }

  static styles = StyleSheet.create({
    cell: {
      ':hover': {
        backgroundColor: '#f5f5f5',
      },
      cursor: 'pointer',
    },
    inactive: {
      color: '#999',
    },
    active: {},
  });
}
