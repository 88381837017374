import PropTypes from 'prop-types';
import React, {Component} from 'react';
import moment from 'moment';
import {StyleSheet, css} from 'aphrodite';

import logo from './../../assets/images/essie/essieLogoNavigationBar.png';
import ctaOpenIcon from './../../assets/images/essie/cTAOpen.png';
import ctaCloseIcon from './../../assets/images/essie/cTAClose.png';
import emailIcon from './../../assets/images/essie/iconEmail.png';
import phoneIcon from './../../assets/images/essie/iconPhone.png';
import whatsappIcon from './../../assets/images/essie/iconPhone.png';
import menu from '../../assets/images/essie/burgerMenu.png';
import back from '../../assets/images/essie/backArrow.png';
import Header from './Header';

export default class MessagePreviewEssie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ctaVisible: false,
    };
  }

  static propTypes = {
    lookup: PropTypes.object,
    message: PropTypes.object,
  };

  _onCtaButtonClick = () => {
    this.setState({ctaVisible: !this.state.ctaVisible});
  };

  _renderCta = (cta) => {
    const {styles} = MessagePreviewEssie;
    switch (cta.type) {
      case 'web':
        return (
          <div className={css(styles.weblink)} key={cta.id}>
            <u className={css(styles.underlineText)}>{cta.defaultLabel}</u>
          </div>
        );
      case 'email':
        return (
          <div className={css(styles.secondPageCta)} key={cta.id}>
            <div className={css(styles.secondPageCtaLabel)}>
              {cta.defaultLabel}
            </div>
            <img
              className={css(styles.secondPageCtaIcon)}
              src={emailIcon}
              alt="email cta"
            />
          </div>
        );
      case 'phone':
        return (
          <div className={css(styles.secondPageCta)} key={cta.id}>
            <div className={css(styles.secondPageCtaLabel)}>
              {cta.defaultLabel}
            </div>
            <img
              className={css(styles.secondPageCtaIcon)}
              src={phoneIcon}
              alt="phone cta"
            />
          </div>
        );

      case 'whatsapp':
        return (
          <div className={css(styles.secondPageCta)} key={cta.id}>
            <div className={css(styles.secondPageCtaLabel)}>
              {cta.defaultLabel}
            </div>
            <img
              className={css(styles.secondPageCtaIcon)}
              src={whatsappIcon}
              alt="whatsapp cta"
            />
          </div>
        );
      default:
        return null;
    }
  };

  _getCtas = () => {
    return (this.props.message.ctas || [])
      .filter((id) => this.props.lookup.ctas[id])
      .map((id) => this.props.lookup.ctas[id]);
  };

  render() {
    const {
      message: {description, title, publicationDate, image, subTitle},
      ctas,
      categories,
    } = this.props;

    const {styles} = MessagePreviewEssie;

    const {ctaVisible} = this.state;
    const categoryName = categories.length > 0 ? categories[0].name : '';
    const formatedPublicationDate =
      moment(publicationDate).format("DD MMMM'YY");

    return (
      <div className={css(styles.preview)}>
        <Header logo={logo} menu={menu} back={back} />

        {!ctaVisible && (
          <div className={css(styles.messageContainer)}>
            <div className={css(styles.imageContainer)}>
              {image && (
                <img className={css(styles.image)} src={image} alt="message" />
              )}
              {categoryName && image && (
                <div className={css(styles.categoryName)}>{categoryName}</div>
              )}
            </div>

            <div className={css(styles.messageContent)}>
              <div className={css(styles.publicationDate)}>
                {formatedPublicationDate}
              </div>
              <div className={css(styles.subtitle)}>{subTitle}</div>
              <div className={css(styles.title)}>{title}</div>
              <div className={css(styles.description)}>{description}</div>
              <div>
                {this._getCtas()
                  .filter((cta) => cta.type === 'web')
                  .map(this._renderCta)}
              </div>
            </div>
          </div>
        )}

        <div className={css(styles.ctas)}>
          {ctaVisible &&
            ctas
              .filter((cta) => cta.type !== 'web')
              .map((cta) => this._renderCta(cta))}

          {ctas.some((cta) => cta.type !== 'web') && (
            <img
              className={css(styles.ctaOpen)}
              alt="cta"
              onClick={this._onCtaButtonClick}
              src={ctaVisible ? ctaCloseIcon : ctaOpenIcon}
            />
          )}
        </div>
      </div>
    );
  }

  static BODONI_POSTER = 'BodoniPoster';
  static BODONI_BOLD = 'BodoniBold';

  static UNIVERS_LIGHT = 'Univers-Light';
  static UNIVERS_BOLD = 'Univers-Bold';

  static previewWidth = 246;
  static previewHeight = 426;
  static headerHeight = 34;

  static MAIN_GRAY = '#666666';
  static SECONDARY_GRAY = '#939393';

  static styles = StyleSheet.create({
    preview: {
      width: MessagePreviewEssie.previewWidth,
      height: MessagePreviewEssie.previewHeight,
      marginTop: 70,
      marginLeft: 53,
      display: 'flex',
      flexDirection: 'column',
    },
    messageContainer: {
      display: 'flex',
      flexDirection: 'column',
      height:
        MessagePreviewEssie.previewHeight - MessagePreviewEssie.headerHeight,
      overflow: 'scroll',
    },
    messageContent: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: 18,
      marginLeft: 12,
    },
    ctas: {
      position: 'absolute',
      right: 16,
      bottom: 44,
      marginTop: 'auto',
      alignSelf: 'flex-end',

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    ctaOpen: {
      width: 47,
      height: 47,
    },
    image: {
      width: '100%',
    },
    imageContainer: {
      position: 'relative',
    },
    categoryName: {
      fontFamily: 'UniversLTW01-45Light',
      position: 'absolute',
      whiteSpace: 'nowrap',
      bottom: '-10%',
      left: '100%',
      transform: 'rotate(270deg)',
      transformOrigin: 'left bottom',
      backgroundColor: 'white',
      color: '#CA131F',
      padding: 6,
      fontSize: 15,
      letterSpacing: 2,
      fontWeight: 100,
    },
    title: {
      fontFamily: MessagePreviewEssie.BODONI_POSTER,
      fontSize: 22,
      marginRight: 12,
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      overflowWrap: 'break-word',
    },
    subtitle: {
      fontFamily: MessagePreviewEssie.BODONI_BOLD,
      fontSize: 18,
      marginRight: 12,
      whiteSpace: 'pre-wrap',
      overflowWrap: 'break-word',
    },
    description: {
      fontFamily: MessagePreviewEssie.UNIVERS_LIGHT,
      fontSize: 18,
      color: MessagePreviewEssie.MAIN_GRAY,
      whiteSpace: 'pre-wrap',
      overflowWrap: 'break-word',
      marginBottom: 12,
    },
    weblink: {
      fontFamily: MessagePreviewEssie.UNIVERS_LIGHT,
      fontSize: 18,
      color: MessagePreviewEssie.MAIN_GRAY,
      textDecoration: 'line',
      marginBottom: 12,
    },
    publicationDate: {
      fontFamily: MessagePreviewEssie.UNIVERS_LIGHT,
      fontSize: 11,
      color: MessagePreviewEssie.SECONDARY_GRAY,
    },
    secondPageCta: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: 6,
    },
    secondPageCtaLabel: {
      color: MessagePreviewEssie.MAIN_GRAY,
      fontFamily: MessagePreviewEssie.UNIVERS_LIGHT,
      fontSize: 16,
      marginRight: 12,
    },
    secondPageCtaIcon: {
      width: 32,
      height: 32,
      marginRight: 9,
    },
    underlineText: {
      color: MessagePreviewEssie.MAIN_GRAY,
    },
  });
}
