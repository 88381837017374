import PropTypes from 'prop-types';
import React, {Component} from 'react';
import moment from 'moment';
import {StyleSheet, css} from 'aphrodite';

import Header from './Header';

// fonts
const PLAYFAIRDISPLAY_REGULAR = 'PlayfairDisplay-Regular';
const PLAYFAIRDISPLAY_ITALIC = 'PlayfairDisplay-Italic';

// colors
const OFF_GOLD = '#706259';
const GOLD = '#ab8249';

// assets
import LOGO_IMAGE from '../../assets/images/decleor/decleorLogoNavigationBar.png';
import MENU_BUTTON from '../../assets/images/decleor/burgerMenu.png';
import BACK_BUTTON from '../../assets/images/decleor/backArrow.png';
import CTA_BUTTON_OPEN from '../../assets/images/decleor/cTAOpenIcon.png';
import CTA_BUTTON_CLOSE from '../../assets/images/decleor/cTACloseIcon.png';
import CTA_BUTTON_PHONE from '../../assets/images/decleor/cTAPhone.png';
import CTA_BUTTON_EMAIL from '../../assets/images/decleor/cTAEmail.png';

const logo = LOGO_IMAGE;
const fabOpenIcon = CTA_BUTTON_OPEN;
const fabCloseIcon = CTA_BUTTON_CLOSE;
const emailIcon = CTA_BUTTON_EMAIL;
const phoneIcon = CTA_BUTTON_PHONE;
const whatsappIcon = CTA_BUTTON_PHONE;
const menu = MENU_BUTTON;
const back = BACK_BUTTON;

const dateFormat = 'D/MM/YYYY';

export default class MessagePreviewDecleor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ctaVisible: false,
    };
  }

  static propTypes = {
    lookup: PropTypes.object,
    message: PropTypes.object,
    categories: PropTypes.array.isRequired,
    ctas: PropTypes.array.isRequired,
  };

  _renderCta = (cta) => {
    const {styles} = MessagePreviewDecleor;
    switch (cta.type) {
      case 'web':
        return (
          <div className={css(styles.link)} key={cta.id}>
            {cta.defaultLabel}
          </div>
        );
      case 'email':
        return (
          <div className={css(styles.secondPageCta)} key={cta.id}>
            <div className={css(styles.secondPageCtaLabel)}>
              {cta.defaultLabel}
            </div>
            <img
              className={css(styles.secondPageCtaIcon)}
              src={emailIcon}
              alt="email cta"
            />
          </div>
        );
      case 'phone':
        return (
          <div className={css(styles.secondPageCta)} key={cta.id}>
            <div className={css(styles.secondPageCtaLabel)}>
              {cta.defaultLabel}
            </div>
            <img
              className={css(styles.secondPageCtaIcon)}
              src={phoneIcon}
              alt="phone cta"
            />
          </div>
        );
      case 'whatsapp':
        return (
          <div className={css(styles.secondPageCta)} key={cta.id}>
            <div className={css(styles.secondPageCtaLabel)}>
              {cta.defaultLabel}
            </div>
            <img
              className={css(styles.secondPageCtaIcon)}
              src={whatsappIcon}
              alt="whatsapp cta"
            />
          </div>
        );
      default:
        return null;
    }
  };

  _getCtas = () => {
    return (this.props.message.ctas || [])
      .filter((id) => this.props.lookup.ctas[id])
      .map((id) => this.props.lookup.ctas[id]);
  };

  _onCtaButtonClick = () => {
    this.setState({ctaVisible: !this.state.ctaVisible});
  };

  render() {
    const {
      message: {description, title, publicationDate, image},
      categories,
      ctas,
    } = this.props;

    const {ctaVisible} = this.state;
    const {styles} = MessagePreviewDecleor;

    const categoryName = categories.length > 0 ? categories[0].name : '...';
    const capitalizedCategoryName = categoryName
      ? categoryName.charAt(0).toUpperCase() + categoryName.slice(1)
      : '';
    const formatedPublicationDate = publicationDate
      ? moment(publicationDate).format(dateFormat)
      : '...';
    const categoryAndDate = `${capitalizedCategoryName} - Published on ${formatedPublicationDate}`;

    return (
      <div className={css(styles.preview)}>
        <Header logo={logo} menu={menu} back={back} />
        <div className={css(styles.container)}>
          <div className={css(styles.imageDiv)}>
            {!ctaVisible && image && (
              <img className={css(styles.image)} src={image} alt="message" />
            )}
          </div>

          {!ctaVisible && (
            <div className={css(styles.bottomWrapper)}>
              <div className={css(styles.title)}>
                {(title || '').toUpperCase()}
              </div>
              <div className={css(styles.categoryAndDate)}>
                {categoryAndDate}
              </div>

              <div className={css(styles.description)}>{description}</div>
              {ctas.some((cta) => cta.type === 'web') && (
                <div>
                  <div className={css(styles.divider)} />
                  {ctas
                    .filter((cta) => cta.type === 'web')
                    .map(this._renderCta)}
                </div>
              )}
            </div>
          )}

          <div className={css(styles.ctas)}>
            {ctaVisible &&
              ctas
                .filter((cta) => cta.type === 'phone' || cta.type === 'email')
                .map(this._renderCta)}

            {ctas.some(
              (cta) => cta.type === 'phone' || cta.type === 'email'
            ) && (
              <img
                className={css(styles.fab)}
                onClick={this._onCtaButtonClick}
                alt="cta"
                src={ctaVisible ? fabCloseIcon : fabOpenIcon}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  static previewScreenWidth = 231;
  static previewScreenHeight = 426;

  static styles = StyleSheet.create({
    preview: {
      width: MessagePreviewDecleor.previewScreenWidth,
      height: MessagePreviewDecleor.previewScreenHeight,
      overflow: 'hidden',
      margin: '53px',
      marginRight: '190px',
      marginTop: '70px',
    },
    container: {
      height: '100%',
      width: '100%',
      overflow: 'scroll',
      paddingRight: '20px',
    },
    secondPage: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      overflow: 'hidden',
    },
    imageDiv: {
      width: MessagePreviewDecleor.previewScreenWidth,
    },
    image: {
      width: MessagePreviewDecleor.previewScreenWidth,
      marginBottom: 12,
    },
    bottomWrapper: {
      marginLeft: 18,
      marginBottom: 48,
    },
    categoryAndDate: {
      fontFamily: PLAYFAIRDISPLAY_ITALIC,
      fontSize: 10,
      color: OFF_GOLD,
      marginBottom: 24,
    },
    title: {
      fontFamily: PLAYFAIRDISPLAY_REGULAR,
      fontSize: 22,
      color: OFF_GOLD,
      letterSpacing: 0.3,
      marginBottom: 6,
      display: 'flex',
      wordBreak: 'break-word',
    },
    description: {
      fontFamily: PLAYFAIRDISPLAY_REGULAR,
      fontSize: 13,
      color: OFF_GOLD,
      marginBottom: 16,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      display: 'flex',
    },
    divider: {
      width: 61,
      height: 2,
      marginBottom: 12,
      backgroundColor: GOLD,
    },
    link: {
      fontFamily: PLAYFAIRDISPLAY_ITALIC,
      fontSize: 13,
      color: OFF_GOLD,
      marginBottom: 12,
    },
    ctas: {
      position: 'absolute',
      right: 200,
      bottom: 50,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    fab: {
      width: 40,
      height: 40,
    },
    secondPageCta: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: 16,
      marginRight: 4,
    },
    secondPageCtaLabel: {
      fontFamily: PLAYFAIRDISPLAY_ITALIC,
      fontSize: 14,
      color: OFF_GOLD,
      marginRight: 10,
    },
    secondPageCtaIcon: {
      width: 32,
      height: 32,
    },
  });
}
