import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {StyleSheet, css} from 'aphrodite';

const deepOrange = '#ea5a06';

export default class CategoryIconUFS extends Component {
  static propTypes = {
    categories: PropTypes.arr,
  };

  render() {
    const {categories} = this.props;
    const {styles} = CategoryIconUFS;

    return (
      <div className={css(styles.iconContainer)}>
        <div className={css(styles.iconOuterCircle)}>
          <div className={css(styles.iconCircle)}>
            <img
              className={css(styles.categoryIcon)}
              src={categories[0].icon}
              alt="category icon"
            />
          </div>
        </div>
      </div>
    );
  }

  static styles = StyleSheet.create({
    iconContainer: {
      backgroundColor: 'aqua',
      position: 'relative',
      top: -48,
      left: 160,
      paddingRight: '20px',
    },
    iconOuterCircle: {
      position: 'absolute',
      width: 50,
      height: 50,
      backgroundColor: 'rgba(0,0,0,0.05)',
      borderRadius: 50,
    },
    iconCircle: {
      position: 'absolute',
      top: 5 / 2,
      left: 5 / 2,
      width: 44,
      height: 44,
      backgroundColor: deepOrange,
      borderRadius: 50,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
    },
    categoryIcon: {
      width: 20,
      height: 20,
      resizeMode: 'contain',
    },
  });
}
